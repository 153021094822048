import React, { useState } from "react";

const AccordionItem = ({ title, isOpen, onToggle, children }) => {
  return (
    <div className="w-full border border-gray-300 rounded-md shadow-sm mb-4">
      <div
        onClick={onToggle}
        className="flex items-center justify-between p-4 cursor-pointer bg-gray-100 hover:bg-gray-200"
      >
        <h2 className="text-lg w-[90%] font-semibold smallTablet:text-base">
          {title}
        </h2>
        <span className="text-xl w-[10%] flex justify-end font-bold">
          {isOpen ? "-" : "+"}
        </span>
      </div>
      {isOpen && <div className="p-4 bg-gray-50">{children}</div>}
    </div>
  );
};

export default AccordionItem;
