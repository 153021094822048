import React from "react";
import GamingBox from "./gamingBox";
import GameSection from "../assets/gameLeft.jpeg";
import * as Fa from "react-icons/fa";
import { useNavigate } from "react-router-dom";

export const GamingSection = () => {
  const naviagte = useNavigate();
  const handleClick = () => {
    const phoneNumber = "+1(876)-4482032"; // replace with the actual phone number, including country code
    const message = "Hello, I would like to chat with you."; // optional message

    const whatsappUrl = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(
      message
    )}`;
    window.location.href = whatsappUrl;
  };
  return (
    <div className="w-full mt-28 flex justify-center flex-col items-center">
      <h1 className="mx-auto text-center font-semibold text-[#3A3A3A] leading-[1.3em] text-[70px] font-roboto smallTablet:text-[50px] mobile:!text-[40px]">
        Gaming Machines
      </h1>
      <p className="mx-auto mt-2 font-blinkcofo font-normal mb-1 text-[1em] text-[#3a3a3a] smallTablet:text-center mobile:m-4">
        We stock the best brands in game boards, parts, machines, supplies and
        machines at competitive prices.
      </p>

      <p className="font-roboto italic">For more information or a quotation</p>
      <div className="flex mt-8 gap-x-5">
        <div
          onClick={handleClick}
          className="w-fit flex items-center gap-x-2 zoom rounded-full py-2 px-4 bg-[#048500] text-white font-roboto font-medium text-sm"
        >
          <Fa.FaWhatsapp color="white" />
          <button className="">WhatsApp Us</button>
        </div>
        <button
          onClick={() => naviagte("/contact")}
          className="w-fit zoom rounded-full py-2 px-4 bg-[#CA0000] text-white font-roboto font-medium text-sm"
        >
          Contact Us
        </button>
      </div>
      <div className="flex my-20 gap-x-10">
        <div className="w-[25%] smallTablet:hidden">
          <img
            className="w-full ml-[-50px] h-[100vh] object-cover zoomImgae"
            src="https://technostorage.nyc3.digitaloceanspaces.com/uploads/2021/07/ST3_01.png"
          />
        </div>
        <div className="w-[75%] smallTablet:w-full">
          <GamingBox />
        </div>
      </div>
    </div>
  );
};
