import React, { useEffect } from "react";
import ImageSectionOnTop from "../ImageSectionOnTop";
import LogoAndContent from "./LogoAndContent";
import SaleSection from "./SalesSection";
import LogoAndText from "./LogoAndText";

const Service = () => {
  useEffect(() => {
    window.scrollTo({
      top: 20,
      behavior: "auto",
    });
  }, []);
  return (
    <div>
      <ImageSectionOnTop
        backgroundImage="https://technostorage.nyc3.digitaloceanspaces.com/uploads/2021/07/Services-Banner.jpg"
        height={300}
        textOnMiddle="Service"
      ></ImageSectionOnTop>
      <LogoAndContent />
      <SaleSection />
      <div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1000 100"
          preserveAspectRatio="none"
          className="w-full h-12 transform scale-y-[-1] origin-center text-[#a50000] fill-current mb-10"
        >
          <path
            className="elementor-shape-fill"
            d="M0,6V0h1000v100L0,6z"
          ></path>
        </svg>
      </div>
      <div className="flex flex-col gap-y-4">
        {" "}
        <LogoAndText
          img="https://technostorage.nyc3.digitaloceanspaces.com/uploads/2021/07/Icon-1-02.png"
          heading="Manufacturing"
        />
        <LogoAndText
          img="https://technostorage.nyc3.digitaloceanspaces.com/uploads/2021/07/Icon-05.png"
          heading="Installation & Maintenance"
        />
        <LogoAndText
          img="https://technostorage.nyc3.digitaloceanspaces.com/uploads/2021/07/Icon-06.png"
          heading="Sales"
        />
        <LogoAndText
          img="https://technostorage.nyc3.digitaloceanspaces.com/uploads/2021/07/Icon-04.png"
          heading="Leasing"
        />
      </div>
    </div>
  );
};

export default Service;
