import axios from "axios";
import { userInfo } from "os";
import { useQuery } from "react-query";
import { useRecoilValue } from "recoil";
import { UserInfo } from "../Components/atoms/atoms";

export interface FetchResults {
  isLoading: boolean;
  isRefreshing: boolean;
  isSuccess: boolean;
  isError: boolean;
  error: false | any;
  data: any;
  refetch?: any;
}

export const makeErrorMessage = async (error: any): Promise<any> => {
  try {
    // Your error handling logic
  } catch (err: any) {
    return err;
  }
};

export default function useFetch(
  path: string,
  name: string | string[] | any[],
  args?: { [key: string]: any },
  baseUrl?: string
): FetchResults {
  const userInfo = useRecoilValue(UserInfo);
  const {
    isLoading,
    isSuccess,
    isError,
    isFetching: isRefreshing,
    data,
    error,
    refetch,
  } = useQuery(
    name,
    async () => {
      const url = baseUrl ? `${baseUrl}${path}` : path;
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${userInfo?.access_token}`, // Include the Authorization header with the token
        },
      });
      return response.data;
    },
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      retry: false,
      ...args,
      ...(args?.onError && {
        onError: async (err: any) =>
          args?.onError({
            message: makeErrorMessage(err),
            statusCode: err.response?.status,
          }),
      }),
    }
  );

  return {
    isLoading,
    isRefreshing,
    isSuccess,
    isError,
    data,
    refetch,
    error,
  };
}
