import React, { useState } from "react";
import { FaUser, FaUserLock, FaEnvelope, FaLock } from "react-icons/fa6";
import logo from "../Asset/Technogenius-logo.png";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import usePostRequest from "../Network/usePost";
import axios from "axios";
import { useMutation, useQueryClient } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import Toast from "./Toast/toast";
import Loader from "./Loader";
// import Toast from "./Toast/Toast";
import { FaArrowLeft } from "react-icons/fa6";
import { Link } from "react-router-dom";

function SignUp() {
  const SignupSchema = Yup.object().shape({
    fullName: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Required*"),

    email: Yup.string().email("Invalid email").required("Required*"),
    password: Yup.string().required("Required*"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password")], "Passwords must match")
      .required("Required*"),
  });
  const [toast, setToast] = useState({ type: "", message: "" });

  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const createUser = async (data: any) => {
    const { data: response } = await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/sign-up`,
      data
    );
    return response.data;
  };
  const { mutate, isLoading } = useMutation(createUser, {
    onSuccess: (data) => {
      setToast({ type: "Sucess", message: "User created successfully" });

      navigate("/login");
    },
    onError: (errors: any) => {
      const error = errors as any;
      setToast({
        type: "Error",
        message: error?.message || "Something went wrong",
      });
    },
  });

  return (
    <div className=" h-full w-full bg-[#f5f5f7] mx-auto relative">
      <div className="flex  justify-center ">
        <div
          style={{
            boxShadow: "5px 5px 10px 12px rgba(0,2,5,.2)",
          }}
          className="absolute my-8  bg-white w-1/2 rounded-lg  tablet:w-[65%] smallTablet:!w-[95%]"
        >
          {toast.type && (
            <Toast
              type={toast.type as "Sucess" | "Error"}
              message={toast.message}
            />
          )}
          <Link
            className="w-full font-bold text-[#AD3511] text-2xl mx-auto"
            to="/"
          >
            <FaArrowLeft className="m-3" />
          </Link>

          <figure className="w-1/2 mx-auto my-4 h-fit ">
            <img
              src={logo}
              alt="techno logo"
              className="w-full h-full object-contain"
            />
          </figure>
          <hr className="border-t-[3px] w-1/2 mx-auto border-[#AD3511]" />
          <Formik
            initialValues={{
              fullName: "",
              email: "",
              password: "",
              confirmPassword: "",
            }}
            validationSchema={SignupSchema}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              // Your form submission logic here

              mutate(values);
              resetForm({});
              setSubmitting(false);
            }}
          >
            {({ isSubmitting }) => (
              <Form className="mt-8 flex flex-col justify-start">
                <div className="flex mx-14 gap-x-8 my-3 items-center">
                  {/* <Toast /> */}
                  <div
                    style={{
                      fontFamily: "DM Sans, sans-serif",
                    }}
                    className="flex w-full flex-col gap-y-4 space-y-1 font-roboto text-sm"
                  >
                    <h1 className="text-4xl font-bold my-4">Sign up</h1>
                    <div className="flex flex-col gap-y-1">
                      <div className="flex gap-x-6 items-center w-full border-b-[1.5px]  border-black">
                        {" "}
                        <FaUser className="text-lg" />
                        <Field
                          type="text"
                          name="fullName"
                          placeholder="Enter your full name"
                          className="w-full font-medium font-roboto text-base  p-2 rounded-md outline-none focus:border-[#3d24249a]"
                        />
                      </div>
                      <ErrorMessage
                        name="fullName"
                        component="div"
                        className="text-red-600"
                      />
                    </div>
                    <div className="flex flex-col gap-y-1">
                      <div className="flex gap-x-6 items-center w-full border-b-[1.5px] border-black">
                        {" "}
                        <FaEnvelope className="text-lg text-black" />
                        <Field
                          type="text"
                          name="email"
                          placeholder="Your email address"
                          className="w-full font-medium font-roboto text-base  p-2 rounded-md outline-none focus:border-[#3d24249a]"
                        />
                      </div>
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="text-red-600"
                      />
                    </div>
                    <div className="flex flex-col gap-y-1">
                      <div className="flex gap-x-6 items-center w-full border-b-[1.5px]  border-black">
                        {" "}
                        <FaLock className="text-lg text-black" />
                        <Field
                          type="password"
                          name="password"
                          placeholder="Password"
                          className="w-full font-medium font-roboto text-base  p-2 rounded-md outline-none focus:border-[#3d24249a]"
                        />
                      </div>
                      <ErrorMessage
                        name="password"
                        component="div"
                        className="text-red-600"
                      />
                    </div>
                    <div className="flex flex-col gap-y-1">
                      <div className="flex gap-x-6 items-center w-full border-b-[1.5px]  border-black">
                        {" "}
                        <FaUserLock className="text-2xl text-black" />
                        <Field
                          type="password"
                          name="confirmPassword"
                          placeholder="Repeat your password"
                          className="w-full font-medium font-roboto text-base  p-2 rounded-md outline-none focus:border-[#3d24249a]"
                        />
                      </div>
                      <ErrorMessage
                        name="confirmPassword"
                        component="div"
                        className="text-red-600"
                      />
                    </div>
                    <div>
                      {isLoading ? (
                        <Loader />
                      ) : (
                        <button
                          type="submit"
                          className="bg-[#AD3511] w-1/2  rounded-md p-3 text-white font-roboto my-3"
                          disabled={isSubmitting}
                        >
                          {"Register"}
                        </button>
                      )}
                    </div>
                  </div>

                  <div className="w-3/4 mobile:hidden ">
                    <img
                      className="h-[26vh] w-full"
                      src="https://technostorage.nyc3.digitaloceanspaces.com/uploads/2021/06/Poker-Slots-03-03.png"
                      alt="poker slots"
                    />
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}

export default SignUp;
