import React, { useState } from "react";
import { FaRegCalendar } from "react-icons/fa";

const Coupon = () => {
  const [openBox, setOpenBox] = useState(false);
  return (
    <div>
      <div className="border-[#1e85be] flex gap-x-4 items-center border-t-[3px] bg-[#f7f6f7] py-4 px-6">
        <span className="">
          <FaRegCalendar className="text-[#1e85be]" />
        </span>{" "}
        <span className="text-[#515151] text-sm">
          Have a coupon?{" "}
          <span
            onClick={() => setOpenBox(!openBox)}
            className="cursor-pointer text-[#E02424] text-sm"
          >
            Click here to enter your code
          </span>
        </span>
      </div>
      {openBox ? (
        <div className="my-8">
          <p className=" text-[#515151] mt-2 mb-6 text-sm">
            If you have a coupon code, please apply it below.
          </p>
          <div className="flex mt-12 gap-x-3 w-1/2">
            <input
              className="border border-[#DDDDDD] py-[4px] px-3 focus:border focus:border-dotted focus:border-black outline-none"
              placeholder="Coupon code"
            ></input>
            <button className="rounded-[30px] px-5 py-[10px] text-sm font-bold text-white bg-[#0170B9]">
              Apply coupon
            </button>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default Coupon;
