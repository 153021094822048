import React, { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  Elements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import "./Payment.css"; // Import the CSS file
import { useSetRecoilState } from "recoil";
import { DataId, cartItems } from "./atoms/atoms";
import { QueryClient } from "react-query";

interface SimpleDialogProps {
  showDialog: boolean;
  onCloseDialog: () => void;
  title?: string;
  width?: string;
  height?: string;
  isLoading?: boolean;
  className?: string;
  showCloseIcon?: boolean;
  data: string;
}
const PaymentModal = ({
  showDialog,
  onCloseDialog,
  showCloseIcon,
  title,
  width,
  height,
  data,
  className,
}: SimpleDialogProps) => {
  const overlayStyle: React.CSSProperties = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(28, 40, 64, 0.55)",
    zIndex: 49,
    display: showDialog ? "block" : "none",
    overflow: "hidden !important",
  };
  const cardElementStyles = {
    base: {
      color: "#32325d",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4",
      },
      ":-webkit-autofill": {
        color: "#fce883",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
    complete: {
      color: "#4caf50",
      iconColor: "#4caf50",
    },
  };
  const [loading, setLoading] = useState(false);
  const stripe = useStripe();
  const queryClient = new QueryClient();
  const elements = useElements();
  const setCartValue = useSetRecoilState(cartItems);
  const setDataValue = useSetRecoilState(DataId);
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) return;

    setLoading(true);

    const cardNumber = elements.getElement(CardNumberElement);
    const result = await stripe.confirmCardPayment(data, {
      payment_method: {
        card: cardNumber,
      },
    });

    if (result.error) {
      console.log(result.error.message);
    } else {
      if (result.paymentIntent.status === "succeeded") {
        console.log("Payment succeeded");
        setCartValue([]);
        setDataValue([]);
        queryClient.invalidateQueries("fetch-all-products");
        // Handle success
      }
    }

    setLoading(false);
  };

  return (
    <>
      <div
        className="!overflow-hidden"
        style={overlayStyle}
        onClick={onCloseDialog}
      />

      {showDialog && (
        <div
          className={`fixed p-4 z-50 min-w-[750px]  h-auto max-h-[95vh] overflow-y-auto rounded top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2   bg-white ${className} overflow-hidden smallTablet:!w-full smallTablet:!min-w-full`}
          style={{
            boxShadow: "5px 5px 10px 12px rgba(0,2,5,.2)",
          }}
        >
          <form className="form" onSubmit={handleSubmit}>
            <div className="field">
              <label>Card Number</label>
              <CardNumberElement options={{ style: cardElementStyles }} />
            </div>
            <div className="field">
              <label>Expiry Date</label>
              <CardExpiryElement options={{ style: cardElementStyles }} />
            </div>
            <div className="field">
              <label>CVC</label>
              <CardCvcElement options={{ style: cardElementStyles }} />
            </div>
            <button
              className="button !bg-[#DD4824]"
              type="submit"
              disabled={!stripe || loading}
            >
              {loading ? "Processing..." : "Pay"}
            </button>
          </form>
        </div>
      )}
    </>
  );
};

export default PaymentModal;
