import React from "react";
import { links, company, QuickLinks } from "../constants";
import { FaPhoneFlip } from "react-icons/fa6";
import { Link } from "react-router-dom";

const FooterSection: React.FC = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="bg-black">
      <div data-negative="false">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 700 10"
          preserveAspectRatio="none"
        >
          <path className="element-icon" d="M350,10L340,0h20L350,10z" />
        </svg>
      </div>

      <div className="max-w-[1140px] mx-auto pt-10  largeTablet:px-5 largeTablet:w-auto">
        {/* top start */}
        <div className="py-10 flex flex-wrap items-start justify-between gap-6">
          <div className="flex flex-col items-center gap-6 w-72 tablet:w-auto">
            <figure className="w-72 tablet:w-auto">
              <Link to="/">
                <img
                  className="w-full h-full object-contain"
                  src="https://technostorage.nyc3.digitaloceanspaces.com/uploads/2023/04/Original-Techno-G-Logo-white-red-2-1536x533-1-1024x355.png"
                  alt="logo"
                />
              </Link>
            </figure>

            <p className="text-center leading-relaxed sm:text-sm sm:leading-relaxed text-gray-300 text-xs capitalize">{`Technogenious Solutions Ltd is a Licensed Technical Services Provider by the Betting Gaming and Lotteries Commission – BGLC. Authorized Servicing Center for Pyramid Bill Acceptors In Jamaica.`}</p>
          </div>

          {/* company start */}
          <div>
            <h6 className="text-[#ff0000] capitalize font-bold text-xl">
              Company
            </h6>
            <ul className="flex flex-col gap-3 mt-5 text-sm">
              {company.length > 0 &&
                company.map((item, index) => (
                  <li
                    key={index}
                    className="w-fit text-white capitalize hover:text-[#ff0000] duration-100 transition-all cursor-pointer"
                  >
                    <Link to={item.link}>{item.title}</Link>
                  </li>
                ))}
            </ul>
          </div>
          {/* company end */}

          {/* Quick Links start */}
          <div>
            <h6 className="text-[#ff0000] capitalize font-bold text-xl">
              Quick Links
            </h6>
            <ul className="flex flex-col gap-3 mt-5 text-sm">
              {QuickLinks.length > 0 &&
                QuickLinks.map((quiklink, index) => (
                  <li
                    key={index}
                    className="w-fit text-white capitalize hover:text-[#ff0000] duration-100 transition-all cursor-pointer"
                  >
                    <Link to={quiklink.link}>{quiklink.title}</Link>
                  </li>
                ))}
            </ul>
          </div>
          {/* Quick Links end */}

          {/* Customer Service start */}
          <div>
            <h6 className="text-[#ff0000] capitalize font-bold text-xl">
              Customer Service
            </h6>
            <ul className="flex flex-col gap-3 mt-5 text-sm">
              <li className="w-fit text-white capitalize hover:text-[#ff0000] duration-100 transition-all cursor-pointer">
                <Link to={"/contact"}>contact us</Link>
              </li>
              <li
                onClick={() => window.open("mailto:technogeniousja@gmail.com")}
                className="w-fit text-white  hover:text-[#ff0000] duration-100 transition-all cursor-pointer"
              >
                technogeniousja@gmail.com
              </li>
            </ul>
            <ul className="flex text-[#ff0000] items-center gap-4 text-xs sm:text-sm mt-4">
              <li>
                <FaPhoneFlip />
              </li>
              <a href="tel:1876484163">
                <li className="duration-200 transition-all hover:text-gray-500 cursor-pointer">
                  +1(876)-6484163
                </li>
              </a>
              <li className="text-white">|</li>
              <a href="tel:18764482032">
                <li className="duration-200 transition-all hover:text-gray-500 cursor-pointer">
                  +1(876)-4482032
                </li>
              </a>
            </ul>
          </div>
          {/* Customer Service end */}
        </div>
        {/* top end */}

        {/* bottom start */}
        <div className="flex flex-wrap items-center justify-between text-gray-500 py-10 border-0 border-t-2 gap-4 border-red-700">
          <p className="text-xs sm:text-sm capitalize gap-4">
            &copy; {currentYear} Technogenious Solutions Ltd -All rights
            reserved
          </p>

          <ul className="flex flex-wrap items-center gap-6">
            {links.length > 0 &&
              links.map((link, index) => (
                <li
                  key={index}
                  className="cursor-pointer duration-100 transition-all hover:text-green-600"
                  onClick={() => window.open(link.url, "_blank")}
                >
                  <link.icon />
                </li>
              ))}
          </ul>
        </div>
        {/* bottom end */}
      </div>
    </footer>
  );
};

export default FooterSection;
