import React, { useEffect } from "react";
import { disclaimerSections } from "../constant";

const Disclaimer = () => {
  useEffect(() => {
    window.scrollTo({
      top: -100,
      behavior: "auto",
    });
  }, []);
  return (
    <div className="max-w-[1200px] w-full mx-auto py-[50px]">
      <div className=" px-[40px] mobile:px-6 break-all">
        <h2
          className="text-center mt-[15px] mb-[50px] text-[35px]"
          style={{
            fontFamily: "Montserrat, Sans-serif",
            fontWeight: "600",
            lineHeight: "1.4em",
          }}
        >
          Technogenious Solutions Disclaimer
        </h2>
        <p
          className="mb-[24px] text-[14px]"
          style={{
            fontFamily: "Montserrat, Sans-serif",
          }}
        >
          Last modified: July 29, 2024
        </p>
        <ol
          className="mx-6 mb-[1.5em]"
          style={{
            listStyle: "decimal",
          }}
        >
          {disclaimerSections &&
            disclaimerSections.map((section, index) => (
              <li
                key={index}
                className="text-[14px] "
                style={{
                  fontFamily: "Montserrat, Sans-serif",
                }}
              >
                <strong style={{ display: "block", marginBottom: "25px" }}>
                  {section.title}
                </strong>
                <p
                  className="mb-[24px] text-[14px]"
                  style={{
                    fontFamily: "Montserrat, Sans-serif",
                  }}
                >
                  {section.content}
                </p>
              </li>
            ))}
        </ol>
      </div>
    </div>
  );
};

export default Disclaimer;
