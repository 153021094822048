import React from "react";

export const HeroSection = () => {
  return (
    <div className=" relative">
      <div
        style={{
          boxShadow: "0 0 10px 8px rgba(0,0,0,.5)",
        }}
        className=" absolute top-0 z-[4] flex items-center justify-center tablet:justify-start smallTablet:!justify-center w-full bg-white bg-opacity-80 py-7 px-4"
      >
        <div className="flex items-center  largeTablet:w-[358px] largeTablet:h-[128px]">
          <img
            src="https://technostorage.nyc3.digitaloceanspaces.com/uploads/2021/08/Original-Techno-G-Logo-updated-2.png"
            alt="Logo"
            style={{ width: "533px", height: "185px" }}
            className="object-contain"
          />
        </div>
        <div className="absolute right-0 p-4 largeTablet:w-[250px] smallTablet:hidden">
          <img
            src="https://technostorage.nyc3.digitaloceanspaces.com/uploads/2021/06/Poker-Slots-03-03.png"
            alt="Logo"
            style={{ width: "305px", height: "170px" }}
            className="zoom-in-out-box"
          />
        </div>
      </div>

      <div className="w-full h-full">
        <video
          className="w-full h-full object-cover smallTablet:w-fit smallTablet:h-[70vh] mobile:!h-[60vh]"
          autoPlay
          loop
          muted
          controls={false}
          src="https://technostorage.nyc3.digitaloceanspaces.com/uploads/2021/11/Technogenious-Homepage-Video-1-1.mp4"
        />
      </div>
    </div>
  );
};
