import React, { useState, useEffect } from "react";
import { MdSearch } from "react-icons/md";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import useFetch from "../Network/useFetch";
import Loader from "./Loader";
import axios from "axios";
import { useRecoilState, useRecoilValue } from "recoil";
import { UserInfo, cartItems } from "./atoms/atoms";
import { useMutation, useQueryClient } from "react-query";
import { FaCheck } from "react-icons/fa";

const ProductChildLinks = () => {
  const [qty, setQty] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [showNavbar, setShowNavbar] = useState(false);
  const location = useLocation();
  const { productId } = useParams();
  const navigate = useNavigate();
  const userInfo = useRecoilValue(UserInfo);
  const queryClient = useQueryClient();
  const [toast, setToast] = useState({ type: "", message: "" });
  const [cartValue, setCartValue] = useRecoilState(cartItems);
  const [selectedOption, setSelectedOption] = useState("menu_order");
  const [selectedPrice, setSelectedPrice] = useState(null);
  const [selectedImage, setSelectedImage] = useState("");

  const addToCart = async (item) => {
    const id = item?._id ? item?._id : productId;
    const itemExist = cartValue?.find((product) => product?.id === id);
    if (itemExist) {
      const currentQuantity = itemExist.quantity;
      const updatedQuantity = currentQuantity + 1;
      const updatedSubtotal = updatedQuantity * itemExist.price;

      const updatedItem = {
        ...itemExist,
        quantity: updatedQuantity,
        subtotal: updatedSubtotal,
      };

      const updatedCart = cartValue.map((item) => {
        if (item.id === updatedItem.id) {
          return updatedItem;
        }
        return item;
      });

      setCartValue(updatedCart);
    } else {
      const newItem = {
        ...item,
        id: item._id,
        quantity: 1,
        subtotal: item.price,
        Image: item?.image,
      };
      setCartValue([...cartValue, newItem]);
    }
  };
  useEffect(() => {
    window.scrollTo({
      top: -100,
      behavior: "auto",
    });
  }, []);
  const gameAddToCart = async (QTY) => {
    const id = productId;
    const itemExist = cartValue?.find((product) => product?.id === id);
    if (itemExist) {
      const currentQuantity = itemExist.quantity;
      const updatedQuantity = currentQuantity + QTY;
      const updatedSubtotal = updatedQuantity * itemExist.price;

      const updatedItem = {
        ...itemExist,
        quantity: updatedQuantity,
        subtotal: updatedSubtotal,
      };

      const updatedCart = cartValue.map((item) => {
        if (item.id === updatedItem.id) {
          return updatedItem;
        }
        return item;
      });

      setCartValue(updatedCart);
    } else {
      const newItem = {
        ...product?.data?.response,
        id: product?.data?.response._id,
        quantity: QTY,
        subtotal: product?.data?.response?.price * QTY,
        Image: product?.data?.response?.image,
      };
      setCartValue([...cartValue, newItem]);
    }
  };

  const {
    mutate,
    isLoading: mutationLoading,
    reset,
  } = useMutation(addToCart, {
    onSuccess: (data) => {
      setToast({ type: "Success", message: "Add to cart successfully" });
      queryClient.invalidateQueries("fetch-cart");
    },
    onError: (errors: any) => {
      const error = errors as any;
      setToast({
        type: "Error",
        message: error?.message || "Something went wrong",
      });
    },
  });

  const {
    isLoading,
    data: product,
    isError,
    error,
    refetch,
  } = useFetch(
    `${process.env.REACT_APP_SERVER_URL}/product/byId/${productId}`,
    [productId],
    {
      enabled: true,
    }
  );

  const toggleModal = () => setShowModal(!showModal);

  const handleSelectChange = (e) => {
    const selectedValue = e.target.value;
    setSelectedOption(selectedValue);

    const selectedOptionObject = product.data.response.option.find(
      (option) => option._id === selectedValue
    );
    setSelectedPrice(selectedOptionObject ? selectedOptionObject.price : null);
  };

  const handleClear = () => {
    setSelectedOption("menu_order");
    setSelectedPrice(null);
  };

  // Function to clean the description text
  const cleanDescription = (description) => {
    // Remove \n \t and any leading or trailing whitespace
    return description ? description.replace(/\\n|\\t/g, "").trim() : "";
  };

  useEffect(() => {
    const handleScroll = () => {
      setShowNavbar(window.scrollY > 50);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  if (isLoading)
    return (
      <div className="flex h-[80vh] items-center justify-center">
        <Loader forProduct />
      </div>
    );

  if (isError) return <div>Error: {error.message}</div>;

  const AddToCart = (id) => {};
  const options = product?.data?.response?.option || [];
  const prices = options.map((option) => option.price);

  const minPrice = options.length > 0 ? Math.min(...prices) : null;
  const maxPrice = options.length > 0 ? Math.max(...prices) : null;

  const handleImageClick = (imageSrc) => {
    setSelectedImage(imageSrc);
  };

  return (
    <div>
      <div
        className={`fixed top-0 left-0 w-full bg-white shadow-md z-[99] transition-transform duration-300 ${
          showNavbar ? "translate-y-0" : "-translate-y-full"
        } smallTablet:px-0 tablet:px-[10px]`}
      >
        <div className="flex justify-center">
          <div className="h-[70px] w-[1240px] flex justify-between desktop:px-5">
            <div className="flex items-center">
              <img
                src={product?.data?.response?.image}
                alt="brand-img"
                className="h-[300px] object-contain w-[300px] max-h-12 max-w-12 smallTablet:hidden"
              />
              <span className="px-5 text-[18px] leading-6 font-inherit mobile:hidden smallTablet:px-0">
                {product?.data?.response?.name}
              </span>
            </div>
            <div className="flex items-center">
              {options.length > 0 ? (
                <p className="font-bold text-[#4B4F58] text-center text-sm my-2 pr-5 mobile:hidden">
                  ${minPrice} - ${maxPrice}
                </p>
              ) : (
                <p className="font-bold text-[#4B4F58] text-center text-sm my-2 pr-5 mobile:hidden">
                  ${product?.data?.response?.price}
                </p>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="flex justify-center mt-10 tablet:px-[50px]">
        <div className="w-[1240px] flex justify-between desktop:px-5 smallTablet:flex-col">
          <div className="w-[60%] flex flex-col gap-[20px] smallTablet:w-auto">
            <h1 className="text-[#1C244B] text-[31px] font-semibold font-['poppins',Sans-serif] leading-[1em] tracking-normal smallTablet:!w-full mobile:!text-[50px] mb-[15px] tablet:text-[65px] tablet:w-[355px] ">
              {product?.data?.response?.name}
            </h1>

            <div className="text-[#324A6D]">
              <p>{cleanDescription(product?.data?.response?.description)}</p>
            </div>

            {options.length > 0 ? (
              <p className="font-bold text-[#1c244b] text-[36px] my-2 pr-5">
                ${minPrice} - ${maxPrice}
              </p>
            ) : (
              <p className="font-bold text-[#1c244b] text-[36px] my-2 pr-5">
                ${product?.data?.response?.price}
              </p>
            )}

            {product?.data?.response.option.length > 0 && (
              <div className="flex flex-col">
                <label
                  htmlFor="productType"
                  className="text-[15px] font-[700] text-[#4B4F58] mb-[10px]"
                >
                  TYPE
                </label>
                <select
                  id="productType"
                  className="text-[#666666] w-1/2 font-light text-sm px-3 border border-solid py-2 mobile:!w-full"
                  value={selectedOption}
                  onChange={handleSelectChange}
                >
                  <option value="menu_order ">Choose an option</option>
                  {product.data.response.option.map((option) => (
                    <option key={option._id} value={option._id}>
                      {option.name}
                    </option>
                  ))}
                </select>

                {/* Clear button */}
                <p
                  className="border-b border-solid text-[#666666] py-[5px] opacity-[.6] cursor-pointer"
                  onClick={handleClear}
                >
                  clear
                </p>

                {/* Price display */}
                {selectedOption !== "menu_order" && (
                  <p className="text-[#666666] font-[700] text-[1.5rem]  py-1">
                    $
                    {
                      product.data.response.option.find(
                        (option) => option?._id === selectedOption
                      )?.price
                    }
                  </p>
                )}
              </div>
            )}

            <div className="flex gap-[40px] h-[55px]">
              <div className="flex mx-2 h-[40px]">
                <button
                  disabled={qty <= 0 ? true : false}
                  onClick={() => setQty(qty - 1)}
                  className="md:p-2 border md:px-4 text-xs md:text-sm px-2 disabled:cursor-not-allowed"
                >
                  -
                </button>
                <button className="md:p-2 border md:px-4 text-xs md:text-sm px-2">
                  {qty}
                </button>
                <button
                  onClick={() => setQty(qty + 1)}
                  className="md:p-2 border md:px-4 text-xs md:text-sm px-2"
                >
                  +
                </button>
              </div>

              <div>
                <button
                  onClick={() => gameAddToCart(qty)}
                  className="capitalize rounded-full px-5 text-base font-bold text-center py-2 bg-[#0170b9] text-white hover:bg-white hover:text-[#0170b9] hover:border hover:border-blue-700"
                >
                  add to cart
                </button>
              </div>
            </div>
          </div>

          <div className="w-[50%] smallTablet:w-auto" onClick={toggleModal}>
            <div className="flex float-end cursor-pointer">
              <MdSearch className="w-[25px] h-[25px]" />
            </div>
            <img
              src={
                product?.data?.response?.option.find(
                  (data) => data._id === selectedOption
                )?.image || product?.data?.response?.image
              }
              alt="brand-img"
              className="w-[100%] h-[40vh] object-contain cursor-pointer"
            />
            {product?.data?.response.images &&
              product?.data?.response.images.length > 0 && (
                <ol className="flex items-center justify-between w-3/4 my-5 cursor-pointer">
                  {product?.data?.response.images.map((img, idx) => (
                    <li key={idx}>
                      <img
                        src={img}
                        alt="product-img"
                        className="h-[148px] w-[148px] object-contain"
                        draggable="false"
                        width="100"
                        // height="100"
                        onClick={() => handleImageClick(img)}
                      />
                    </li>
                  ))}
                </ol>
              )}
            {/* <div>aa</div> */}
          </div>
        </div>
      </div>

      {showModal && (
        <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-80 z-[100] flex justify-center items-center">
          <div className="bg-white p-5 rounded-lg relative">
            <button
              onClick={toggleModal}
              className="absolute top-3 right-3 text-black"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
            <img
              src={selectedImage}
              alt="brand-img"
              className="w-full h-auto"
            />
          </div>
        </div>
      )}
      <hr className="my-2" />
      <div>
        <h2 className="mb-[30px] mt-10 text-[#1C244b] text-center text-[42px] font-[600] font-['poppins',Sans-serif] leading-[1.2em]">
          Related products
        </h2>
      </div>
      <div className="flex justify-center py-[20px] flex-wrap tablet:px-[50px]">
        <ul className="grid grid-cols-3 mx-auto w-3/4 smallTablet:grid-cols-2 mobile:!grid-cols-1 max-w-[1200px] gap-x-12 flex-wrap ">
          {product.data.relevantProduct &&
            product.data.relevantProduct.length > 0 &&
            product.data?.relevantProduct?.map((item, index) => (
              <li
                key={index}
                className=" h-fit flex justify-center flex-col smallTablet:w-[100%] tablet:mt-[30px]"
              >
                <div
                  className="mb-[15px] "
                  onClick={() => (
                    navigate(`/product/${encodeURIComponent(item._id)}`),
                    refetch()
                  )}
                >
                  <figure className="h-52 bg-white mb-2 smallTablet:h-[15rem] cursor-pointer mobile:!h-[14rem]">
                    <img
                      className="w-full h-full mx-auto object-contain smallTablet:object-fill"
                      src={item.image}
                      alt={item.name}
                    />
                  </figure>
                </div>
                <h2 className="text-[#1C244B] text-center text-[15px] font-bold leading-[1.2em] mb-[7px] cursor-pointer">
                  {item.name}
                </h2>
                <p className="font-bold text-[#000002] text-center text-[17px] leading-[2.2em] mb-2">
                  ${item.price}
                </p>
                <button
                  onClick={() => addToCart(item)}
                  className="capitalize w-fit mx-auto font-quando rounded-full px-5 text-base font-medium py-2 bg-[#e11111] text-white duration-300 transition-all"
                >
                  {item?.option?.length <= 0 &&
                  cartValue?.find((data) => data.id === item._id) ? (
                    <span className="flex items-center gap-x-3">
                      Add to cart <FaCheck className="text-lg font-bold" />
                    </span>
                  ) : (
                    <span>Add to cart</span>
                  )}
                </button>
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
};

export default ProductChildLinks;
