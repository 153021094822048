import React, { ReactNode, useState } from "react";
// import avatar from "../../src/assests/avatar.svg";
import greyMenu from "../../src/assests/greyMenu.svg";
import * as Fa from "react-icons/fa";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { FaUser, FaUserLock, FaEnvelope, FaLock } from "react-icons/fa6";

import { UserInfo } from "./atoms/atoms";
import { useRecoilState } from "recoil";
import axios from "axios";
import { useMutation, useQueryClient } from "react-query";
import Toast from "./Toast/toast";
interface SimpleDialogProps {
  showDialog: boolean;
  onCloseDialog: () => void;
  title?: string;
  width?: string;
  height?: string;
  isLoading?: boolean;
  className?: string;
  showCloseIcon?: boolean;
}

const EditProfile = ({
  showDialog,
  onCloseDialog,
  showCloseIcon,
  title,
  width,
  height,

  className,
}: SimpleDialogProps) => {
  const overlayStyle: React.CSSProperties = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(28, 40, 64, 0.55)",
    zIndex: 49,
    display: showDialog ? "block" : "none",
  };
  const EditSchema = Yup.object().shape({
    fullName: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Required*"),

    email: Yup.string().email("Invalid email").required("Required*"),
  });
  const [userInfo, setUserInfo] = useRecoilState(UserInfo);
  const [toast, setToast] = useState({ type: "", message: "" });

  const updateUser = async (data: any) => {
    const { data: response } = await axios.patch(
      `${process.env.REACT_APP_SERVER_URL}/user/update`,
      data,
      {
        headers: {
          Authorization: `Bearer ${userInfo?.access_token}`,
        },
      }
    );
    setUserInfo((prevState) => ({
      ...prevState,
      user: {
        ...prevState.user,
        fullName: response.data?.fullName,
        email: response.data?.email,
      },
    }));

    return response.data;
  };
  const { mutate, isLoading } = useMutation(updateUser, {
    onSuccess: (data) => {
      setToast({ type: "Sucess", message: "User updated successfully" });
      //   navigate("/login");
      setTimeout(() => {
        onCloseDialog();
      }, 1000);
    },
    onError: (e: any) => {
      setToast({ type: "Error", message: e });
    },
  });

  return (
    <div>
      <div style={overlayStyle} onClick={onCloseDialog} />
      {showDialog && (
        <div
          className={`fixed p-4 z-50 min-w-[750px]  h-auto max-h-[95vh] overflow-y-auto rounded top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2   bg-white ${className} overflow-hidden smallTablet:!w-full smallTablet:!min-w-full`}
          style={{
            boxShadow: "5px 5px 10px 12px rgba(0,2,5,.2)",
          }}
        >
          {/* <p className="text-black  mx-auto text-center font-bold text-[38px]">
            Edit User Info
          </p> */}
          <p
            onClick={onCloseDialog}
            className="absolute right-0 cursor-pointer mr-4 text-2xl font-bold"
          >
            x
          </p>
          <Formik
            initialValues={{
              fullName: userInfo?.user?.fullName,
              email: userInfo?.user?.email,
            }}
            validationSchema={EditSchema}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              // Your form submission logic here

              mutate(values);
              resetForm({});
              setSubmitting(false);
            }}
          >
            {({ isSubmitting }) => (
              <Form className="mt-8 flex flex-col justify-start">
                <div className="flex mx-14 gap-x-8 my-3 items-center">
                  {toast.type && (
                    <Toast
                      type={toast.type as "Sucess" | "Error"}
                      message={toast.message}
                    />
                  )}
                  <div
                    style={{
                      fontFamily: "DM Sans, sans-serif",
                    }}
                    className="flex w-full flex-col gap-y-4 space-y-1 font-roboto text-sm"
                  >
                    <h1 className="text-4xl font-bold my-4"> Edit User Info</h1>
                    <div className="flex flex-col gap-y-1">
                      <div className="flex gap-x-6 items-center w-full border-b-[1.5px]  border-black">
                        {" "}
                        <FaUser className="text-lg" />
                        <Field
                          type="text"
                          name="fullName"
                          placeholder="Enter your full name"
                          className="w-full font-medium font-roboto text-base  p-2 rounded-md outline-none focus:border-[#3d24249a]"
                        />
                      </div>
                      <ErrorMessage
                        name="fullName"
                        component="div"
                        className="text-red-600"
                      />
                    </div>
                    <div className="flex flex-col gap-y-1">
                      <div className="flex gap-x-6 items-center w-full border-b-[1.5px] border-black">
                        {" "}
                        <FaEnvelope className="text-lg text-black" />
                        <Field
                          type="text"
                          name="email"
                          placeholder="Your email address"
                          className="w-full font-medium font-roboto text-base  p-2 rounded-md outline-none focus:border-[#3d24249a]"
                        />
                      </div>
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="text-red-600"
                      />
                    </div>

                    <div>
                      {/* {isLoading ? (
                        <Loader />
                      ) : ( */}
                      <button
                        type="submit"
                        className="bg-[#AD3511] w-1/2  rounded-md p-3 text-white font-roboto my-3"
                        disabled={isSubmitting}
                      >
                        {isLoading ? (
                          <div className="buttonLoader" />
                        ) : (
                          "Edit Info"
                        )}
                      </button>
                      {/* )} */}
                    </div>
                  </div>

                  <div className="w-3/4 medium:hidden ">
                    <img
                      className="h-[26vh] w-full"
                      src="https://technostorage.nyc3.digitaloceanspaces.com/uploads/2021/06/Poker-Slots-03-03.png"
                      alt="poker slots"
                    />
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      )}
    </div>
  );
};

export default EditProfile;
