import React from "react";

interface HeroProps {
  title?: string;
  image: string;
}

const Hero: React.FC<HeroProps> = ({ title, image }) => (
  <div
    style={{
      backgroundImage: `url(${image})`,
    }}
    className="w-full h-[38vh] relative bg-no-repeat bg-cover"
  >
    <div className="absolute inset-0  ">
      {title && (
        <h1
          style={{
            textShadow: "0 0 10px rgba(0,0,0,.3)",
            margin: "auto",
            background: "rgba(0, 0, 0, 0.5)",
          }}
          className="capitalize w-full h-[140px]  text-white absolute flex items-center justify-center text-[90px] font-bold inset-0 tablet:text-6xl tablet:h-[110px] smallTablet:!text-4xl smallTablet:!h-[90px] mobile:text-center"
        >
          {title?.toUpperCase()}
        </h1>
      )}
    </div>
  </div>
);

export default Hero;
