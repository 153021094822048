import * as Fa from "react-icons/fa";
import * as Md from "react-icons/md";
import { MobileNav } from "./index";
import logo from "../Asset/Technogenius-logo.png";
import { Link, NavLink, useNavigate, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { UserInfo, cartItems, searchValue } from "./atoms/atoms";
import { useRecoilState, useRecoilValue } from "recoil";
import EditProfile from "./EditProfile";
import useFetch from "../Network/useFetch";

const Navbar = () => {
  const [userInfo, setUserInfo] = useRecoilState(UserInfo);
  const [cartValue, setCartValue] = useRecoilState(cartItems);
  const [showModal, setShowModal] = useState(false);
  const location = useLocation();
  const Logout = () => {
    setUserInfo(null);
  };
  const calculateTotal = (items) => {
    let totalQuantity = 0;
    let totalPrice = 0;
    cartValue?.forEach((item) => {
      if (item.quantity > 0) {
        totalQuantity += item.quantity;
        totalPrice += item.subtotal;
      }
    });

    return { totalQuantity, totalPrice };
  };
  const EditModal = () => {
    setShowModal(true);
  };
  const {
    isLoading,
    data: categories,
    isError,
  } = useFetch(
    `${process.env.REACT_APP_SERVER_URL}/category/get-all`,
    ["fetch-all-category"],
    {
      enabled: true,
    }
  );
  const { totalQuantity, totalPrice } = calculateTotal(cartValue);
  const links = [
    {
      title: "Home",
      path: "/",
    },
    {
      title: "Gaming Machines",
      path: "/gaming-machines",
    },

    {
      title: "Products",
      path: "/products",
      icon: <Md.MdOutlineArrowDropDown />,
      childRoutes: categories
        ? categories.data?.map((category: any) => ({
            title: category.name,
            path: `/shop/${category._id}`,
            subRoutes: category.subcategory?.map((subcategory: any) => ({
              title: subcategory.name,
              path: `/shop/${subcategory._id}`,
            })),
          }))
        : [],
    },
    {
      title: "Services",
      path: "/services",
    },
    {
      title: "Contact US",
      path: "/contact",
    },
    {
      title: "About Us",
      path: "/about",
    },
    ...(userInfo
      ? [
          {
            title: userInfo?.user?.fullName,
            path: "/",
            icon: <Md.MdOutlineArrowDropDown />,
            childRoutes: [
              { title: "Edit Profile ", onClick: EditModal },
              { title: "Logout", onClick: Logout },
            ],
          },
        ]
      : [
          {
            title: "Login",
            path: "/login",
          },
          {
            title: "Signup",
            path: "/register",
          },
        ]),
  ];

  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [flag, setFlag] = useState(false);
  const navigate = useNavigate();
  const filterValue = useRecoilValue(searchValue);
  const handleSearch = (e) => {
    e.preventDefault();
    setFlag(true);
    if (searchTerm.trim()) {
      const formattedSearchTerm = searchTerm?.trim()?.replace(/\s+/g, "");
      console.log("formattedSearchTerm", formattedSearchTerm);
      navigate(`/search/${formattedSearchTerm}`);
    }
  };
  useEffect(() => {
    const newFilValue = filterValue?.replace(/%20|%26/g, " ");
    if (
      filterValue !== undefined &&
      filterValue !== null &&
      filterValue !== "undefined"
    ) {
      setSearchTerm(newFilValue);
      if (
        newFilValue !== "undefined" &&
        newFilValue !== null &&
        newFilValue !== null
      ) {
        flag && navigate(`/search/${newFilValue}`);
      }
    } else {
      setSearchTerm(location.pathname.split("/")[2]);
      newFilValue !== "undefined" &&
        newFilValue !== null &&
        newFilValue !== null &&
        flag &&
        navigate(`/search/${location.pathname.split("/")[2]}`);
    }
  }, [filterValue]);
  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    if (value.trim() === "") {
      navigate("/");
    }
  };
  return (
    <header className="flex flex-col w-full h-auto justify-between ">
      <div className="container mx-auto flex justify-center gap-[35%] items-center largeTablet:gap-[20%] h-fit py-4">
        <figure className="w-80 h-fit">
          <img
            src={logo}
            alt="techno logo"
            className="w-full h-full object-contain"
          />
        </figure>

        <div className="items-center justify-between bg-[#D59292] overflow-hidden hidden md:flex focus:bg-transparent w-96 ps-4">
          <input
            type="text"
            value={searchTerm?.replace(/%20/g, " ")}
            // onChange={(e) => setSearchTerm(e.target.value)}
            onChange={handleInputChange}
            className="bg-transparent h-full focus:outline-none placeholder-gray-600"
            placeholder="Search..."
          />
          <span className="flex items-center justify-center w-10 bg-red-600 h-10">
            {" "}
            <Fa.FaSearch color="white" onClick={handleSearch} />
          </span>
        </div>
      </div>

      <div className="bg-black z-20 w-full flex items-center smallTablet:justify-center relative">
        <button
          onClick={() => {
            setIsOpen(!isOpen);
          }}
          className="hidden smallTablet:block smallTablet:text-3xl smallTablet:py-2 smallTablet:font-bold"
        >
          {isOpen ? (
            <Md.MdOutlineClose className="text-white active:text-[#e82a00] hover:text-[#e82a00]" />
          ) : (
            <Md.MdOutlineMenu className="text-white active:text-[#e82a00] hover:text-[#e82a00]" />
          )}
        </button>

        <div
          className={`hidden smallTablet:block smallTablet:absolute smallTablet:top-[2.5rem] smallTablet:w-full smallTablet:left-0`}
        >
          <MobileNav menu={links} isOpen={isOpen} setIsOpen={setIsOpen} />
        </div>
        <div className="px-32 flex w-full items-center smallTablet:hidden largeTablet:px-16">
          <div className="flex w-full justify-center gap-x-7 items-center largeTablet:flex-col whitespace-nowrap tablet:py-2">
            <div className="flex items-center tablet:w-full tablet:justify-center">
              {links?.length > 0 &&
                links.map((item, i) => (
                  <div key={i} className="w-fit">
                    <NavLink
                      className="w-fit  gap-x-2 text-[15px] p-6 text-white capitalize font-semibold py-6 duration-300 transition-all relative before:absolute  before:h-full before:bg-red-700 before:top-0 before:left-0 before:-z-10 z-10 before:duration-300 before:transition-all hover:before:w-full cursor-pointer before:w-0 before:block flex items-center justify-center  group link tablet:px-3"
                      to={item?.path ? item?.path : ""}
                      onClick={(e) => {
                        if (item?.path == "/shop") {
                          e.preventDefault();
                        }
                      }}
                    >
                      {item.title}
                      {item.icon && item.icon}
                      {item.childRoutes && (
                        <ul className="absolute hover:bg-[#ededed] bg-[#f2f2f2] text-black flex flex-col gap-4 font-normal pl-6  w-[16rem] top-[4.5rem] overflow-hidden hover:overflow-visible h-0 transition-all duration-300 group-hover:h-fit group-hover:py-2 left-0">
                          {item.childRoutes.map((route, i) => (
                            <li
                              key={i}
                              className="p-[5px] flex items-center text-[13px] nest-wrapper hover:text-green-500"
                            >
                              {route.onClick ? (
                                // If onClick is provided, render a div with an onClick handler
                                <div onClick={route.onClick}>{route.title}</div>
                              ) : (
                                <NavLink
                                  to={route?.path}
                                  className={({ isActive }) =>
                                    isActive
                                      ? "text-green-500"
                                      : "hover:text-green-500"
                                  }
                                >
                                  {route?.title}
                                </NavLink>
                              )}
                              <span className="text-[25px] rotate-[270deg]">
                                {route && route?.subRoutes?.length > 0 && (
                                  <Md.MdOutlineArrowDropDown />
                                )}
                              </span>
                              {route.subRoutes && (
                                <div
                                  className={`${
                                    route && route.subRoutes?.length > 0
                                      ? "nested-ul relative"
                                      : "hidden"
                                  }`}
                                >
                                  <ul
                                    className={`absolute bg-[#ededed] text-black flex-col gap-4 font-normal pr-6 min-w-[15rem] max-w-[28rem] overflow-hidden h-0 transition-all duration-300 group-hover:h-fit ${
                                      route && route.subRoutes?.length > 2
                                        ? "left-[37px] top-[-39px]"
                                        : "left-[170px] top-[-24px]"
                                    } py-2 pl-[50px] whitespace-nowrap`}
                                  >
                                    {route.subRoutes?.map(
                                      (childRoute: any, i: any) => (
                                        <li
                                          key={i}
                                          className="p-[5px] hover:text-green-500 flex items-center text-center"
                                        >
                                          <NavLink
                                            to={childRoute.path}
                                            className={({ isActive }) =>
                                              isActive
                                                ? "text-green-500"
                                                : "hover:text-green-500"
                                            }
                                          >
                                            {childRoute.title}
                                          </NavLink>
                                        </li>
                                      )
                                    )}
                                  </ul>
                                </div>
                              )}
                            </li>
                          ))}
                        </ul>
                      )}
                    </NavLink>
                  </div>
                ))}
            </div>

            <Link
              to={"/cart"}
              className="flex items-center h-full text-red-500 gap-2 transition-all duration-300 hover:text-gray-700 tablet:py-2"
            >
              {" "}
              <Fa.FaShoppingCart />
              {`${totalQuantity} items -USD$ ${totalPrice}`}
            </Link>
          </div>
        </div>
      </div>
      <EditProfile
        showDialog={showModal}
        onCloseDialog={() => setShowModal(!showModal)}
      />
    </header>
  );
};

export default Navbar;
