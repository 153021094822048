import React from "react";
import GoogleMap from "../GoogleMap";
import phone from "../../assets/black-phone .png";
const ContactSection = () => {
  return (
    <div
      className="w-full "
      style={{
        backgroundImage:
          "url(https://technostorage.nyc3.digitaloceanspaces.com/uploads/2021/07/slate-texture.jpg)",
        backgroundSize: "cover",
      }}
    >
      <div className="mx-auto pt-20 max-w-[1140px] w-full flex flex-col gap-y-14">
        <div className="flex smallTablet:flex-col">
          <div
            className="w-1/2 h-[400px] flex flex-col justify-between smallTablet:w-full smallTablet:h-auto"
            style={{
              backgroundColor: "#B20000",
            }}
          >
            <div className="w-full">
              <div
                className="h-[135px]  mt-12 mb-10 flex  items-center smallTablet:flex-col  smallTablet:h-[200px]"
                style={{
                  backgroundColor: "#bf0202",
                }}
              >
                <div className=" w-[30.5%]  p-2.5 flex justify-center smallTablet:w-full smallTablet:flex-col smallTablet:items-center">
                  <svg
                    aria-hidden="true"
                    className="w-[80px] h-[80px] smallTablet:w-[60px]  smallTablet:h-[80px] e-font-icon-svg e-far-building"
                    viewBox="0 0 448 512"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M128 148v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12zm140 12h40c6.6 0 12-5.4 12-12v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12zm-128 96h40c6.6 0 12-5.4 12-12v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12zm128 0h40c6.6 0 12-5.4 12-12v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12zm-76 84v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm76 12h40c6.6 0 12-5.4 12-12v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12zm180 124v36H0v-36c0-6.6 5.4-12 12-12h19.5V24c0-13.3 10.7-24 24-24h337c13.3 0 24 10.7 24 24v440H436c6.6 0 12 5.4 12 12zM79.5 463H192v-67c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v67h112.5V49L80 48l-.5 415z"
                      style={{
                        fill: "#FFF",
                      }}
                    ></path>
                  </svg>
                </div>
                <div className="w-[70%]  h-[135px] p-2.5 smallTablet:h-[100px] smallTablet:text-center">
                  <h2
                    className="font-roboto text-[17px] "
                    style={{
                      color: "#FFF",
                      fontSize: "17px",
                      fontWeight: "400",
                    }}
                  >
                    Location:{" "}
                  </h2>
                  <h2
                    className="font-roboto text-[30px] tablet:text-[22px] smallTablet:!text-[18px]"
                    style={{
                      color: "#FFF",
                      fontWeight: "600",
                    }}
                  >
                    Shop 4B, 17 Molynes Rd, Kingston Jamaica
                  </h2>
                </div>
              </div>
              <hr className="mx-auto mt-10 mb-5 w-full border-solid border border-white" />
              <div
                className="w-full h-[135px]  p-2.5 flex  items-center smallTablet:w-full smallTablet:flex-col smallTablet:h-[205px] "
                style={{
                  backgroundColor: "#bf0202",
                }}
              >
                <div className="w-[30.5%] p-2.5 flex justify-center smallTablet:w-full smallTablet:h-[80px] smallTablet:items-center">
                  <svg
                    aria-hidden="true"
                    className="w-[80px] h-[80px]  smallTablet:w-[60px] smallTablet:w-[60px] e-font-icon-svg e-far-clock"
                    viewBox="0 0 512 512"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{
                      fill: "#FFF",
                    }}
                  >
                    <path d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200zm61.8-104.4l-84.9-61.7c-3.1-2.3-4.9-5.9-4.9-9.7V116c0-6.6 5.4-12 12-12h32c6.6 0 12 5.4 12 12v141.7l66.8 48.6c5.4 3.9 6.5 11.4 2.6 16.8L334.6 349c-3.9 5.3-11.4 6.5-16.8 2.6z"></path>
                  </svg>
                </div>
                <div className="w-[70%] h-[135px] p-2.5 smallTablet:w-full  smallTablet:h-full smallTablet:text-center">
                  <h2
                    className="font-roboto text-[17px]"
                    style={{
                      color: "#FFF",
                      fontWeight: "400",
                    }}
                  >
                    Business Opening Hours
                  </h2>
                  <h2
                    className="font-roboto text-[30px] smallTablet:text-[22px]"
                    style={{
                      color: "#FFF",
                      fontWeight: "600",
                    }}
                  >
                    Monday- Saturday
                  </h2>
                  <h2
                    className="font-roboto text-[30px] smallTablet:text-[26px]"
                    style={{
                      color: "#FFF",
                      fontWeight: "600",
                    }}
                  >
                    9am-6pm
                  </h2>
                </div>
              </div>
            </div>
          </div>
          <div
            className="w-1/2 h-[400px] smallTablet:w-full smallTablet:mx-auto"
            style={{
              backgroundColor: "#fff",
            }}
          >
            <GoogleMap />
          </div>
        </div>
      </div>
      <div className="w-full   mt-14 relative   bg-black  ">
        <div className="max-w-[1140px] pb-40 w-full mx-auto flex gap-x-10 smallTablet:flex-col tablet:pb-10">
          <div
            className="w-[47%] h-[158px] py-2.5   flex justify-end  items-center smallTablet:flex-col smallTablet:w-full  smallTablet:h-[220px] "
            style={{
              backgroundColor: "#fff",
            }}
          >
            <div className="flex justify-between bg-black w-full">
              <img
                src={phone}
                className="h-full k py-4  w-1/4 object-contain"
              />
              <div
                className="h-[158px] p-2.5 w-full  smallTablet:w-full smallTablet:h-[109px] smallTablet:text-center"
                style={{
                  backgroundColor: "#bf0202",
                }}
              >
                <h2
                  className="font-roboto text-[17px] "
                  style={{
                    color: "#FFF",
                    fontWeight: "400",
                  }}
                >
                  Call Us Directly at:
                </h2>
                <h2
                  className="font-roboto mb-1 text-[30px] smallTablet:text-[20px]"
                  style={{
                    color: "#FFF",
                    fontWeight: "600",
                  }}
                >
                  <a href="tel:18766484163">+1(876) 648-4163</a>
                </h2>
                <h2
                  className="font-roboto text-[30px] smallTablet:text-[20px]"
                  style={{
                    color: "#FFF",
                    fontWeight: "600",
                  }}
                >
                  <a href="tel:18764482032">+1(876) 448-2032</a>
                </h2>
              </div>
            </div>
          </div>

          <div
            className="w-[50%]  h-[158px]  p-2.5 flex  items-center  smallTablet:flex-col smallTablet:w-full  smallTablet:h-[201px]"
            style={{
              backgroundColor: "#000",
            }}
          >
            <div className="w-[34%]  h-[136px] p-2.5 flex justify-center smallTablet:w-full smallTablet:h-[90px] ">
              <svg
                aria-hidden="true"
                className="w-[60%] h-[110px] smallTablet:h-[65px] e-font-icon-svg e-fab-instagram"
                viewBox="0 0 448 512"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"
                  style={{
                    fill: "#FFF",
                  }}
                ></path>
              </svg>
            </div>
            <div
              className="w-[69%]   h-[136px] p-2.5 flex flex-col content-center justify-evenly smallTablet:w-full smallTablet:h-[90px]  smallTablet:text-center"
              style={{
                backgroundColor: "#fff",
              }}
            >
              <h2
                className="font-roboto text-[17px]"
                style={{
                  color: "#E00000",
                  fontWeight: "400",
                }}
              >
                <a
                  href="https://www.instagram.com/technogeniousja/?hl=en"
                  target="_blank"
                >
                  Follow us on Instagram{" "}
                </a>
              </h2>
              <h2
                className="font-roboto text-[30px] smallTablet:text-[22px]"
                style={{
                  color: "#E00000",
                  fontWeight: "600",
                }}
              >
                <a
                  href="https://www.instagram.com/technogeniousja/?hl=en"
                  target="_blank"
                >
                  @technogeniousja{" "}
                </a>
              </h2>
            </div>
          </div>
        </div>
        <div className=" absolute ml-10  right-0 top-0   smallTablet:hidden medium:hidden largeTablet:hidden ">
          <img
            className=" h-[340px]"
            style={{
              overflowX: "hidden",
            }}
            src="https://technostorage.nyc3.digitaloceanspaces.com/uploads/2021/07/blue-shirt-phone-guy.png"
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

export default ContactSection;
