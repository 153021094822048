import React, { useEffect } from "react";

const WarrantySection = () => {
  let topImage =
    "https://technostorage.nyc3.digitaloceanspaces.com/uploads/2021/09/Warranty-IMage-2.jpg";
  let title = "TECHNOGENIOUS";
  useEffect(() => {
    window.scrollTo({
      top: 20,
      behavior: "auto",
    });
  }, []);
  return (
    <div>
      {/* header */}
      <div className="">
        <div
          style={{
            backgroundImage: `url(${topImage})`,
          }}
          className="w-full h-[66vh] relative bg-no-repeat bg-cover smallTablet:h-[35vh]"
        >
          <div className="absolute inset-0 bg-black opacity-70"></div>
          <div className=" absolute flex flex-col items-center justify-center inset-0 ">
            <h1 className="capitalize text-white  text-8xl font-bold  smallTablet:text-4xl">
              {title}
            </h1>
            <h2 className="capitalize text-white  text-[52px] font-semibold smallTablet:text-3xl smallTablet:py-2">
              WARRANTIES
            </h2>
          </div>
        </div>

        <div className="flex">
          <div className="flex justify-center items-start mt-6 w-1/2  smallTablet:hidden">
            <div>
              <img
                src="https://technostorage.nyc3.digitaloceanspaces.com/uploads/2021/09/Warranty-IMage.png"
                alt="leftimage"
              />
            </div>
          </div>
          <div className="w-1/2 px-8 smallTablet:w-full smallTablet:px-1">
            <div className="mt-14  smallTablet:px-3">
              <p
                style={{
                  fontFamily: "'Montserrat', Sans-serif",
                }}
                className="break-all text-[15px] leading-6 font-normal font-['Montserrat',Sans-serif] mb-7"
              >
                <strong>Product Guarantee New Equipment</strong>
                <br />
                Other than abuse, acts of God, or improper servicing,
                Technogenious Solutions Ltd warrants your new machine for sixty
                (60) days from date of delivery. After sixty (60) days
                Components warranted by the Manufacturer for a longer period of
                time must be sent to the MANUFACTURER FOR REPAIR or replacement
                under the Warranty Policy set by the Manufacturer. If the
                customer wants Technogenious Solutions Ltd to exchange or repair
                the defective item he does so with the full understanding that
                he will be billed at Technogenious Solution’s current service
                rates.
              </p>
              <p
                style={{
                  fontFamily: "'Montserrat', Sans-serif",
                }}
                className="break-all  text-[15px] leading-6 font-normal font-['Montserrat', Sans-serif] mb-7"
              >
                <strong>Limited Product Guarantee Used Equipment</strong>
                <br />
                Other than abuse, acts of God, or improper servicing,
                Technogenious Solutions Ltd warrants your machine for thirty
                (30) days. During that thirty (30) day period, the Customer must
                return the defective part to Technogenious Solutions Ltd for
                repair or exchange.
              </p>
              <p
                style={{
                  fontFamily: "'Montserrat', Sans-serif",
                }}
                className="break-all  text-[15px] leading-6 font-normal font-['Montserrat', Sans-serif] mb-7"
              >
                <strong>Repair Warranty Policy</strong>
                <br />
                All Gaming component repairs (Logic boards, Monitors, &nbsp;Bill
                validators, and Printers) are warranted for ninety (45) days;
                all other items are warranted for thirty (15) days from date of
                completion including road service. Items warranted are specific
                to the initial repair only and any additional problems or
                failures that may result that are unrelated to the original
                repair are not covered.
                <br />
                <br />
                Improper servicing or abuse will VOID any and all existing
                Warranties. The Customer is responsible for all Freight charges
                incurred under these Warranty Policies.
                <br />
                <br />
                All warranty claims must be made when the item is brought to
                Technogenious Solutions Ltd for service. Customer must provide
                machine serial number to verify warranty. Failure to make a
                warranty claim or provide the serial number at the time of
                service will result in warranty service not being honored.
              </p>
              <p
                style={{
                  fontFamily: "'Montserrat', Sans-serif",
                }}
                className="break-all  text-[15px] leading-6 font-normal font-['Montserrat', Sans-serif] mb-7"
              >
                <strong>
                  Technogenious Solutions Ltd Customer Property Policy
                </strong>
                <br />
                All customer property must be removed from Technogenious
                Solutions Ltd within thirty (30) days after notification that
                your property or repair is ready for pick up. After thirty (30)
                days a storage fee of $250 per day may be billed to your account
                up to fifteen (15) days. After that Technogenious Solutions Ltd
                will assume ownership, considering it an abandoned property.
              </p>
              <p
                style={{
                  fontFamily: "'Montserrat', Sans-serif",
                }}
                className="break-all  text-[15px] leading-6 font-normal font-['Montserrat', Sans-serif] mb-7"
              >
                <strong>Return Policy</strong>
                <br />
                All claims must be made within seven days from receipt of the
                product. Authorized returns are subject to a 30% restocking fee.
                Return Authorization number is required.
              </p>
              <p
                style={{
                  fontFamily: "'Montserrat', Sans-serif",
                }}
                className="break-all  text-[15px] leading-6 font-normal font-['Montserrat', Sans-serif] mb-7"
              >
                <strong>Governing Law</strong>
                <br />
                This agreement shall be governed by and construed in accordance
                with the laws of Jamaica.
              </p>
              <p
                style={{
                  fontFamily: "'Montserrat', Sans-serif",
                }}
                className="break-all  text-[15px] leading-6 font-normal font-['Montserrat', Sans-serif] mb-7"
              >
                <strong>Fraudulent or Improper Use of Machines</strong>
                <br />
                Buyer hereby acknowledges and agrees that the Seller shall not
                be responsible for any damages, losses or liability incurred by
                Buyer as a result of improper, fraudulent or criminal use of the
                Equipment by anyone, including but not limited to direct,
                incidental or consequential damages and losses arising out of
                any person’s improper, fraudulent or criminal use or conduct
                with respect to the Equipment.
                <br />
                <br />
                Title to the equipment covered by this invoice is retained by
                the seller until the payment in full of amount hereof with
                exceptions by the purchaser of equipment to conditions herein
                stated.
              </p>{" "}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WarrantySection;
