import React from "react";
import { product } from "../constant";
import CarouselProducts from "./CarouselProducts";

const FeatureProducts: React.FC = () => {
  return (
    <>
      {product.length === 0 ? (
        <h2>Loading...</h2>
      ) : (
        <>
          <div className="h-full max-w-[1140px] mx-auto w-full flex flex-col">
            <div className="text-center  p-3 h-[85px] flex items-center tablet:justify-center">
              <h1 className="text-[50px] text-[#C03C12] font-roboto font-bold  tablet:text-3xl">
                Featured Products
              </h1>
            </div>
          </div>
          <CarouselProducts />
        </>
      )}
    </>
  );
};

export default FeatureProducts;
