import React, { useEffect, useState } from "react";
import { RangeSlider } from "./index";
import { useRecoilState, useSetRecoilState } from "recoil";
import {
  FilterCheckBox,
  FilterInfo,
  MaxFilterSlider,
  MinFilterSlider,
  searchValue,
} from "./atoms/atoms";
import MultiRangeSlider from "multi-range-slider-react";
import { useLocation, useParams } from "react-router-dom";
import Loader from "./Loader";

type Props = {
  data?: any;
  isLoading?: boolean;
  searchPage?: boolean;
};
interface InputEvent {
  minValue: number;
  maxValue: number;
}

const SideFilter: React.FC<Props> = React.memo(
  ({ isLoading, data, searchPage }) => {
    const [filterArray, setFilterArray] = useRecoilState(FilterCheckBox);
    const [filterRecoil, setFilterRecoil] = useRecoilState(FilterInfo);
    // const setSearchValue = useSetRecoilState(searchValue);

    const location = useLocation();
    const [maxRange, setMaxRange] = useRecoilState(MaxFilterSlider);
    const [minRange, setMinRange] = useRecoilState(MinFilterSlider);
    const [minValue, setMinValue] = useState<number>(
      filterRecoil?.ranges?.lowestPrice
        ? filterRecoil?.ranges?.lowestPrice
        : data?.data?.priceRange?.lowestPrice ?? 0
    );
    const [maxValue, setMaxValue] = useState<number>(
      filterRecoil?.ranges?.highestPrice
        ? filterRecoil?.ranges?.highestPrice
        : data?.data?.priceRange?.highestPrice ?? 1767
    );
    const handleInput = (e: InputEvent) => {
      setMinValue(e.minValue);
      setMaxValue(e.maxValue);
    };

    const handleChange = (event: any) => {
      const { id } = event.target;

      // Add or remove the selected item from the array
      if (filterArray?.includes(id)) {
        setFilterArray(null);
      } else {
        setFilterArray(id);
      }
    };

    const handleClick = () => {
      setFilterRecoil({
        filters: filterArray,
        ranges: { lowestPrice: minValue, highestPrice: maxValue },
        searchPage: searchPage ? searchPage : false,
      });
      setMinRange(minValue);
      setMaxRange(maxValue);
    };
    return (
      <aside className="w-full">
        <div>
          <MultiRangeSlider
            className="shadow-none border-0"
            barInnerColor="lightGrey"
            barLeftColor="white"
            barRightColor="white"
            ruler={false}
            min={data?.data?.priceRange?.lowestPrice ?? 0}
            max={data?.data?.priceRange?.highestPrice ?? 1767}
            step={5}
            minValue={
              filterRecoil?.ranges?.lowestPrice
                ? filterRecoil?.ranges?.lowestPrice
                : minValue ?? 0
            }
            maxValue={
              filterRecoil?.ranges?.highestPrice
                ? filterRecoil?.ranges?.highestPrice
                : maxValue ?? 1767
            }
            onInput={(e) => {
              handleInput(e);
            }}
          />
          {isLoading ? (
            <Loader />
          ) : (
            <div className="text-center text-base font-light">
              USD$ {minValue} - {maxValue}
            </div>
          )}
        </div>

        <div className="flex flex-col  gap-4 mt-4">
          {data?.data?.filter?.length ? (
            <ul className="w-full max-h-[210px] gap-2 flex flex-col scrol overflow-x-hidden overflow-y-auto  scrollbar-thumb-gray-500 scrollbar-track-gray-200">
              {data?.data?.filter?.map((item: any, index: number) => (
                <div key={index} className="flex gap-2 items-center">
                  <input
                    type="checkbox"
                    id={item}
                    checked={filterArray === item}
                    onChange={handleChange}
                  />
                  <label
                    className="capitalize text-sm font-light"
                    htmlFor={item}
                  >
                    {item}
                  </label>
                </div>
              ))}
            </ul>
          ) : null}

          <div className="flex gap-2  items-center">
            <input type="checkbox" id="featured" />
            <label className="capitalize font-light" htmlFor="featured">
              featured
            </label>
          </div>

          <div className="flex gap-2  items-center">
            <input type="checkbox" id="sale" />
            <label className="capitalize font-light" htmlFor="sale">
              on sale
            </label>
          </div>

          <button
            onClick={handleClick}
            className="bg-[#0170b9]  text-white uppercase font-bold rounded-md px-9 py-4 w-fit"
          >
            filter
          </button>
        </div>
      </aside>
    );
  }
);

export default SideFilter;
