import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";

const { persistAtom } = recoilPersist();

// export const UserInfo = atom({
//   key: "User", // unique ID (with respect to other atoms/selectors)
//   default: null,
//   effects_UNSTABLE: [persistAtom],
//   // default value (aka initial value)
// });
const TEN_MINUTES = 6 * 60 * 1000; // 10 minutes in milliseconds

const persistAtomWithExpiry = ({ key, default: defaultValue }) => {
  const persistedValue = localStorage.getItem(key);
  const initial =
    persistedValue != null ? JSON.parse(persistedValue) : defaultValue;

  const atomState = atom({
    key,
    default: initial,
    effects_UNSTABLE: [
      ({ onSet }) => {
        onSet((newValue) => {
          localStorage.setItem(key, JSON.stringify(newValue));
        });
      },
    ],
  });

  setTimeout(() => {
    localStorage.removeItem(key);
  }, TEN_MINUTES);

  return atomState;
};

export const UserInfo = persistAtomWithExpiry({
  key: "User",
  default: null,
});
export const FilterInfo = atom({
  key: "Filters", // unique ID (with respect to other atoms/selectors)
  default: null,

  // default value (aka initial value)
});

export const FilterCheckBox = atom({
  key: "Filters-checkbox", // unique ID (with respect to other atoms/selectors)
  default: null,

  // default value (aka initial value)
});
export const searchValue = atom({
  key: "search-value", // unique ID (with respect to other atoms/selectors)
  default: null,

  // default value (aka initial value)
});

export const MinFilterSlider = atom({
  key: "Filters-min-slider", // unique ID (with respect to other atoms/selectors)
  default: null,

  // default value (aka initial value)
});

export const MaxFilterSlider = atom({
  key: "Filters-max-slider", // unique ID (with respect to other atoms/selectors)
  default: null,

  // default value (aka initial value)
});

export const cartItems = atom({
  key: "Cart-items",
  default: [],
  effects_UNSTABLE: [persistAtom],
});

export const DataId = persistAtomWithExpiry({
  key: "data-id",
  default: [],
  // effects_UNSTABLE: [persistAtom],
});
