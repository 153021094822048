import React, { useEffect, useState } from "react";
import { FaUser, FaUserLock, FaEnvelope, FaLock } from "react-icons/fa6";
import logo from "../Asset/Technogenius-logo.png";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import usePostRequest from "../Network/usePost";
import { FaArrowLeft } from "react-icons/fa6";

import axios from "axios";
import { useMutation, useQueryClient } from "react-query";
import Toast from "./Toast/toast";
import { useNavigate } from "react-router-dom";
import Loader from "./Loader";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { UserInfo, cartItems } from "./atoms/atoms";

import { Link, NavLink } from "react-router-dom";
import ForgotModal from "./ForgotModal";
// import Toast from "./Toast/Toast";

function SignIn() {
  const [showForgotModal, setShowForgotModal] = useState(false);
  const userInfo = useRecoilValue(UserInfo);
  const SignInSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Required*"),
    password: Yup.string().required("Required*"),
  });
  const icon = document.getElementById("togglePassword") as any;
  let password = document.getElementById("password") as any;
  const navigate = useNavigate();
  const [cartValue, setCartValue] = useRecoilState(cartItems);
  function togglePassword() {
    if (password?.type === "password") {
      password.type = "text";
      icon.classList.add("fa-eye-slash");
      icon.classList.remove("fa-eye");
    } else {
      password.type = "password";
      icon.classList.add("fa-eye");
      icon.classList.remove("fa-eye-slash");
    }
  }
  const setUserInfo = useSetRecoilState(UserInfo);
  const queryClient = useQueryClient();
  const loginUser = async (data: any) => {
    const { data: response } = await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/sign-in`,
      data
    );
    return response.data;
  };

  const [toast, setToast] = useState({ type: "", message: "" });

  const { mutate, isLoading } = useMutation(loginUser, {
    onSuccess: async (data) => {
      setUserInfo(data);
      setToast({ type: "Sucess", message: "Login successfully" });

      navigate("/");
    },

    onError: (errors) => {
      const error = errors as any;
      setToast({
        type: "Error",
        message: error?.response?.data?.message || "Something went wrong",
      });
    },
  });

  return (
    <div className=" h-full w-full mx-auto relative">
      <div className="flex   justify-center ">
        <div
          style={{
            boxShadow: "5px 5px 10px 12px rgba(0,2,5,.2)",
          }}
          className="my-8  bg-white w-1/3 rounded-lg  tablet:w-[65%] smallTablet:!w-[95%]"
        >
          <Link
            className="w-full font-bold text-[#AD3511] text-2xl mx-auto"
            to="/"
          >
            <FaArrowLeft className="m-3" />
          </Link>

          {toast.type && (
            <Toast
              type={toast.type as "Sucess" | "Error"}
              message={toast.message}
            />
          )}

          <figure className="w-1/2 mx-auto my-4 h-fit ">
            <img
              src={logo}
              alt="techno logo"
              className="w-full h-full object-contain"
            />
          </figure>
          <hr className="border-t-[3px] w-1/2 mx-auto border-[#AD3511]" />

          <Formik
            initialValues={{
              email: "",
              password: "",
            }}
            validationSchema={SignInSchema}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              mutate(values);
              resetForm();
              setSubmitting(false);
            }}
          >
            {({ isSubmitting }) => (
              <Form className="mt-8 flex flex-col justify-start">
                <div className="flex mx-14  gap-x-8 my-3 items-center">
                  <div
                    style={{
                      fontFamily: "DM Sans, sans-serif",
                    }}
                    className="flex w-full flex-col gap-y-4 space-y-1 font-roboto text-sm"
                  >
                    <h1 className="text-4xl font-bold my-4">Sign In</h1>

                    <div className="flex flex-col gap-y-1">
                      <div className="flex gap-x-6 items-center w-full border-b-[1.5px] border-black">
                        {" "}
                        <FaEnvelope className="text-lg text-black" />
                        <Field
                          type="text"
                          name="email"
                          placeholder="Your email address"
                          className="w-full font-medium font-roboto text-base  p-2 rounded-md outline-none focus:border-[#3d24249a]"
                        />
                      </div>
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="text-red-600"
                      />
                    </div>
                    <div className="flex flex-col gap-y-1">
                      <div className="flex gap-x-6 items-center w-full border-b-[1.5px]  border-black">
                        {" "}
                        <FaLock className="text-lg text-black" />
                        <Field
                          id="password"
                          type="password"
                          name="password"
                          placeholder="Password"
                          className="w-full font-medium font-roboto text-base  p-2 rounded-md outline-none focus:border-[#3d24249a]"
                        />
                        <i
                          onClick={togglePassword}
                          className="fa fa-eye w-[8%] hover:text-blue-500"
                          id="togglePassword"
                        ></i>
                      </div>
                      <ErrorMessage
                        name="password"
                        component="div"
                        className="text-red-600"
                      />
                    </div>

                    <div className="mx-20">
                      {isLoading ? (
                        <div className="w-full flex justify-center">
                          {" "}
                          <Loader />
                        </div>
                      ) : (
                        <button
                          type="submit"
                          className="bg-[#AD3511] w-full text-base font-bold  rounded-md p-3 text-white font-roboto my-3"
                          disabled={isSubmitting}
                        >
                          {"Login"}
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
          <p
            onClick={() => {
              setShowForgotModal(true);
            }}
            className="text-[#AD3511] cursor-pointer my-5 flex justify-center w-full font-bold"
          >
            Forgotten password?
          </p>
          <ForgotModal
            showDialog={showForgotModal}
            onCloseDialog={() => setShowForgotModal(!showForgotModal)}
          />
        </div>
      </div>
    </div>
  );
}

export default SignIn;
