import React from "react";
import logo from "../../Asset/Technogenius-logo.png";
import * as Fa from "react-icons/fa";

const LogoAndContent = () => {
  const scrollToSection = (id: string) => {
    const manufacturingSection = document.getElementById(id);
    if (manufacturingSection) {
      const offset = -100; // Adjust this value to set the desired offset from the top
      const elementPosition = manufacturingSection.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset + offset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  };

  return (
    <div className="w-full p-3 mt-20 mb-8 max-w-[1200px] mx-auto grid grid-cols-3 items-center  h-full gap-x-5  smallTablet:!grid-cols-1 smallTablet:gap-y-14 ">
      <div className="h-[30vh] smallTablet:h-[25vh]">
        <div className="flex w-full h-[25vh]  gap-x-3  smallTablet:flex-col smallTablet:items-center smallTablet:gap-y-4 smallTablet:h-[12vh]">
          <div className="w-fit">
            <svg
              aria-hidden="true"
              fill="#AB0E13"
              id="icon-zoom"
              className=" text-[50px] h-14 w-14"
              viewBox="0 0 512 512"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M501.1 395.7L384 278.6c-23.1-23.1-57.6-27.6-85.4-13.9L192 158.1V96L64 0 0 64l96 128h62.1l106.6 106.6c-13.6 27.8-9.2 62.3 13.9 85.4l117.1 117.1c14.6 14.6 38.2 14.6 52.7 0l52.7-52.7c14.5-14.6 14.5-38.2 0-52.7zM331.7 225c28.3 0 54.9 11 74.9 31l19.4 19.4c15.8-6.9 30.8-16.5 43.8-29.5 37.1-37.1 49.7-89.3 37.9-136.7-2.2-9-13.5-12.1-20.1-5.5l-74.4 74.4-67.9-11.3L334 98.9l74.4-74.4c6.6-6.6 3.4-17.9-5.7-20.2-47.4-11.7-99.6.9-136.6 37.9-28.5 28.5-41.9 66.1-41.2 103.6l82.1 82.1c8.1-1.9 16.5-2.9 24.7-2.9zm-103.9 82l-56.7-56.7L18.7 402.8c-25 25-25 65.5 0 90.5s65.5 25 90.5 0l123.6-123.6c-7.6-19.9-9.9-41.6-5-62.7zM64 472c-13.2 0-24-10.8-24-24 0-13.3 10.7-24 24-24s24 10.7 24 24c0 13.2-10.7 24-24 24z"></path>
            </svg>
          </div>
          <div className="w-full">
            <div className="flex flex-col w-full  gap-y-2 h-[25vh]  smallTablet:flex-col smallTablet:items-center smallTablet:h-[12vh]">
              <span className="font-roboto text-[24px] font-semibold">
                Manufacturing
              </span>
              <span
                className="text-sm smallTablet:text-center "
                style={{ fontFamily: "Montserrat',Sans-serif" }}
              >
                We are licensed by BGLC to manufacture and assemble gaming
                machines.{" "}
              </span>
              <button
                onClick={() => scrollToSection("Manufacturing")}
                className="w-fit my-2 flex items-center gap-x-2 px-4 text-[13px] border-2 border-black py-2 rounded-md"
              >
                <Fa.FaArrowRight className="h-3 w-3" color="black" />
                Learn more
              </button>
            </div>
            <div className="my-6 border w-[80%] border-b-black font-bold smallTablet:hidden"></div>
          </div>
        </div>
      </div>
      <div className="h-[30vh] smallTablet:hidden">
        <div className="flex w-full  items-center h-[25vh] gap-x-6 smallTablet:h-[12vh]">
          <div className="flex w-3/4 flex-col  justify-center ">
            <img id="img-zoom" className="w-11/12 h-auto" src={logo} />
          </div>
        </div>
        <div className="my-6 w-[80%] border border-b-black font-bold"></div>
      </div>
      <div className="h-[30vh]">
        <div className="flex gap-x-2 h-[25vh]smallTablet:h-[12vh] smallTablet:flex-col smallTablet:items-center">
          <div className="w-fit ">
            <svg
              style={{ color: "red" }}
              aria-hidden="true"
              id="icon-zoom"
              className=" text-[50px] h-14 w-14"
              viewBox="0 0 576 512"
              xmlns="http://www.w3.org/2000/svg"
              fill="#AB0E13"
            >
              <path d="M571.31 193.94l-22.63-22.63c-6.25-6.25-16.38-6.25-22.63 0l-11.31 11.31-28.9-28.9c5.63-21.31.36-44.9-16.35-61.61l-45.25-45.25c-62.48-62.48-163.79-62.48-226.28 0l90.51 45.25v18.75c0 16.97 6.74 33.25 18.75 45.25l49.14 49.14c16.71 16.71 40.3 21.98 61.61 16.35l28.9 28.9-11.31 11.31c-6.25 6.25-6.25 16.38 0 22.63l22.63 22.63c6.25 6.25 16.38 6.25 22.63 0l90.51-90.51c6.23-6.24 6.23-16.37-.02-22.62zm-286.72-15.2c-3.7-3.7-6.84-7.79-9.85-11.95L19.64 404.96c-25.57 23.88-26.26 64.19-1.53 88.93s65.05 24.05 88.93-1.53l238.13-255.07c-3.96-2.91-7.9-5.87-11.44-9.41l-49.14-49.14z"></path>
            </svg>
          </div>
          <div className="flex flex-col gap-y-2 h-[25vh] smallTablet:items-center smallTablet:h-[20vh]">
            <span className="font-roboto text-[24px] font-semibold">
              Installation & Maintenance{" "}
            </span>
            <span
              className="text-sm smallTablet:text-center "
              style={{ fontFamily: "Montserrat',Sans-serif" }}
            >
              Technogenious Solutions Limited reserves the exclusive right to
              install boxes leased to customers, however...{" "}
            </span>
            <button
              onClick={() => scrollToSection("Installation & Maintenance")}
              className="w-fit my-2 flex items-center gap-x-2 px-4 text-[13px] border-2 border-black py-2 rounded-md"
            >
              <Fa.FaArrowRight className="h-3 w-3" color="black" />
              Learn more
            </button>
          </div>
        </div>
        <div className="my-6  w-[80%] border border-b-black font-bold smallTablet:mx-auto "></div>
      </div>
    </div>
  );
};

export default LogoAndContent;
