import React, { useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { MdSearch } from "react-icons/md";
import { relatedProducts } from "../constants";
import useFetch from "../Network/useFetch";
import Loader from "./Loader";

export const GameProductDetail = () => {
  // const location = useLocation();
  const { gamingId } = useParams();
  // const { product } = location.state;
  const [qty, setQty] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const navigate = useNavigate();
  const {
    data: gamingDetail,
    isLoading,
    isError,
    error,
    refetch,
  } = useFetch(
    `${process.env.REACT_APP_SERVER_URL}/product/gaming-machine/${gamingId}`,
    ["GAMING", gamingId],
    {
      enabled: true,
    }
  );

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const handleImageClick = (imageSrc) => {
    setSelectedImage(imageSrc);
  };

  const handleMouseMove = (e) => {
    const { left, top, width, height } =
      e.currentTarget.getBoundingClientRect();
    const x = ((e.clientX - left) / width) * 100;
    const y = ((e.clientY - top) / height) * 100;
    setMousePosition({ x, y });
  };

  if (isLoading)
    return (
      <div className="flex h-[80vh] items-center justify-center">
        <Loader forProduct />
      </div>
    );
  if (isError) return <div className="h-[50vh]">Error: {error.message}</div>;

  const item = gamingDetail?.data?.game;

  return (
    <div>
      {item && (
        <div>
          <div className="flex justify-center mt-10 tablet:px-[50px] smallTablet:!px-[20px]">
            <div className="w-[1240px] flex justify-between desktop:px-5 smallTablet:flex-col">
              <div className="w-[432px] flex flex-col gap-[20px] smallTablet:w-auto">
                <h1 className="text-[#1C244B] text-[31px] font-semibold font-['poppins',Sans-serif] leading-[1em] tracking-normal smallTablet:w-auto smallTablet:text-[40px] mobile:!text-[25px] mb-[15px]  tablet:w-full ">
                  {item.name}
                </h1>

                <div className="text-[#324A6D]">
                  <p className="mb-[30px] uppercase">
                    <strong className="text-[red]">
                      Contact US for Pricing
                    </strong>
                  </p>

                  <p className="mb-[30px] ">{item.description}</p>

                  {item.specifications && item.specifications.length > 0 && (
                    <>
                      <p>Specification:</p>
                      <ul className="list-disc ml-[35px] mb-[15px]">
                        {item.specifications.map((spec, index) => (
                          <li key={index}>{spec}</li>
                        ))}
                      </ul>
                    </>
                  )}

                  {item.features && item.features.length > 0 && (
                    <ol className=" ml-[35px] mb-5">
                      {item.features.map((option, idx) => (
                        <li className="text-[14px]">
                          {" "}
                          {idx + 1}. {option}
                        </li>
                      ))}
                    </ol>
                  )}

                  {item.bottomDescription.description ? (
                    <p className="mb-[1.75em] font-['poppins',Sans-serif] text-[14px]">
                      {item.bottomDescription.description}
                    </p>
                  ) : null}

                  {item.bottomDescription.text.map((descItem, index) => (
                    <div key={index}>
                      <p className="text-[25px] mb-5 font-[300]">
                        ------------------------
                      </p>
                      <p className="mb-5 text-[14px]">{descItem.heading}</p>

                      <ol className=" ml-[35px] mb-5">
                        {descItem.options.map((option, idx) => (
                          <li className="text-[14px]">
                            {" "}
                            {idx + 1}. {option}
                          </li>
                        ))}
                      </ol>
                    </div>
                  ))}
                </div>

                {/* <p>Features:</p>
                <ol className="list-decimal ml-[35px] mb-5">
                  <li>
                    Double zero (00) american style roulette game with Linking
                    Mystery Jackpot.
                  </li>
                  <li>
                    Server-Client based linking system for multiplayer, max link
                    up to 16 roulette machines.
                  </li>
                  <li>
                    Player may use touch panel and/or press buttons to play.
                  </li>
                </ol> */}

                <p className="font-bold text-[#1c244b] text-[36px]  my-2 pr-5">
                  {/* {product.price} */}
                </p>

                {/* <div className="flex gap-[40px] h-[55px]">
                  <div className="flex mx-2 h-[40px]">
                    <button
                      onClick={() => setQty(qty - 1)}
                      className="md:p-2 border md:px-4 text-xs md:text-sm px-2"
                    >
                      -
                    </button>
                    <button className="md:p-2 border md:px-4 text-xs md:text-sm px-2">
                      {qty}
                    </button>
                    <button
                      onClick={() => setQty(qty + 1)}
                      className="md:p-2 border md:px-4 text-xs md:text-sm px-2"
                    >
                      +
                    </button>
                  </div>

                  <div>
                    <button className="capitalize rounded-full px-5 text-base font-bold text-center py-2 bg-[#0170b9] text-white hover:bg-white hover:text-[#0170b9] hover:border hover:border-blue-700">
                      add to cart
                    </button>
                  </div>
                </div> */}
              </div>

              <div className="w-[50%] smallTablet:w-auto">
                <div className="flex float-end cursor-pointer">
                  <MdSearch className="w-[25px] h-[25px]" />
                </div>
                <div
                  className="image-container h-[50%] tablet:h-[35%]"
                  onClick={toggleModal}
                >
                  <img
                    src={selectedImage == "" ? item?.mainImage : selectedImage}
                    alt="brand-img"
                    className="w-full h-[95%] image-zoom object-contain"
                    onMouseMove={handleMouseMove}
                    style={{
                      transformOrigin: `${mousePosition.x}% ${mousePosition.y}%`,
                    }}
                  />
                </div>
                {item.image && item.image.length > 0 && (
                  <ol className="flex cursor-pointer">
                    {item.image.map((img, idx) => (
                      <li key={idx}>
                        <img
                          src={img.smallImage}
                          alt="product-img"
                          className="h-[148px] w-[148px] object-contain"
                          draggable="false"
                          width="100"
                          // height="100"
                          onClick={() => handleImageClick(img.largeImage)}
                        />
                      </li>
                    ))}
                  </ol>
                )}
              </div>
            </div>
          </div>

          {showModal && (
            <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-80 z-[100] flex justify-center items-center">
              <div className="bg-white p-5 rounded-lg">
                <button
                  onClick={toggleModal}
                  className="absolute top-3 right-3 text-white"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
                <img
                  src={selectedImage == "" ? item?.mainImage : selectedImage}
                  alt="brand-img"
                  className="max-w-[400px] max-h-[400px]"
                />
              </div>
            </div>
          )}
          <div>
            <div>
              <h2 className="mb-[30px] text-[#1C244b] text-center text-[42px] font-[600]  font-['poppins',Sans-serif] leading-[1.2em] ">
                Related products
              </h2>
            </div>
            <div className="flex items-center justify-center py-[20px] mb-5 flex-wrap tablet:px-[50px]">
              <ul className="flex items-center justify-around w-[90%] flex-wrap tablet:w-[100%]  smallTablet:flex-col">
                {Array.isArray(gamingDetail.data?.relatedGame) &&
                  gamingDetail.data.relatedGame.length > 0 &&
                  gamingDetail.data?.relatedGame?.map((item, index) => (
                    <li key={index}>
                      <div className="w-[230px] h-fit flex items-center justify-center flex-col smallTablet:w-[100%] tablet:mt-[30px] ">
                        <Link
                          to={`/game-product-detail/${encodeURIComponent(
                            item?._id
                          )}`}
                          className="mb-[15px]"
                        >
                          <figure className=" h-52 bg-white mb-2 smallTablet:h-auto">
                            <img
                              className="w-full h-full object-contain  smallTablet:h-[50%] "
                              src={item.mainImage}
                            />
                          </figure>
                        </Link>

                        <h2 className="text-[#1C244B] text-center text-[15px] font-bold leading-[1.2em] mb-[7px] smallTablet:w-[70%]">
                          {item.name}
                        </h2>

                        <Link
                          to={`/game-product-detail/${encodeURIComponent(
                            item?._id
                          )}`}
                          className="capitalize w-fit font-quando rounded-full px-5 text-base font-medium py-2 bg-[#e11111] text-white duration-300 transition-all smallTablet:mt-2"
                        >
                          Read more
                        </Link>
                      </div>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
