import React from "react";
import CardImg from "../assets/cards.webp";
import { useNavigate } from "react-router-dom";

const SellingProduct = () => {
  const navigate = useNavigate();
  return (
    <div className="max-w-[1140px] mx-auto flex flex-col tablet:hidden">
      {/* <h1 className="text-[#6D6D6D] px-3 w-full font-roboto font-semibold text-[50px]">
        Top Selling Products
      </h1> */}
      <div className="w-full mb-5 mt-7 flex justify-center">
        <img src={CardImg} alt="CardImg-img" className="w-1/5 h-auto face" />
      </div>
      <div className="flex justify-center">
        <button
          onClick={() => navigate("/products")}
          className="border-2 rounded-lg py-[6px] px-6 zoom border-[#0270b9]"
        >
          View All Products
        </button>
      </div>
    </div>
  );
};

export default SellingProduct;
