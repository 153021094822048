// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Payment.css */

  .form {
    width: 400px;
    padding: 40px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    display: flex;
    margin:auto;
    flex-direction: column;
  }
  
  .field {
    margin-bottom: 20px;
  }
  
  .field label {
    display: block;
    margin-bottom: 10px;
    font-weight: bold;
  }
  
  #card-element,
  .field div {
    border: 1px solid #ccc;





























    
    padding: 10px;
    border-radius: 4px;
    background-color: #f9f9f9;
    margin-bottom: 20px;
  }
  
  #card-errors {
    color: #fa755a;
    margin-bottom: 20px;
  }
  
  .button {
    width: 100%;
    padding: 15px;
    background-color: #32325d;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    text-align: center;
  }
  
  .button:disabled {
    background-color: #ccc;
  }
  
  .button:hover:not(:disabled) {
    background-color: #555;
  }
  `, "",{"version":3,"sources":["webpack://./src/Components/Payment.css"],"names":[],"mappings":"AAAA,gBAAgB;;EAEd;IACE,YAAY;IACZ,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,uCAAuC;IACvC,aAAa;IACb,WAAW;IACX,sBAAsB;EACxB;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,cAAc;IACd,mBAAmB;IACnB,iBAAiB;EACnB;;EAEA;;IAEE,sBAAsB;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;IA+BtB,aAAa;IACb,kBAAkB;IAClB,yBAAyB;IACzB,mBAAmB;EACrB;;EAEA;IACE,cAAc;IACd,mBAAmB;EACrB;;EAEA;IACE,WAAW;IACX,aAAa;IACb,yBAAyB;IACzB,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,eAAe;IACf,kBAAkB;EACpB;;EAEA;IACE,sBAAsB;EACxB;;EAEA;IACE,sBAAsB;EACxB","sourcesContent":["/* Payment.css */\n\n  .form {\n    width: 400px;\n    padding: 40px;\n    background-color: #fff;\n    border-radius: 8px;\n    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);\n    display: flex;\n    margin:auto;\n    flex-direction: column;\n  }\n  \n  .field {\n    margin-bottom: 20px;\n  }\n  \n  .field label {\n    display: block;\n    margin-bottom: 10px;\n    font-weight: bold;\n  }\n  \n  #card-element,\n  .field div {\n    border: 1px solid #ccc;\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n    \n    padding: 10px;\n    border-radius: 4px;\n    background-color: #f9f9f9;\n    margin-bottom: 20px;\n  }\n  \n  #card-errors {\n    color: #fa755a;\n    margin-bottom: 20px;\n  }\n  \n  .button {\n    width: 100%;\n    padding: 15px;\n    background-color: #32325d;\n    color: #fff;\n    border: none;\n    border-radius: 4px;\n    cursor: pointer;\n    font-size: 16px;\n    text-align: center;\n  }\n  \n  .button:disabled {\n    background-color: #ccc;\n  }\n  \n  .button:hover:not(:disabled) {\n    background-color: #555;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
