import React from "react";
import { FooterSection } from "../Components/index";

const Footer = () => {
  return (
      <FooterSection />
  );
};

export default Footer;
