import React, { useEffect, useState } from "react";
import CircleBrand from "../assets/circlegame.jpeg";
import PyramidBrand from "../assets/pyramid.jpeg";
import GameBrand from "../assets/game.jpeg";

const TopBrands = () => {
  const brands = [CircleBrand, PyramidBrand, GameBrand];
  const [currentBrandIndex, setCurrentBrandIndex] = useState(0);
  const [fade, setFade] = useState(true);
  // useEffect(() => {
  //   const timer = setInterval(() => {
  //     setFade(false);
  //     setTimeout(() => {
  //       setCurrentBrandIndex((prevIndex) => (prevIndex + 1) % brands.length);
  //       setFade(true);
  //     }, 500);
  //   }, 5000);

  //   return () => clearInterval(timer);
  // }, []);
  const [itemsToShow, setItemsToShow] = useState(5);

  useEffect(() => {
    const handleResize = () => {
      const smallScreenBreakpoint = 768;
      if (window.innerWidth <= smallScreenBreakpoint) {
        setItemsToShow(3);
      } else {
        setItemsToShow(5);
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <>
      <h1 className="text-[55px] text-center mt-20 flex mx-auto justify-center font-semibold font-roboto leading-[1.3em] tablet:text-[38px]">
        OUR TOP BRANDS!
      </h1>{" "}
      <div
        style={{ border: "2px solid black" }}
        className=" my-4 w-1/3 h-auto flex justify-center mx-auto  bg-white"
      ></div>
      <div className="flex mt-12 gap-x-10 justify-center">
        {/* {Array.from({ length: itemsToShow }).map((_, index) => ( */}
        <div
          // key={index}
          className={`w-2/3 grid grid-cols-3 smallTablet:grid-cols-2 mobile:!grid-cols-1 mobile:justify-center mobile:items-center mobile:mx-8 mobile:w-full  gap-x-6 my-5 brand-image ${
            fade ? "fade-in" : "fade-out"
          }`}
        >
          <img
            className="h-[23vh] w-fit mx-auto"
            src={PyramidBrand}
            alt={`Brand ${currentBrandIndex + 1}`}
          />
          <img
            className="h-[23vh] w-fit mx-auto"
            src={GameBrand}
            alt={`Brand ${currentBrandIndex + 1}`}
          />
          <img
            className="h-[23vh] w-fit mx-auto"
            src={CircleBrand}
            alt={`Brand ${currentBrandIndex + 1}`}
          />
        </div>
        {/* ))} */}
      </div>
    </>
  );
};
export default TopBrands;
