import React, { useEffect, useState } from "react";
import { useParams, Link, useNavigate, useLocation } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import Loader from "./Loader";
import { useRecoilState, useSetRecoilState } from "recoil";
import {
  cartItems,
  FilterCheckBox,
  FilterInfo,
  searchValue,
} from "./atoms/atoms";
import Pagination from "./Pagination";
import useFetch from "../Network/useFetch";
import SideFilter from "./SideFilter";

const SearchGlobal = () => {
  const { searchTerm } = useParams();
  const searchPage = true;
  const [currentPage, setCurrentPage] = useState(1);
  const [sortOption, setSortOption] = useState("");
  const [filterValue, setFilterValue] = useRecoilState(FilterInfo);
  const [pageSize] = useState(8);
  const navigate = useNavigate();
  const location = useLocation();
  const setFilterState = useSetRecoilState(searchValue);
  const encodedFilter = encodeURIComponent(filterValue?.filters);
  const setFilterChecks = useSetRecoilState(FilterCheckBox);
  setFilterState(encodedFilter?.replace(/%20|%26/g, " "));
  // const [cart, setCart] = useRecoilState(cartItems);
  let fetchUrl =
    filterValue?.searchPage === true && filterValue.filters
      ? `${process.env.REACT_APP_SERVER_URL}/product/search?search=${encodedFilter}&page=${currentPage}&pageSize=${pageSize}`
      : `${process.env.REACT_APP_SERVER_URL}/product/search?search=${searchTerm}&page=${currentPage}&pageSize=${pageSize}`;
  const handleClick = (id: string) => {
    // Navigate to the detailed page with state
    navigate(`/product/${encodeURIComponent(id)}`);
  };

  const handlePageChange = (page: any) => {
    setCurrentPage(page);
  };
  const handleSortChange = (event) => {
    setSortOption(event.target.value);
  };

  if (sortOption?.length) {
    fetchUrl = `${process.env.REACT_APP_SERVER_URL}/product/search?search=${searchTerm}&sort=${sortOption}&page=${currentPage}&pageSize=${pageSize}`;
  }
  // if (filterValue?.filters) {
  //   const encodedFilter = encodeURIComponent(filterValue?.filters);
  //   fetchUrl = fetchUrl.concat(`&filter=${encodedFilter}`);
  // }
  if (filterValue?.ranges) {
    fetchUrl = fetchUrl.concat(
      `&lowPrice=${filterValue?.ranges?.lowestPrice}&highPrice=${filterValue?.ranges?.highestPrice}`
    );
  }

  const fetchSearchResults = async (searchTerm) => {
    const response = await fetch(fetchUrl);

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    return response.json();
  };
  useEffect(() => {
    setFilterState(null);
    setFilterValue(null);
    setFilterChecks(null);
  }, [location.key]);
  // const addToCart = async (items) => {
  //   const existingItem = cart?.find((item) => item?.id === items?._id);
  //   console.log("item", items, existingItem);
  //   if (existingItem) {
  //     // If exists, update quantity and price
  //     const updatedCart = cart.map((item) => {
  //       if (item.id === items._id) {
  //         return {
  //           ...item,
  //           quantity: item.quantity + 1,
  //           price: item.price,
  //           subtotal: (item.quantity + 1) * items.price,
  //           Image: items.image,
  //           name: items.name,

  //           // Adjust price calculation as needed
  //         };
  //       }
  //       return item;
  //     });

  //     setCart(updatedCart);
  //   } else {
  //     setCart([
  //       ...cart,
  //       {
  //         id: items._id,
  //         _id: items._id,
  //         quantity: 1,
  //         price: items?.price,
  //         subtotal: items?.quantity * items?.price,
  //         Image: items.image,
  //         name: items.name,
  //       },
  //     ]);
  //   }
  // };

  // const { mutate, reset } = useMutation(addToCart, {
  //   onSuccess: (data) => {
  //     // setToast({ type: "Sucess", message: "Add to cart sucessfully" });
  //     // queryClient.invalidateQueries("fetch-cart");
  //   },
  //   onError: (errors: any) => {
  //     const error = errors as any;
  //     // setToast({
  //     //   type: "Error",
  //     //   message: error?.response?.data?.message || "Something went wrong",
  //     // });
  //   },
  // });

  const filterUrl: any = `${process.env.REACT_APP_SERVER_URL}/category/filter`;
  const fetchFilterKey: any = `fetch-filter-products`;
  const {
    isLoading: filterLoading,
    data: filterData,
    isError: isFilterError,
    error: filterError,
  } = useFetch(filterUrl, fetchFilterKey, {
    enabled: true,
  });
  const { isLoading, data, isError, error } = useQuery(
    ["searchResults", searchTerm, sortOption, currentPage, filterValue],
    () => fetchSearchResults(searchTerm),
    {
      enabled: !!searchTerm,
    }
  );

  if (isLoading)
    return (
      <div className="flex h-[80vh] items-center justify-center">
        <Loader forProduct />
      </div>
    );
  if (isError) return <div className="h-[50vh]">Error:</div>;

  return (
    <div className="max-w-[1200px] mx-auto mb-5">
      <div className="flex items-center justify-between px-4 gap-4 flex-wrap w-full py-8 largeTablet:gap-2  mobile:flex-col mobile:gap-y-3 mobile:!w-full">
        <h1 className="text-[#666666] font-light text-sm">
          {data?.data?.totalRecords !== 0
            ? `Showing ${(currentPage - 1) * pageSize + 1}–${Math.min(
                currentPage * pageSize,
                data.data?.totalRecords
              )} of ${data?.data?.totalRecords} results`
            : `Showing 0-0 of 0 results`}
        </h1>
        <select
          className="text-[#666666] font-light text-sm w-40 px-3 border-dotted py-2 border-black focus:border-2 focus:outline-none"
          value={sortOption}
          onChange={handleSortChange}
        >
          <option value="menu_order" selected>
            Default sorting
          </option>
          {/* <option value="popularity">Sort by popularity</option>
              <option value="rating">Sort by average rating</option> */}
          <option value="latest">Sort by latest</option>
          <option value="low">Sort by price: low to high</option>
          <option value="high">Sort by price: high to low</option>
        </select>
      </div>

      <div className="flex smallTablet:flex-col">
        <div className={` largeTablet:px-6`}>
          {!data?.isSlider ? (
            <SideFilter
              isLoading={filterLoading}
              data={filterData}
              searchPage={searchPage}
            />
          ) : null}
        </div>
        <div className="flex items-center w-full justify-center my-8 mx-auto flex-wrap">
          {Array.isArray(data?.data?.results) &&
          data?.data?.results.length === 0 ? (
            <div className="flex flex-col h-[80vh] items-center space-y-4">
              <p>No products found for your search.</p>
              <Link
                to="/"
                className="flex items-center space-x-2 bg-red-600 hover:bg-red-700 text-gray-100 px-4 py-2 rounded transition duration-150"
                title="Return Home"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M9.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L7.414 9H15a1 1 0 110 2H7.414l2.293 2.293a1 1 0 010 1.414z"
                    clipRule="evenodd"
                  />
                </svg>
                <span>Return Home</span>
              </Link>
            </div>
          ) : (
            <div className="products-container  w-full gap-4 grid-cols-3 smallTablet:grid-cols-2 mobile:!grid-cols-1 mobile:w-1/2 mobile:items-center mobile:justify-center ">
              {Array.isArray(data?.data?.results) &&
                data?.data?.results.length > 0 &&
                data?.data?.results.map((item, index) => (
                  <div
                    key={index}
                    className="w-3/4 mb-3 text-center mx-auto h-fit flex justify-center items-center gap-3 flex-col mobile:w-full"
                  >
                    <figure
                      onClick={() => handleClick(item?._id)}
                      className="w-44 h-52 bg-white mb-2 mobile:w-full cursor-pointer mobile:h-[40vh] "
                    >
                      <img
                        className="w-full h-full object-contain"
                        src={item.image}
                        alt="Product"
                      />
                    </figure>
                    <p className="product-name text-center mx-auto flex justify-center">
                      {item.name}
                    </p>
                    <p className="product-price !font-bold text-base text-center">
                      ${item.price}
                    </p>

                    {/* {item.option?.length <= 0 ? (
                    <button
                      onClick={() => addToCart(item)}
                      className="product-button !text-lg"
                    >
                      Add to Cart
                    </button>
                  ) : (
                    <button
                      onClick={() => handleClick(item?._id)}
                      className="product-button !text-lg"
                    >
                      Select option{" "}
                    </button>
                  )} */}
                    <button
                      onClick={() => handleClick(item?._id)}
                      className="product-button !text-lg"
                    >
                      View Product{" "}
                    </button>
                  </div>
                ))}
            </div>
          )}
        </div>
      </div>
      <div className="w-full flex justify-center items-center py-8">
        {data.data?.totalRecords > pageSize ? (
          <Pagination
            currentPage={currentPage}
            totalPages={Math.ceil(data.data?.totalRecords / pageSize)}
            onPageChange={handlePageChange}
          />
        ) : null}
      </div>
    </div>
  );
};

export default SearchGlobal;
