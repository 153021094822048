import React, { useEffect, useState } from "react";
import { HeroSection } from "../Components/hero-section/HeroSection";
import { WhoWeAre } from "../Components/who-we-are/WhoWeAre";
import SellingProduct from "../Components/SellingProduct";
import TopBrands from "../Components/TopBrands";
import FixedPicture from "../Components/FixedPicture";
import slotss from "../assets/slotss.jpg";
import { RetailerComission } from "../Components/index";
import jackpot from "../assets/jackpot.jpg";

import FeatureProducts from "../Components/FeatureProducts";
import useFetch from "../Network/useFetch";
import axios from "axios";
import { useRecoilState, useRecoilValue } from "recoil";
import { UserInfo, cartItems } from "../Components/atoms/atoms";
const Home = () => {
  const userValue = useRecoilValue(UserInfo);
  useEffect(() => {
    window.scrollTo({
      top: -100,
      behavior: "auto",
    });
  }, []);
  return (
    <>
      <HeroSection />
      <WhoWeAre />

      <SellingProduct></SellingProduct>
      <div className="mt-[6rem]">
        <FixedPicture picture={jackpot} />
      </div>
      <RetailerComission />
      <FixedPicture picture={slotss} />

      <FeatureProducts />
      <TopBrands />
    </>
  );
};

export default Home;
