import React, { useState } from "react";
import MultiRangeSlider from "multi-range-slider-react";

interface InputEvent {
  minValue: number;
  maxValue: number;
}

const RangeSlider = (values: any) => {
  const [minValue, setMinValue] = useState<number>(values?.values?.lowestPrice);
  const [maxValue, setMaxValue] = useState<number>(
    values?.values?.highestPrice
  );

  const handleInput = (e: InputEvent) => {
    setMinValue(e.minValue);
    setMaxValue(e.maxValue);
  };

  return (
    <div>
      <MultiRangeSlider
        className="shadow-none border-0"
        barInnerColor="lightGrey"
        barLeftColor="white"
        barRightColor="white"
        ruler={false}
        min={values?.values?.lowestPrice}
        max={values?.values?.highestPrice}
        step={5}
        minValue={minValue}
        maxValue={maxValue}
        onInput={(e) => {
          handleInput(e);
        }}
      />
      <div className="text-center text-base font-light">
        USD$ {minValue} - {maxValue}
      </div>
    </div>
  );
};

export default RangeSlider;
