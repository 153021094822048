import React from "react";

type props = {
  title: string;
  backgroundImage?: string;
  height: number;
  fontSize?: string;
  boxHeight?: string;
};
const AboutBanner = ({
  title,
  backgroundImage,
  height,
  fontSize,
  boxHeight,
}: props) => {
  return (
    <div
      className="w-full relative flex flex-col justify-center items-center"
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        height: `${height}px`,
      }}
    >
      <div
        style={{
          boxShadow: "0 0 10px 0 rgba(0,0,0,.5)",
          backgroundColor: "rgb(0 0 0 / 54%)",
          height: boxHeight,
        }}
        className="w-full bg-black bg-opacity-80 py-4 flex justify-center items-center"
      >
        <h2
          style={{
            color: "#FFF",
            fontWeight: "600",
            textShadow: "0 0 10px rgba(0,0,0,.3)",
            fontSize: fontSize,
          }}
          className="text-center font-roboto smallTablet:!text-6xl mobile:!text-5xl"
        >
          {title}
        </h2>
      </div>
    </div>
  );
};

export default AboutBanner;
