import React, { useEffect } from "react";
import ContentSection from "../Components/privacy-policy/ContentSection";

const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo({
      top: -100,
      behavior: "auto",
    });
  }, []);
  return <ContentSection />;
};

export default PrivacyPolicy;
