import { Outlet } from "react-router-dom";
import { Header, Footer } from "../Pages/index";
import { useLocation } from "react-router-dom";
const RootLayout = () => {
  const location = useLocation();

  // Correct use of the conditional rendering based on the pathname
  // Also corrected pathname property

  return (
    <>
      <Header />
      <Outlet />
      {location.pathname !== "/register" &&
      location.pathname !== "/login" &&
      location.pathname !== "/payment" ? (
        <Footer />
      ) : null}
    </>
  );
};

export default RootLayout;
