import game from "./Components/game.png";

type RangeRadio = string;

interface ProductItem {
  id: string;
  title: string;
  price: string;
  image: string;
  link: string;
}

export const rangeRadio: RangeRadio[] = [
  "Alarm Systems",
  "BILL ACCEPTORS & ACCESSORIES",
  "Bonding Adhesive",
  "BUTTONS & SWITCHES",
  "CABLES & WIRES",
  "CAM LOCKS",
  "Camera",
  "CGA - VGA CONVERTERS",
  "CLEANING & MAINTENANCE",
  "COMPUTER ACCESSORIES",
  "COMPUTER ACCESSORIES",
  "COUNTERS",
  "EDGE CONNNECTORS",
  "ELECTRONIC COMPONENTS",
  "Ethernet Hub",
  "FAN",
  "FLASH DRIVE",
  "GAME BOARDS",
  "GAMING COMPONENTS",
  "Hard Drive",
  "Keyboard",
  "LED LIGHTINGS",
  "LED RING LIGHT",
  "MACHINES",
  "Memory Card",
  "POWER SUPPLIES",
  "PRINTERS & ACCESSORIES",
  "SPEAKERS",
  "TOOLS",
  "TOUCHSCREENS & LCD MONITORS",
  "USB Hub",
  "WIFI ROUTER",
  "WIRELESS HEADSETS",
  "Wireless Mouse",
  "Wireless Presenter",
];
export const product = [
  {
    heading:
      "American Roulette Machine In Metal Cabinet- (Contact us for pricing).",
    image:
      "https://www.technogeniousja.com/wp-content/uploads/2024/02/Artboard-11-300x300.png",
    priceRange: [],
  },
  { heading: "Cable Adapter HDMI to VGA", image: game, priceRange: [2.5] },
  { heading: "Task Wood Bit", image: game, priceRange: [2.5, 5.7] },
  {
    heading:
      "American Roulette Machine In Metal Cabinet- (Contact us for pricing).",
    image:
      "https://www.technogeniousja.com/wp-content/uploads/2022/02/1007643_2_1.jpg",
    priceRange: [],
  },
  { heading: "Cable Adapter HDMI to VGA", image: game, priceRange: [2.5] },
  {
    heading: "Task Wood Bit",
    image:
      "https://www.technogeniousja.com/wp-content/uploads/2024/02/Artboard-14-300x300.png",
    priceRange: [2.5, 5.7],
  },
  {
    heading:
      "American Roulette Machine In Metal Cabinet- (Contact us for pricing).",
    image:
      "https://www.technogeniousja.com/wp-content/uploads/2024/03/Pearl-of-the-Caribbeanresize-300x300.png",
    priceRange: [],
  },
  { heading: "Cable Adapter HDMI to VGA", image: game, priceRange: [2.5] },
  {
    heading: "Task Wood Bit",
    image:
      "https://www.technogeniousja.com/wp-content/uploads/2022/02/lhss800_3.jpg",
    priceRange: [2.5, 5.7],
  },
  {
    heading:
      "American Roulette Machine In Metal Cabinet- (Contact us for pricing).",
    image: game,
    priceRange: [],
  },
  { heading: "Cable Adapter HDMI to VGA", image: game, priceRange: [2.5] },
  { heading: "Task Wood Bit", image: game, priceRange: [2.5, 5.7] },
];

export const touchscreens = [
  {
    title: "19″ Touch Monitor",
    price: "USD$365.00",
    image:
      "https://www.technogeniousja.com/wp-content/uploads/2023/04/HTB1W0GhaPLuK1Rjy0Fhq6xpdFXaP-600x600-1-768x768.jpg",

    link: "/product/10-inch-wrench/",
  },
  {
    title: "19″ Touch Monitor W/ Led Light",
    price: "USD$425.00",
    image:
      "	https://www.technogeniousja.com/wp-content/uploads/2024/02/Artboard-14-600x600.png",
    link: "/product/1n4007-diode/",
  },
  {
    title: "Dell LCD Monitors",
    price: "USD$70.00",
    image:
      "https://www.technogeniousja.com/wp-content/uploads/2023/04/1908FPC-500x500-1-300x300-1-600x600.jpg",
    link: "/product/3-ton-epoxy/",
  },
];

export const billAcceptors = [
  {
    title: "Bill Acceptor In Cabinet.",
    price: "USD$520.00",
    image:
      "https://www.technogeniousja.com/wp-content/uploads/2023/04/Bill-Acceptor-installed-in-Wooden-Cabinet-1-300x300-1-600x600.png",

    link: "/product/10-inch-wrench/",
  },
  {
    title: "BluStream Dongle",
    price: "USD$80.00",
    image:
      "	https://www.technogeniousja.com/wp-content/uploads/2023/04/Bluestream__2-300x200-1-600x600.jpg",
    link: "/product/1n4007-diode/",
  },
  {
    title: "1000 Notes Metal Cashbox",
    price: "USD$54.00 – USD$80.00",
    image:
      "https://www.technogeniousja.com/wp-content/uploads/2021/06/thumb.png",
    link: "/product/3-ton-epoxy/",
  },
  {
    title: "Pyramid 7600-U13",
    price: "USD$386.67.00",
    image:
      "https://www.technogeniousja.com/wp-content/uploads/2023/04/apex_7400-300x300-1-600x600.jpg",

    link: "/product/10-inch-wrench/",
  },
  {
    title: "Tops Bill Acceptor",
    price: "USD$12.00",
    image:
      "	https://www.technogeniousja.com/wp-content/uploads/2024/02/Artboard-11-600x600.png",
    link: "/product/1n4007-diode/",
  },
  {
    title: "Pyramid Universal Mounting Bracket",
    price: "USD$54.00 – USD$80.00",
    image:
      "https://www.technogeniousja.com/wp-content/uploads/2023/04/95po2-1-e1681060327373-600x600.jpg",
    link: "/product/3-ton-epoxy/",
  },
  {
    title: "Pyramid Wall Plug Harness",
    price: "USD$12.00",
    image:
      "https://www.technogeniousja.com/wp-content/uploads/2023/04/pyr__05aa0012_5-1-150x150-1.jpg",

    link: "/product/10-inch-wrench/",
  },
  {
    title: "Wire",
    price: "USD$816.70",
    image:
      "	https://www.technogeniousja.com/wp-content/uploads/2021/10/IO-HARNESS.jpg",
    link: "/product/1n4007-diode/",
  },
];

export const electronicComponents = [
  {
    title: "1N4007 Diode",
    price: "USD$0.13",
    image:
      "https://www.technogeniousja.com/wp-content/uploads/2022/01/704466_1.jpg",

    link: "/product/10-inch-wrench/",
  },
  {
    title: "GP Super Alkaline Battery",
    price: "USD$1.00 – USD$2.50",
    image:
      "https://www.technogeniousja.com/wp-content/uploads/2022/01/GP_23A_Battery.jpg",
    link: "/product/1n4007-diode/",
  },
  {
    title: "Maxell 1.55V SR62ISW Battery",
    price: "USD$1.00",
    image:
      "https://www.technogeniousja.com/wp-content/uploads/2022/01/sr621sw_front-300x300.jpg",
    link: "/product/3-ton-epoxy/",
  },
  {
    title: "Maxell 3V Lithium Coin Battery",
    price: "USD$1.30",
    image:
      "https://www.technogeniousja.com/wp-content/uploads/2022/01/images-150x150.jpg",

    link: "/product/10-inch-wrench/",
  },
  {
    title: "11 Pin 12V Relay w/Base Socket",
    price: "USD$16.67",
    image:
      "https://www.technogeniousja.com/wp-content/uploads/2023/04/11pin-12V-Relay-W-Base-Socket-300x300-1-600x600.jpg",
    link: "/product/1n4007-diode/",
  },
  {
    title: "12V 7AH Battery",
    price: "USD$26.67",
    image:
      "https://www.technogeniousja.com/wp-content/uploads/2023/04/Battery-12V-7AH-Sealed-Lead-Acid-Twins-Chip-1-550x550-1-600x600.jpg",
    link: "/product/3-ton-epoxy/",
  },
  {
    title: "3M Electrical Tape",
    price: "USD$1.33",
    image:
      "https://www.technogeniousja.com/wp-content/uploads/2023/04/Templex-Electrical-Tape-300x300-1-600x600.jpg",

    link: "/product/10-inch-wrench/",
  },
  {
    title: "3M Electrical Tape",
    price: "USD$2.67",
    image:
      "https://www.technogeniousja.com/wp-content/uploads/2023/04/cable-tie-300x300-1-600x600.jpg",
    link: "/product/1n4007-diode/",
  },
];

export const gamingBoard = [
  {
    title: "88 Fortune",
    image:
      "https://www.technogeniousja.com/wp-content/uploads…023/04/88-Fortunes-Screens3-300x300-1-450x450.png",
    price: "867.00",
  },
  {
    title: "American Roulette Linking VER",
    image:
      "https://www.technogeniousja.com/wp-content/uploads/2023/04/brand-1-150x150-1-300x300.jpg",
    price: "732.00",
  },
  {
    title: "Pearl of the Caribbean Linking VER",
    Image:
      "	https://www.technogeniousja.com/wp-content/uploads…sApp-Image-2024-03-28-at-11.05.03-AM-300x300.jpeg",
    price: "790.00",
  },
  {
    title: "American Roulette Single VER",
    Image:
      "https://www.technogeniousja.com/wp-content/uploads/2021/10/ARS-Single.jpg",
    price: "746.00",
  },
  {
    title: "HI/LO Poker",
    image:
      "https://www.technogeniousja.com/wp-content/uploads/2023/04/Hi-Lo-Poker-Board-450x450.png",
    price: "166.67",
  },
];


export const disclaimerSections = [
  {
    title: "General Disclaimer",
    content:
      "The information provided by Technogenious Solutions Ltd (“we,” “us,” or “our”) on our website (the “Website”) is for general informational purposes only. All information on the Website is provided in good faith; however, we make no representation or warranty of any kind, express or implied, regarding the accuracy, adequacy, validity, reliability, availability, or completeness of any information on the Website.",
  },
  {
    title: "External Links Disclaimer",
    content:
      "The Website may contain (or you may be sent through the Website) links to other websites or content belonging to or originating from third parties or links to websites and features in banners or other advertising. Such external links are not investigated, monitored, or checked for accuracy, adequacy, validity, reliability, availability, or completeness by us. We do not warrant, endorse, guarantee, or assume responsibility for the accuracy or reliability of any information offered by third-party websites linked through the Website or any website or feature linked in any banner or other advertising. We will not be a party to or in any way be responsible for monitoring any transaction between you and third-party providers of products or services.",
  },
  {
    title: "Professional Disclaimer",
    content:
      "The Website cannot and does not contain professional advice. The information is provided for general informational and educational purposes only and is not a substitute for professional advice. Accordingly, before taking any actions based upon such information, we encourage you to consult with the appropriate professionals. We do not provide any kind of professional advice. The use or reliance of any information contained on this Website is solely at your own risk.",
  },
  {
    title: "Limitation of Liability",
    content:
      "In no event shall Technogenious Solutions Ltd, nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential, or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from (i) your access to or use of or inability to access or use the Website; (ii) any conduct or content of any third party on the Website; (iii) any content obtained from the Website; and (iv) unauthorized access, use, or alteration of your transmissions or content, whether based on warranty, contract, tort (including negligence), or any other legal theory, whether or not we have been informed of the possibility of such damage.",
  },
  {
    title: '"As Is" and "As Available" Disclaimer',
    content:
      'The Website is provided on an "as is" and "as available" basis. Technogenious Solutions Ltd makes no representations or warranties of any kind, express or implied, as to the operation of their services or the information, content, or materials included therein. You expressly agree that your use of the Website and our services is at your sole risk. Technogenious Solutions Ltd does not warrant that the Website, its servers, or e-mails sent from them are free of viruses or other harmful components.',
  },
  {
    title: "Errors and Omissions Disclaimer",
    content:
      "While we have made every attempt to ensure that the information contained in this Website has been obtained from reliable sources, Technogenious Solutions Ltd is not responsible for any errors or omissions or for the results obtained from the use of this information. All information in this Website is provided 'as is,' with no guarantee of completeness, accuracy, timeliness, or the results obtained from the use of this information, and without warranty of any kind, express or implied, including, but not limited to, warranties of performance, merchantability, and fitness for a particular purpose.",
  },
  {
    title: "Fair Use Disclaimer",
    content:
      'This Website may contain copyrighted material that has not always been specifically authorized by the copyright owner. We believe this constitutes a "fair use" of any such copyrighted material as provided for in section 53 of the Jamaican Copyright Act. If you wish to use copyrighted material from this Website for purposes of your own that go beyond fair use, you must obtain permission from the copyright owner.',
  },
  {
    title: "Views Expressed Disclaimer",
    content:
      "The Website may contain views and opinions which are those of the authors and do not necessarily reflect the official policy or position of any other author, agency, organization, employer, or company, including Technogenious Solutions Ltd. Comments published by users are their sole responsibility, and the users will take full responsibility, liability, and blame for any libel or litigation that results from something written in or as a direct result of something written in a comment. Technogenious Solutions Ltd is not liable for any comment published by users and reserves the right to delete any comment for any reason whatsoever.",
  },
  {
    title: "No Responsibility Disclaimer",
    content:
      "The information on the Website is provided with the understanding that Technogenious Solutions Ltd is not herein engaged in rendering legal, accounting, tax, or other professional advice and services. As such, it should not be used as a substitute for consultation with professional accounting, tax, legal, or other competent advisers.",
  },
  {
    title: "Governing Law",
    content:
      "These terms and any dispute or claim (including non-contractual disputes or claims) arising out of or in connection with them or their subject matter or formation shall be governed by and construed in accordance with the laws of Jamaica.",
  },
  {
    title: "Contact Us",
    content:
      "If you have any feedback, comments, requests for technical support, or other inquiries, please contact us by email: sales.technogeniousja@gmail.com.",
  },
];