import React from "react";
import "swiper/swiper-bundle.css";
import { register } from "swiper/element/bundle";

import { Swiper, SwiperSlide } from "swiper/react";
import ProductCard from "../Pages/ProductCard";
import useFetch from "../Network/useFetch";
import ShopProduct from "./ShopProduct";
register();

const CarouselProducts = () => {
  const { data, isLoading } = useFetch(
    `${process.env.REACT_APP_SERVER_URL}/product/random-product`,
    [],
    {
      enabled: true,
    }
  );
  const products = data?.data;
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1000 100"
        preserveAspectRatio="none"
        className="w-full h-12 transform rotate-180 origin-center text-[#C03C12] fill-current mb-10"
      >
        <path className="elementor-shape-fill" d="M0,6V0h1000v100L0,6z" />
      </svg>

      <div className="max-w-[1140px] mx-auto">
        <div className="w-full mt-[30px] mx-auto">
          <Swiper
            loop={true}
            navigation
            pagination
            autoplay
            breakpoints={{
              640: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 3,
                spaceBetween: 40,
              },
              1024: {
                slidesPerView: 4,
                spaceBetween: 50,
              },
              1200: {
                slidesPerView: 5,
                spaceBetween: 30,
              },
            }}
          >
            {products?.map((item, index) => (
              <SwiperSlide className="" key={index}>
                {/* <ProductCard
                  heading={item.name}
                  image={item?.image}
                  priceRange={item.price}
                /> */}
                <ShopProduct
                  key={item._id}
                  title={item.name}
                  price={item.price}
                  link={item.link}
                  image={item.image}
                  ID={item._id}
                  option={item.option}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default CarouselProducts;
