import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import * as Fa from "react-icons/fa";
import { useRecoilState } from "recoil";
import { cartItems } from "./atoms/atoms";

interface Props {
  menu: any;
  isOpen: boolean;
  setIsOpen: Function;
}

const MobileNav = ({ menu, isOpen, setIsOpen }: Props) => {
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const [showNestMenu, setShowNestMenu] = useState<boolean>(false);
  const location = useLocation();

  const handleLinkClick = () => {
    setIsOpen(false);
  };
  const [cartValue, setCartValue] = useRecoilState(cartItems);

  const calculateTotal = (items) => {
    let totalQuantity = 0;
    let totalPrice = 0;
    cartValue?.forEach((item) => {
      if (item.quantity > 0) {
        totalQuantity += item.quantity;
        totalPrice += item.subtotal;
      }
    });

    return { totalQuantity, totalPrice };
  };
  const isActive = (path: string) => {
    return location.pathname === path ? "text-green-500" : "";
  };
  const { totalQuantity, totalPrice } = calculateTotal(cartValue);

  return (
    <div
      className={`${
        isOpen ? "h-fit py-2 bg-[#f2f2f2]" : "h-0"
      } w-full transition-all duration-300 overflow-hidden`}
    >
      <ul className="flex flex-col gap-3 justify-center items-center">
        {menu.map((item: any, index: any) => (
          <li
            key={index}
            className={`text-black py-1 text-[15px] w-full hover:bg-[#ededed] font-medium hover:text-green-400 duration-100 transition-all flex justify-center text-xl capitalize`}
          >
            <div>
              <div className="flex items-center gap-1 justify-center">
                <Link
                  className={`text-[15px] font-medium ${isActive(item.path)}`}
                  to={item.path}
                  // onClick={handleLinkClick}
                  onClick={(e) => {
                    if (item?.path == '/shop') {
                      e.preventDefault();
                    }else {
                      handleLinkClick()
                    }
                  }}
                >
                  {item.title}
                </Link>
                <button onClick={() => setShowMenu(!showMenu)}>
                  {item.icon && item.icon}
                </button>
              </div>
              {item.childRoutes && (
                <ul
                  className={`text-black flex flex-col gap-4 font-normal px-6 w-[19rem] transition-all duration-300 group-hover:h-fit group-hover:py-2 left-0 items-center text-xs overflow-hidden ${
                    showMenu ? "py-6 h-fit" : "h-0 py-0"
                  }`}
                >
                  {item.childRoutes.map((route: any, i: string) => (
                    <li
                      key={i}
                      className="p-[5px] hover:text-green-500 flex items-center nest-wrapper"
                    >
                      <div>
                        <div className="flex justify-center items-center">
                          <Link
                            className={isActive(route.path)}
                            to={route.path}
                            onClick={handleLinkClick}
                          >
                            {route.title}
                          </Link>
                          <span
                            onClick={() => setShowNestMenu(!showNestMenu)}
                            className="text-[25px] cursor-pointer"
                          >
                            {route.icon && route.icon}
                          </span>
                        </div>
                        {route.childRoute && (
                          <div
                            className={`${
                              showNestMenu
                                ? " pt-3 w-full h-fit"
                                : "overflow-hidden h-0 w-0 p-0"
                            }`}
                          >
                            <ul
                              className={`text-black items-center flex flex-col font-normal transition-all duration-300 h-fit`}
                            >
                              {route.childRoute.map(
                                (childRoute: any, i: string) => (
                                  <li
                                    key={i}
                                    className="p-[5px] hover:text-green-500 flex items-center text-center"
                                  >
                                    <Link
                                      className={isActive(childRoute.path)}
                                      to={childRoute.path}
                                      onClick={handleLinkClick}
                                    >
                                      {childRoute.title}
                                    </Link>
                                  </li>
                                )
                              )}
                            </ul>
                          </div>
                        )}
                      </div>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </li>
        ))}
        <Link
          to={"/cart"}
          className={`flex items-center h-full w-fit text-black gap-2 transition-all duration-300 hover:text-green-500 ${isActive(
            "/cart"
          )}`}
          onClick={handleLinkClick}
        >
          <Fa.FaShoppingCart />
          {`${totalQuantity} items -USD$ ${totalPrice}`}
        </Link>
      </ul>
    </div>
  );
};

export default MobileNav;
