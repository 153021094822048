import React from "react";
interface SimpleDialogProps {
  showDialog: boolean;
  onCloseDialog: () => void;
  title?: string;
  width?: string;
  height?: string;
  isLoading?: boolean;
  className?: string;
  showCloseIcon?: boolean;
}

const PrivacyModal = ({
  showDialog,
  onCloseDialog,
  showCloseIcon,
  title,
  width,
  height,

  className,
}: SimpleDialogProps) => {
  const overlayStyle: React.CSSProperties = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(28, 40, 64, 0.55)",
    zIndex: 49,
    display: showDialog ? "block" : "none",
    overflow: "hidden !important",
  };

  return (
    <div>
      <div
        className="!overflow-hidden"
        style={overlayStyle}
        onClick={onCloseDialog}
      />
      {showDialog && (
        <div
          className={`fixed p-4 z-50 min-w-[750px]  h-auto max-h-[95vh] overflow-y-auto rounded top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2   bg-white ${className} overflow-hidden smallTablet:!w-full smallTablet:!min-w-full`}
          style={{
            boxShadow: "5px 5px 10px 12px rgba(0,2,5,.2)",
          }}
        >
          {/* <p className="text-black  mx-auto text-center font-bold text-[38px]">
                Edit User Info
              </p> */}
          <p
            onClick={onCloseDialog}
            className="absolute right-0 cursor-pointer mr-4 text-2xl font-bold"
          >
            x
          </p>
          <div className="max-w-[1140px] w-full mx-auto py-[50px]">
            <div className=" px-[40px] mobile:px-6 break-all">
              <h2
                className="text-center mt-[15px] mb-[50px] text-[35px]"
                style={{
                  fontFamily: "Montserrat, Sans-serif",
                  fontWeight: "600",
                  lineHeight: "1.4em",
                }}
              >
                Technogenious Solutions: Website Privacy Policy
              </h2>
              <p
                className="mb-[24px] text-[14px]"
                style={{
                  fontFamily: "Montserrat, Sans-serif",
                }}
              >
                Last modified: 6-9-21
              </p>
              <ol
                className="ml-[3em] mb-[1.5em]"
                style={{
                  listStyle: "decimal",
                }}
              >
                <li
                  className="text-[14px]"
                  style={{
                    fontFamily: "Montserrat, Sans-serif",
                  }}
                >
                  Introduction
                </li>
              </ol>
              <p
                className="mb-[24px] text-[14px]"
                style={{
                  fontFamily: "Montserrat, Sans-serif",
                }}
              >
                Technogenious Solutions Ltd (<strong>“Company”</strong>or{" "}
                <strong> “We”</strong>) respect your privacy and are committed
                to protecting it through our compliance with this policy.
              </p>
              <p
                className="mb-[24px] text-[14px]"
                style={{
                  fontFamily: "Montserrat, Sans-serif",
                }}
              >
                This policy describes the types of information we may collect
                from you or that you may provide when you visit the website
                www.technogeniousja.com (our “ <strong>Website</strong>“) and
                our practices for collecting, using, maintaining, protecting,
                and disclosing that information.
              </p>
              <p
                className="mb-[24px] text-[14px]"
                style={{
                  fontFamily: "Montserrat, Sans-serif",
                }}
              >
                This policy applies to information we collect:
              </p>
              <ul
                className="ml-[3em] mb-[1.5em]"
                style={{
                  listStyle: "outside",
                }}
              >
                <li
                  className="text-[14px]"
                  style={{
                    fontFamily: "Montserrat, Sans-serif",
                  }}
                >
                  On this Website.
                </li>
                <li
                  className="text-[14px]"
                  style={{
                    fontFamily: "Montserrat, Sans-serif",
                  }}
                >
                  In email, text, and other electronic messages between you and
                  this Website.
                </li>
              </ul>
              <p
                className="mb-[24px] text-[14px]"
                style={{
                  fontFamily: "Montserrat, Sans-serif",
                }}
              >
                It does not apply to information collected by:
              </p>
              <ul
                className="ml-[3em] mb-[1.5em]"
                style={{
                  listStyle: "outside",
                }}
              >
                <li
                  className="text-[14px]"
                  style={{
                    fontFamily: "Montserrat, Sans-serif",
                  }}
                >
                  Us offline or through any other means, including on any other
                  website operated by Company or any third party (including our
                  affiliates and subsidiaries); or
                </li>
                <li
                  className="text-[14px]"
                  style={{
                    fontFamily: "Montserrat, Sans-serif",
                  }}
                >
                  any third party (including our affiliates and subsidiaries),
                  including through any application or content (including
                  advertising) that may link to or be accessible from or on the
                  Website
                </li>
              </ul>
              <p
                className="mb-[24px] text-[14px]"
                style={{
                  fontFamily: "Montserrat, Sans-serif",
                }}
              >
                Please read this policy carefully to understand our policies and
                practices regarding your information and how we will treat it.
                If you do not agree with our policies and practices, your choice
                is not to use our Website. By accessing or using this Website,
                you agree to this privacy policy. This policy may change from
                time to time without notice. Your continued use of this Website
                after we make changes is deemed to be acceptance of those
                changes, so please check the policy periodically for updates.
              </p>
              <ol
                className="ml-[3em] mb-[1.5em]"
                style={{
                  listStyle: "decimal",
                }}
              >
                <li
                  className="text-[14px]"
                  style={{
                    fontFamily: "Montserrat, Sans-serif",
                  }}
                >
                  Children Under the Age of 13
                </li>
              </ol>
              <p
                className="mb-[24px] text-[14px]"
                style={{
                  fontFamily: "Montserrat, Sans-serif",
                }}
              >
                Our Website is not intended for children under 18 years of age.
                No one under age 18 may provide any personal information to or
                on the Website. We do not knowingly collect personal information
                from children under 13. If you are under 18, do not use or
                provide any information on this Website or on or through any of
                its features/register on the Website, make any purchases through
                the Website, use any of the interactive or public comment
                features of this Website or provide any information about
                yourself to us, including your name, address, telephone number,
                email address, or any screen name or user name you may use
                without the supervision of a parent or guardian. If we learn we
                have collected or received personal information from a child
                under 13 without verification of parental consent, we will
                delete that information. If you believe we might have any
                information from or about a child under 13, please contact us
                using the “Contact Us” link on our Website.
              </p>
              <p
                className="mb-[24px] text-[14px]"
                style={{
                  fontFamily: "Montserrat, Sans-serif",
                }}
              >
                III. Information We Collect About You and How We Collect It
              </p>
              <p
                className="mb-[24px] text-[14px]"
                style={{
                  fontFamily: "Montserrat, Sans-serif",
                }}
              >
                We collect several types of information from and about users of
                our Website, including information:
              </p>
              <ul
                className="ml-[3em] mb-[1.5em]"
                style={{
                  listStyle: "outside",
                }}
              >
                <li
                  className="text-[14px]"
                  style={{
                    fontFamily: "Montserrat, Sans-serif",
                  }}
                >
                  By which you may be personally identified, such as name,
                  postal address, e-mail address, telephone number and any other
                  identifier by which you may be contacted online or offline (“{" "}
                  <strong>Personal Information</strong>“);
                </li>
                <li
                  className="text-[14px]"
                  style={{
                    fontFamily: "Montserrat, Sans-serif",
                  }}
                >
                  That is about you but individually does not identify you, such
                  as user names and/or information about your internet
                  connection, the equipment you use to access our Website and
                  usage details.
                </li>
              </ul>
              <p
                className="mb-[24px] text-[14px]"
                style={{
                  fontFamily: "Montserrat, Sans-serif",
                }}
              >
                We collect this information:
              </p>
              <ul
                className="ml-[3em] mb-[1.5em]"
                style={{
                  listStyle: "outside",
                }}
              >
                <li
                  className="text-[14px]"
                  style={{
                    fontFamily: "Montserrat, Sans-serif",
                  }}
                >
                  Directly from you when you provide it to us.
                </li>
                <li
                  className="text-[14px]"
                  style={{
                    fontFamily: "Montserrat, Sans-serif",
                  }}
                >
                  Automatically as you navigate through the site. Information
                  collected automatically may include usage details, IP
                  addresses, and information collected through cookies and other
                  tracking technologies.
                </li>
              </ul>
              <ol
                className="ml-[3em] mb-[1.5em]"
                style={{
                  listStyle: "decimal",
                }}
              >
                <li
                  className="text-[14px]"
                  style={{
                    fontFamily: "Montserrat, Sans-serif",
                  }}
                >
                  <strong>
                    <u>Information You Provide to Us</u>
                  </strong>
                </li>
              </ol>
              <p
                className="mb-[24px] text-[14px]"
                style={{
                  fontFamily: "Montserrat, Sans-serif",
                }}
              >
                The information we collect on or through our Website may
                include:
              </p>
              <ul
                className="ml-[3em] mb-[1.5em]"
                style={{
                  listStyle: "outside",
                }}
              >
                <li
                  className="text-[14px]"
                  style={{
                    fontFamily: "Montserrat, Sans-serif",
                  }}
                >
                  Information that you provide by filling in forms on our
                  Website. This includes information provided at the time of
                  registering to use our Website, subscribing to any of our
                  services, posting material or requesting further services. We
                  may also ask you for information when you enter a contest or
                  promotion sponsored by us, and when you report a problem with
                  our Website.
                </li>
                <li
                  className="text-[14px]"
                  style={{
                    fontFamily: "Montserrat, Sans-serif",
                  }}
                >
                  Records and copies of your correspondence (including email
                  addresses), if you contact us.
                </li>
                <li
                  className="text-[14px]"
                  style={{
                    fontFamily: "Montserrat, Sans-serif",
                  }}
                >
                  Details of transactions you carry out through our Website and
                  of the fulfillment of your orders. You may be required to
                  provide financial information before placing an order through
                  our Website.
                </li>
                <li
                  className="text-[14px]"
                  style={{
                    fontFamily: "Montserrat, Sans-serif",
                  }}
                >
                  Your search queries on the Website.
                </li>
              </ul>
              <p
                className="mb-[24px] text-[14px]"
                style={{
                  fontFamily: "Montserrat, Sans-serif",
                }}
              >
                You also may provide information to be published or
                displayed/posted on public areas of the Website, or transmitted
                to other users of the Website or third parties (collectively, “{" "}
                <strong>User Contributions</strong>“). Your User Contributions
                are posted on and transmitted to others at your own risk and
                please be aware that no security measures are perfect or
                impenetrable. Additionally, we cannot control the actions of
                other users of the Website with whom you may choose to share
                your User Contributions. Therefore, we cannot and do not
                guarantee that your User Contributions will not be viewed by
                unauthorized persons.
              </p>
              <ol
                className="ml-[3em] mb-[1.5em]"
                style={{
                  listStyle: "decimal",
                }}
              >
                <li
                  className="text-[14px]"
                  style={{
                    fontFamily: "Montserrat, Sans-serif",
                  }}
                >
                  <strong>
                    <u>
                      Information We Collect Through Automatic Data Collection
                      Technologies
                    </u>
                  </strong>
                </li>
              </ol>
              <p
                className="mb-[24px] text-[14px]"
                style={{
                  fontFamily: "Montserrat, Sans-serif",
                }}
              >
                As you navigate through and interact with our Website, we may
                use automatic data collection technologies to collect certain
                information about your equipment, browsing actions, and
                patterns, including:
              </p>
              <ul
                className="ml-[3em] mb-[1.5em]"
                style={{
                  listStyle: "outside",
                }}
              >
                <li
                  className="text-[14px]"
                  style={{
                    fontFamily: "Montserrat, Sans-serif",
                  }}
                >
                  Details of your visits to our Website, including traffic data,
                  location data, logs, and other communication data and the
                  resources that you access and use on the Website.
                </li>
                <li
                  className="text-[14px]"
                  style={{
                    fontFamily: "Montserrat, Sans-serif",
                  }}
                >
                  Information about your computer and internet connection,
                  including your IP address, operating system, and browser type.
                </li>
              </ul>
              <p
                className="mb-[24px] text-[14px]"
                style={{
                  fontFamily: "Montserrat, Sans-serif",
                }}
              >
                The information we collect automatically is statistical data and
                may include Personal Information, but we may maintain it or
                associate it with Personal Information we collect in other ways
                or receive from third parties. It helps us to improve our
                Website and to deliver a better and more personalized service,
                including by enabling us to:
              </p>
              <ul
                className="ml-[3em] mb-[1.5em]"
                style={{
                  listStyle: "outside",
                }}
              >
                <li
                  className="text-[14px]"
                  style={{
                    fontFamily: "Montserrat, Sans-serif",
                  }}
                >
                  Estimate our audience size and usage patterns.
                </li>
                <li
                  className="text-[14px]"
                  style={{
                    fontFamily: "Montserrat, Sans-serif",
                  }}
                >
                  Store information about your preferences, allowing us to
                  customize our Website according to your individual interests.
                </li>
                <li
                  className="text-[14px]"
                  style={{
                    fontFamily: "Montserrat, Sans-serif",
                  }}
                >
                  Speed up your searches.
                </li>
                <li
                  className="text-[14px]"
                  style={{
                    fontFamily: "Montserrat, Sans-serif",
                  }}
                >
                  Recognize you when you return to our Website.
                </li>
              </ul>
              <p
                className="mb-[24px] text-[14px]"
                style={{
                  fontFamily: "Montserrat, Sans-serif",
                }}
              >
                The technologies we use for this automatic data collection may
                include:
              </p>
              <ul
                className="ml-[3em] mb-[1.5em]"
                style={{
                  listStyle: "outside",
                }}
              >
                <li
                  className="text-[14px]"
                  style={{
                    fontFamily: "Montserrat, Sans-serif",
                  }}
                >
                  <strong>Cookies (or browser cookies).</strong> A cookie is a
                  small file placed on the hard drive of your computer. You may
                  refuse to accept browser cookies by activating the appropriate
                  setting on your browser. However, if you select this setting
                  you may be unable to access certain parts of our Website.
                  Unless you have adjusted your browser setting so that it will
                  refuse cookies, our system will issue cookies when you direct
                  your browser to our Website.
                </li>
                <li
                  className="text-[14px]"
                  style={{
                    fontFamily: "Montserrat, Sans-serif",
                  }}
                >
                  <strong>Web Beacons.</strong> Pages of our the Website may
                  contain small electronic files known as web beacons (also
                  referred to as clear gifs, pixel tags, and single-pixel gifs)
                  that permit the Company, for example, to count users who have
                  visited those pages and for other related website statistics
                  (for example, recording the popularity of certain website
                  content and verifying system and server integrity).
                </li>
              </ul>
              <ol
                className="ml-[3em] mb-[1.5em]"
                style={{
                  listStyle: "decimal",
                }}
              >
                <li
                  className="text-[14px]"
                  style={{
                    fontFamily: "Montserrat, Sans-serif",
                  }}
                >
                  <strong>
                    <u>Third-Party Use of Cookies</u>
                  </strong>
                </li>
              </ol>
              <p
                className="mb-[24px] text-[14px]"
                style={{
                  fontFamily: "Montserrat, Sans-serif",
                }}
              >
                Some content or applications, including advertisements, on the
                Website are served by third-parties, including advertisers, ad
                networks and servers, content providers, and application
                providers. These third parties may use cookies to collect
                information about you when you use our Website. The information
                they collect may be associated with your Personal Information or
                they may collect information, including Personal Information,
                about your online activities over time and across different
                websites and other online services. They may use this
                information to provide you with interest-based (behavioral)
                advertising or other targeted content.
              </p>
              <p
                className="mb-[24px] text-[14px]"
                style={{
                  fontFamily: "Montserrat, Sans-serif",
                }}
              >
                We do not control these third parties’ tracking technologies or
                how they may be used. If you have any questions about an
                advertisement or other targeted content, you should contact the
                responsible provider directly.
              </p>
              <p
                className="mb-[24px] text-[14px]"
                style={{
                  fontFamily: "Montserrat, Sans-serif",
                }}
              >
                <strong>
                  VII. <u>How We Use Your Information</u>
                </strong>
              </p>
              <p
                className="mb-[24px] text-[14px]"
                style={{
                  fontFamily: "Montserrat, Sans-serif",
                }}
              >
                We use information that we collect about you or that you provide
                to us, including any Personal Information:
              </p>
              <ul
                className="ml-[3em] mb-[1.5em]"
                style={{
                  listStyle: "outside",
                }}
              >
                <li
                  className="text-[14px]"
                  style={{
                    fontFamily: "Montserrat, Sans-serif",
                  }}
                >
                  To present our Website and its contents to you.
                </li>
                <li
                  className="text-[14px]"
                  style={{
                    fontFamily: "Montserrat, Sans-serif",
                  }}
                >
                  To provide you with information, products, or services that
                  you request from us.
                </li>
                <li
                  className="text-[14px]"
                  style={{
                    fontFamily: "Montserrat, Sans-serif",
                  }}
                >
                  To fulfill any other purpose for which you provide it.
                </li>
                <li
                  className="text-[14px]"
                  style={{
                    fontFamily: "Montserrat, Sans-serif",
                  }}
                >
                  To provide you with notices about your account.
                </li>
                <li
                  className="text-[14px]"
                  style={{
                    fontFamily: "Montserrat, Sans-serif",
                  }}
                >
                  To carry out our obligations and enforce our rights arising
                  from any contracts entered into between you and us, including
                  for billing and collection.
                </li>
                <li
                  className="text-[14px]"
                  style={{
                    fontFamily: "Montserrat, Sans-serif",
                  }}
                >
                  To notify you about changes to our Website or any products or
                  services we offer or provide though it.
                </li>
                <li
                  className="text-[14px]"
                  style={{
                    fontFamily: "Montserrat, Sans-serif",
                  }}
                >
                  To allow you to participate in interactive features on our
                  Website.
                </li>
              </ul>
              <p
                className="mb-[24px] text-[14px]"
                style={{
                  fontFamily: "Montserrat, Sans-serif",
                }}
              >
                We may use the information we have collected from you to enable
                us to display advertisements to our advertisers’ target
                audiences. Even though we do not disclose your Personal
                Information for these purposes without your consent, if you
                click on or otherwise interact with an advertisement, the
                advertiser may assume that you meet its target criteria.
              </p>
              <p
                className="mb-[24px] text-[14px]"
                style={{
                  fontFamily: "Montserrat, Sans-serif",
                }}
              >
                <strong>
                  VIII. <u>Disclosure of Your Information</u>
                </strong>
              </p>
              <p
                className="mb-[24px] text-[14px]"
                style={{
                  fontFamily: "Montserrat, Sans-serif",
                }}
              >
                We may disclose aggregated information about our users, and
                information that does not identify any individual, without
                restriction.
              </p>
              <p
                className="mb-[24px] text-[14px]"
                style={{
                  fontFamily: "Montserrat, Sans-serif",
                }}
              >
                We may disclose Personal Information that we collect or you
                provide as described in this privacy policy:
              </p>
              <ul
                className="ml-[3em] mb-[1.5em]"
                style={{
                  listStyle: "outside",
                }}
              >
                <li
                  className="text-[14px]"
                  style={{
                    fontFamily: "Montserrat, Sans-serif",
                  }}
                >
                  To our subsidiaries and affiliates.
                </li>
                <li
                  className="text-[14px]"
                  style={{
                    fontFamily: "Montserrat, Sans-serif",
                  }}
                >
                  To contractors, service providers, and other third parties we
                  use to support our business.
                </li>
                <li
                  className="text-[14px]"
                  style={{
                    fontFamily: "Montserrat, Sans-serif",
                  }}
                >
                  To a buyer or other successor in the event of a merger,
                  divestiture, restructuring, reorganization, dissolution, or
                  other sale or transfer of some or all of Technogenious
                  Solutions Ltd.’s assets, whether as a going concern or as part
                  of bankruptcy, liquidation, or similar proceeding, in which
                  personal information held by Technogenious Solutions Ltd.
                  about our Website users is among the assets transferred.
                </li>
                <li
                  className="text-[14px]"
                  style={{
                    fontFamily: "Montserrat, Sans-serif",
                  }}
                >
                  To fulfill the purpose for which you provide it.
                </li>
                <li
                  className="text-[14px]"
                  style={{
                    fontFamily: "Montserrat, Sans-serif",
                  }}
                >
                  For any other purpose disclosed by us when you provide the
                  information.
                </li>
                <li
                  className="text-[14px]"
                  style={{
                    fontFamily: "Montserrat, Sans-serif",
                  }}
                >
                  With your consent.
                </li>
              </ul>
              <p
                className="mb-[24px] text-[14px]"
                style={{
                  fontFamily: "Montserrat, Sans-serif",
                }}
              >
                We may also disclose your personal information:
              </p>
              <ul
                className="ml-[3em] mb-[1.5em]"
                style={{
                  listStyle: "outside",
                }}
              >
                <li
                  className="text-[14px]"
                  style={{
                    fontFamily: "Montserrat, Sans-serif",
                  }}
                >
                  To comply with any court order, law, or legal process,
                  including to respond to any government or regulatory request.
                </li>
                <li
                  className="text-[14px]"
                  style={{
                    fontFamily: "Montserrat, Sans-serif",
                  }}
                >
                  To enforce or apply our terms of use
                  technogeniousja.com/terms-and-conditions/ or Terms of Sale
                  technogeniousja.com/terms-and-conditions/ and other
                  agreements, including for billing and collection purposes.
                </li>
                <li
                  className="text-[14px]"
                  style={{
                    fontFamily: "Montserrat, Sans-serif",
                  }}
                >
                  If we believe disclosure is necessary or appropriate to
                  protect the rights, property, or safety of Technogenious
                  Solutions Ltd., our customers, or others. This includes
                  exchanging information with other companies and organizations
                  for the purposes of fraud protection and credit risk
                  reduction.
                </li>
              </ul>
              <p
                className="mb-[24px] text-[14px]"
                style={{
                  fontFamily: "Montserrat, Sans-serif",
                }}
              >
                <strong>
                  XIV. <u>Accessing and Correcting Your Information</u>
                </strong>
              </p>
              <p
                className="mb-[24px] text-[14px]"
                style={{
                  fontFamily: "Montserrat, Sans-serif",
                }}
              >
                You can review and change your Personal Information by logging
                into the Website and visiting your account profile page.
              </p>
              <p
                className="mb-[24px] text-[14px]"
                style={{
                  fontFamily: "Montserrat, Sans-serif",
                }}
              >
                If you delete your User Contributions from the Website, copies
                of your User Contributions may remain viewable in cached and
                archived pages, or might have been copied or stored by other
                Website users. Proper access and use of information provided on
                the Website, including User Contributions, is governed by our
                terms of use technogeniousja.com/terms-and-conditions/.
              </p>
              <p
                className="mb-[24px] text-[14px]"
                style={{
                  fontFamily: "Montserrat, Sans-serif",
                }}
              >
                <strong>
                  XVI. <u>Data Security</u>
                </strong>
              </p>
              <p
                className="mb-[24px] text-[14px]"
                style={{
                  fontFamily: "Montserrat, Sans-serif",
                }}
              >
                We have implemented measures designed to secure your Personal
                Information from accidental loss and from unauthorized access,
                use, alteration, and disclosure.
              </p>
              <p
                className="mb-[24px] text-[14px]"
                style={{
                  fontFamily: "Montserrat, Sans-serif",
                }}
              >
                The safety and security of your information also depends on you.
                Where we have given you (or where you have chosen) a password
                for access to certain parts of our Website, you are responsible
                for keeping this password confidential. We ask you not to share
                your password with anyone. We urge you to be careful about
                giving out information in public areas of the Website like
                message boards. The information you share in public areas may be
                viewed by any user of the Website.
              </p>
              <p
                className="mb-[24px] text-[14px]"
                style={{
                  fontFamily: "Montserrat, Sans-serif",
                }}
              >
                Unfortunately, the transmission of information via the internet
                is not completely secure. Although we do our best to protect
                your Personal Information, we cannot guarantee the security of
                your Personal Information transmitted to our Website. Any
                transmission of Personal Information is at your own risk. We are
                not responsible for circumvention of any privacy settings or
                security measures contained on the Website.
              </p>
              <p
                className="mb-[24px] text-[14px]"
                style={{
                  fontFamily: "Montserrat, Sans-serif",
                }}
              >
                <strong>
                  XVII. <u>Changes to Our Privacy Policy</u>
                </strong>
              </p>
              <p
                className="mb-[24px] text-[14px]"
                style={{
                  fontFamily: "Montserrat, Sans-serif",
                }}
              >
                It is our policy to post any changes we make to our privacy
                policy on this page. If we make material changes to how we treat
                our users’ Personal Information, we will attempt to notify you
                through a notice on the Website home page. The date this Privacy
                Policy was last revised is identified at the top of the page.
                You are responsible for periodically visiting our Website and
                this Privacy Policy to check for any changes.{" "}
              </p>
              <p
                className="mb-[24px] text-[14px]"
                style={{
                  fontFamily: "Montserrat, Sans-serif",
                }}
              >
                <strong>SECURITY POLICY</strong>
              </p>
              <p
                className="mb-[24px] text-[14px]"
                style={{
                  fontFamily: "Montserrat, Sans-serif",
                }}
              >
                Your payment and personal information is always safe. Our Secure
                Sockets Layer (SSL) software is the industry standard and among
                the best software available today for secure commerce
                transactions. It encrypts all of your personal information,
                including credit card number, name, and address, so that it
                cannot be read over the internet.
              </p>
              <p
                className="mb-[24px] text-[14px]"
                style={{
                  fontFamily: "Montserrat, Sans-serif",
                }}
              >
                <strong>SHIPPING POLICY/DELIVERY POLICY</strong>
              </p>
              <p
                className="mb-[24px] text-[14px]"
                style={{
                  fontFamily: "Montserrat, Sans-serif",
                }}
              >
                Please be assured that your items will ship out within two days
                of purchase. We determine the most efficient shipping carrier
                for your order. The carriers that may be used internationally
                are DHL Service or FedEx and Locally courier services offered by
                Knutsford Express and Door way Express. Sorry, but we cannot
                ship to P.O. Boxes.
              </p>
              <p
                className="mb-[24px] text-[14px]"
                style={{
                  fontFamily: "Montserrat, Sans-serif",
                }}
              >
                If you’re trying to estimate when a package will be delivered,
                please note the following:
              </p>
              <p
                className="mb-[24px] text-[14px]"
                style={{
                  fontFamily: "Montserrat, Sans-serif",
                }}
              >
                Credit card authorization and verification must be received
                prior to processing. If you require express or 2 day shipping,
                please call us at 876-648-4163 for charges.
              </p>
              <p
                className="mb-[24px] text-[14px]"
                style={{
                  fontFamily: "Montserrat, Sans-serif",
                }}
              >
                <strong>Cancel Items and Orders</strong>
              </p>
              <p
                className="mb-[24px] text-[14px]"
                style={{
                  fontFamily: "Montserrat, Sans-serif",
                }}
              >
                Physical items can be cancelled; however, cancellation is only
                applicable if the product is not shipped from the company’s
                warehouse or in the process of shipping.
              </p>
              <p
                className="mb-[24px] text-[14px]"
                style={{
                  fontFamily: "Montserrat, Sans-serif",
                }}
              >
                <strong>Return/Refund Policy</strong>
              </p>
              <p
                className="mb-[24px] text-[14px]"
                style={{
                  fontFamily: "Montserrat, Sans-serif",
                }}
              >
                Items that are deemed defective or damaged by the company,
                Technogenious Solutions Ltd, from shipping can be returned
                within 7 working days for a full refund. All parts and supplies
                can be returned within 7 working days and may be subject to a
                restocking fee of up to 20%. All game machines are custom built
                to specific requests and are not able to be returned. All
                refunds will be provided as a credit to the credit card used at
                the time of purchase within 5 business days upon receipt of the
                returned merchandise.
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PrivacyModal;
