import React, { useEffect, useState } from "react";
import Coupon from "../Components/Coupon";
import Billing from "../Components/Billing";
import OrderSummary from "../Components/OrderSummary";
import { Link, useNavigate } from "react-router-dom";
import PrivacyModal from "../Components/PrivacyModal";
import { Formik, Form, Field, ErrorMessage, useFormikContext } from "formik";
import * as Yup from "yup";
import { loadStripe } from "@stripe/stripe-js";
import {
  Elements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import "../Components/Payment.css"; // Import the CSS file

import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { DataId, cartItems } from "../Components/atoms/atoms";
import useFetch from "../Network/useFetch";
import clsx from "clsx";
import { Country, State, City } from "country-state-city";
import PaymentModal from "../Components/PaymentModal";
import { useMutation } from "react-query";
import axios from "axios";
import Toast from "../Components/Toast/toast";
import Loader from "../Components/Loader";

const Checkout = () => {
  const navigate = useNavigate();
  const [checked, setChecked] = useState(false);
  const [data, setData] = useState(null);
  const chekoutSchema2 = Yup.object().shape({
    firstName: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Required*"),
    lastName: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Required*"),
    companyName: Yup.string(),
    // country: Yup.string().required("Required*"),
    streetAddress: Yup.string().required("Required*"),
    appartment: Yup.string(),
    town: Yup.string().required("Required*"),
    // state: Yup.string().required("Required*"),
    postalCode: Yup.string().required("Required*"),
    // phone: Yup.number().required("Required*"),
    email: Yup.string().email("Invalid email").required("Required*"),
    // // checked: Yup.boolean(),
    phone: Yup.string().matches(/^\d{6,14}$/, "Invalid phone number"),
    // Example phone number validation

    // differentShipFirstName: Yup.string().required("Required"),
    // differentShipLastName: Yup.string().required("Required"),
    // differentShipCompanyName: Yup.string(),
    // differentShipStreet: Yup.string().required("Required"),
    // differentAppartment: Yup.string().required("Required"),
    // diffTown: Yup.string().required("Required"),
    // diffPostal: Yup.string(),
    orderNotes: Yup.string(),
    ...(checked && {
      differentShipFirstName: Yup.string().required("Required*"),
      differentShipLastName: Yup.string().required("Required*"),
      differentShipCompanyName: Yup.string(),
      differentShipStreet: Yup.string().required("Required*"),
      differentAppartment: Yup.string(),
      diffTown: Yup.string().required("Required*"),
      diffPostal: Yup.string().required("Required*"),
    }),
  });
  const dataIdValue = useRecoilValue(DataId);

  const countries = Country.getAllCountries();
  const diffCountries = Country.getAllCountries();
  const setDataValue = useSetRecoilState(DataId);
  const [error, setError] = useState({
    country: false,
    state: false,
    diffCountry: false,
    diffState: false,
  });
  const [shippingValue, setShippingValue] = useState({
    option: null,
  });
  const handleCheckboxChange = (selectedOption) => {
    setShippingValue({
      option: selectedOption,
    });
  };

  const {
    isLoading: cartLoading,
    data: cartData,
    error: cartError,
    isError,
  } = useFetch(
    `${process.env.REACT_APP_SERVER_URL}/cart/${dataIdValue}`,
    ["fetch-cart", dataIdValue as unknown as string],

    {
      enabled: true,
    }
  );

  const handleSubmit = async ({ id, body }) => {
    let value;
    if (shippingValue.option === "option1") {
      value = "Store Pickup: Free";
    }
    if (shippingValue.option === "option2") {
      value = "Bearer Delivery (Fixed Rate, Close Proximity): $50.00";
    }
    if (shippingValue.option === "option3") {
      value = "Local Courier Pickup (COD, Rate Determined by Courier):$0";
    }
    if (shippingValue.option === "option4") {
      value =
        "  International Delivery (Fixed Rate, Estimated, Subject toChange): $150.00";
    }
    const { data } = await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/cart/check-out/${id}`,
      {
        billingDetails: body.billingDetails,
        ...(checked && {
          isShipToDifferentAdress: checked,
          shippingDetails: body.shippingDetails,
        }),

        shippingOption: value,
      }
    );
    return data;
  };
  const [toast, setToast] = useState({ type: "", message: "" });
  useEffect(() => {
    if (cartError?.message) {
      setToast({ type: "success", message: "Cart updated successfully" });
    }
  }, [isError]);
  const handleCall = async ({ id }) => {
    const { data } = await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/cart/create-payment-intent/${id}`,
      {}
    );
    return data;
  };

  const { mutate: apiMutate, isLoading: apiLoading } = useMutation(handleCall, {
    onSuccess: (data) => {
      setToast({ type: "success", message: "Cart updated successfully" });
      setData(data?.data?.clientSecret);
      setOpenModal(true);
    },
    onError: (error) => {
      setToast({
        type: "error",
        message: "Something went wrong",
      });
    },
  });
  const { mutate, isLoading } = useMutation(handleSubmit, {
    onSuccess: (data) => {
      setToast({ type: "success", message: "Cart updated successfully" });

      apiMutate({ id: dataIdValue });
    },
    onError: (error) => {
      setToast({
        type: "error",
        message: "Something went wrong",
      });
    },
  });
  const [cartValue, setCartValue] = useRecoilState(cartItems);
  const [openPrivacyModal, setOpenPrivacyModal] = useState(false);
  const [isTermsChecked, setIsTermsChecked] = useState(false);
  const [button, setButton] = useState(false);
  const [countrySelect, setCountrySelect] = useState({
    code: null,
    name: null,
  });

  const [stateSelect, setStateSelect] = useState(null);
  const [diffCountrySelect, setDiffCCountrySelect] = useState({
    code: null,
    name: null,
  });
  const [diffStateSelect, setDiffStateSelect] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const states = State.getStatesOfCountry(countrySelect?.code);
  const diffStates = State.getStatesOfCountry(diffCountrySelect.code);
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

  // imp useEffect
  useEffect(() => {
    if (!dataIdValue.length) {
      navigate("/cart");
    }
  }, [dataIdValue?.length]);

  let total;
  useEffect(() => {
    if (button) {
      if (countrySelect.code === null) {
        setError((pre) => ({ ...pre, country: true }));
      } else {
        setError((pre) => ({ ...pre, country: false }));
      }
      if (stateSelect === null) {
        setError((pre) => ({ ...pre, state: true }));
      } else {
        setError((pre) => ({ ...pre, state: false }));
      }
      if (diffCountrySelect.code === null) {
        setError((pre) => ({ ...pre, diffCountry: true }));
      } else {
        setError((pre) => ({ ...pre, diffCountry: false }));
      }
      if (diffStateSelect === null) {
        setError((pre) => ({ ...pre, diffState: true }));
      } else {
        setError((pre) => ({ ...pre, diffState: false }));
      }
    }
  }, [
    button,
    countrySelect.code,
    stateSelect,
    diffCountrySelect.code,
    diffStateSelect,
  ]);
  const CountrySelect = ({ countries }) => {
    const { setFieldValue } = useFormikContext();

    const handleChange = (e) => {
      setFieldValue("country", e.target.value);
      setCountrySelect({
        code: e.target.value,
        name: countries.find((data) => data.isoCode === e.target.value)?.name,
      });
    };

    return (
      <Field
        as="select"
        id="selection"
        name="country"
        className="w-full border text-sm rounded border-[#DDDDDD] py-2 px-3 focus:border focus:border-dotted focus:border-black outline-none"
        onChange={handleChange}
      >
        <option value="">Select a value</option>
        {countries?.map((data, index) => (
          <option key={index} value={data.isoCode}>
            {data.name}
          </option>
        ))}
      </Field>
    );
  };
  const DiffCountrySelect = ({ diffCountries }) => {
    const { setFieldValue } = useFormikContext();

    const handleChange = (e) => {
      setFieldValue("differentShipCountryName", e.target.value);
      setDiffCCountrySelect({
        code: e.target.value,
        name: diffCountries.find((data) => data.isoCode === e.target.value)
          ?.name,
      });
    };

    return (
      <Field
        as="select"
        id="selection"
        name="differentShipCountryName"
        className="w-full border text-sm rounded border-[#DDDDDD] py-2 px-3 focus:border focus:border-dotted focus:border-black outline-none"
        onChange={handleChange}
      >
        <option value="">Select a value</option>
        {diffCountries?.map((data, index) => (
          <option key={index} value={data.isoCode}>
            {data.name}
          </option>
        ))}
      </Field>
    );
  };
  const StateSelect = ({ states }) => {
    const { setFieldValue } = useFormikContext();

    const handleChange = (e) => {
      setFieldValue("state", e.target.value);
      setStateSelect(e.target.value);
    };

    return (
      <Field
        as="select"
        id="selection"
        name="state"
        className="w-full border text-sm rounded border-[#DDDDDD] py-2 px-3 focus:border focus:border-dotted focus:border-black outline-none"
        onChange={handleChange}
      >
        <option value="">Select a value</option>
        {states?.map((data, index) => (
          <option key={index} value={data.name}>
            {data.name}
          </option>
        ))}
      </Field>
    );
  };

  const DiffStateSelect = ({ states }) => {
    const { setFieldValue } = useFormikContext();

    const handleChange = (e) => {
      setFieldValue("diffState", e.target.value);
      setDiffStateSelect(e.target.value);
    };
    return (
      <Field
        as="select"
        id="selection"
        name="diffState"
        className="w-full border text-sm rounded border-[#DDDDDD] py-2 px-3 focus:border focus:border-dotted focus:border-black outline-none"
        onChange={handleChange}
      >
        <option value="">Select a value</option>
        {diffStates?.map((data, index) => (
          <option key={index} value={data.name}>
            {data.name}
          </option>
        ))}
      </Field>
    );
  };
  let shippingCost = 0;
  return (
    <div className="mt-4 mb-8 max-w-[1200px] mx-auto desktop:mx-5">
      <Coupon />
      <Formik
        initialValues={
          // checked?
          {
            firstName: "",
            email: "",
            lastName: "",
            companyName: "",
            // country: "",
            streetAddress: "",
            appartment: "",
            town: "",
            postalCode: "",
            // state: "",
            phone: "",
            differentShipFirstName: checked ? "" : undefined,
            differentShipLastName: checked ? "" : undefined,
            differentShipCompanyName: checked ? "" : undefined,
            differentShipStreet: checked ? "" : undefined,
            differentAppartment: checked ? "" : undefined,
            diffTown: checked ? "" : undefined,
            diffPostal: checked ? "" : undefined,
            orderNotes: "",
            //   }
            // : {
            //     firstName: "",
            //     email: "",
            //     lastName: "",
            //     // country: "",
            //     streetAddress: "",
            //     appartment: "",
            //     town: "",
            //     postalCode: "",
            //     // state: "",
            //     phone: "",
            //     orderNotes: "",
            //   }
          }
        }
        validationSchema={chekoutSchema2}
        onSubmit={(values, { setSubmitting, resetForm, setFieldValue }) => {
          // Your form submission logic here
          if (!checked) {
            if (!countrySelect.code) {
              if (states && !stateSelect) {
                return;
              }
            }
            if (countrySelect.code) {
              if (states.length) {
                if (!stateSelect) return;
              }
            }
          }
          if (checked) {
            if (!countrySelect.code) {
              if (states && !stateSelect) {
                return;
              }
            }
            if (countrySelect.code) {
              if (states.length) {
                if (!stateSelect) return;
              }
            }
            if (!diffCountrySelect.code) {
              if (diffStates.length) {
                if (!diffStateSelect) {
                  return;
                }
              }
            }
            if (diffCountrySelect.code) {
              if (diffStates.length) {
                if (!diffStateSelect) {
                  return;
                }

                return;
              }
            }
          }
          // else if (checked) {
          //   if (
          //     !countrySelect.code &&
          //     !stateSelect &&
          //     !diffCountrySelect.code &&
          //     diffStateSelect
          //   ) {
          //     return;
          //   }
          // }
          let result = !checked
            ? {
                billingDetails: {
                  ...values,
                  country: countrySelect.name,
                  state: stateSelect,
                },
              }
            : {
                billingDetails: {
                  ...values,
                  country: countrySelect.name,
                  state: stateSelect,
                },
                isShipToDifferentAdress: checked,
                shippingDetails: {
                  firstName: values.differentShipFirstName,
                  lastName: values.differentShipLastName,
                  companyName: values.differentShipCompanyName,
                  country: diffCountrySelect?.name,
                  state: diffStateSelect,
                  streetAddress: values.differentShipStreet,
                  postalCode: values.diffPostal,
                  town: values.diffTown,
                },
              };
          mutate({ id: dataIdValue, body: result });
          // result=checked?{...values,country:}
          // navigate("/payment");
          // mutate(values);

          // resetForm({});
          // setSubmitting(false);
        }}
      >
        <>
          <Form className="my-4 flex gap-x-10 mt-12 tablet:flex-col">
            {toast.type && (
              <Toast
                type={toast.type as "Sucess" | "Error"}
                message={toast.message}
              />
            )}
            <div className="flex-[2_2_55%] ">
              <div>
                <p className="text-base font-bold">Billing details</p>
                <p className="border-[0.8px] mt-2 border-t-[#DDDDDD]"></p>
                <Form className="flex w-full flex-col gap-y-3 my-8">
                  <div className="flex gap-x-7 w-full">
                    <div className="flex flex-col gap-y-2 w-1/2">
                      <label className="text-xs font-bold">
                        First name <span className="text-[#E02424]">*</span>
                      </label>
                      <Field
                        type="text"
                        name="firstName"
                        className="border text-sm rounded border-[#DDDDDD] py-2 px-3 focus:border focus:border-dotted focus:border-black outline-none"
                      />
                      <ErrorMessage
                        name="firstName"
                        component="div"
                        className="text-red-600 text-xs"
                      />
                    </div>
                    <div className="flex flex-col gap-y-2 w-1/2">
                      <label className="text-xs font-bold">
                        Last name <span className="text-[#E02424]">*</span>
                      </label>
                      <Field
                        type="text"
                        name="lastName"
                        className="border text-sm rounded border-[#DDDDDD] py-2 px-3 focus:border focus:border-dotted focus:border-black outline-none"
                      />
                      <ErrorMessage
                        name="lastName"
                        component="div"
                        className="text-red-600 text-xs"
                      />
                    </div>
                  </div>
                  <div className="flex flex-col gap-y-2 w-full">
                    <label className="text-xs font-bold">
                      Company name (optional){" "}
                    </label>
                    <input className="border text-sm rounded border-[#DDDDDD] py-2 px-3 focus:border focus:border-dotted focus:border-black outline-none"></input>
                  </div>
                  <div className="flex flex-col gap-y-2 w-full">
                    <label className="text-xs font-bold">
                      Country / Region <span className="text-[#E02424]">*</span>
                    </label>
                    <Formik
                      initialValues={{ country: "" }}
                      onSubmit={(values) => {}}
                    >
                      {() => (
                        <Form>
                          <CountrySelect countries={countries} />
                        </Form>
                      )}
                    </Formik>
                    {error.country ? (
                      <div className="text-red-600 text-xs">"Required*"</div>
                    ) : null}
                  </div>
                  <div className="flex flex-col gap-y-2 w-full">
                    <label className="text-xs font-bold">
                      Street address <span className="text-[#E02424]">*</span>
                    </label>
                    <Field
                      name="streetAddress"
                      placeholder="House number and street name"
                      className="border text-sm rounded border-[#DDDDDD] py-2 px-3 focus:border focus:border-dotted focus:border-black outline-none"
                    ></Field>
                    <ErrorMessage
                      name="streetAddress"
                      component="div"
                      className="text-red-600 text-xs"
                    />
                    <Field
                      name="appartment"
                      placeholder="Appartment,suite,unit etc"
                      className="border text-sm rounded border-[#DDDDDD] py-2 px-3 focus:border focus:border-dotted focus:border-black outline-none"
                    ></Field>
                    <ErrorMessage
                      name="appartment"
                      component="div"
                      className="text-red-600 text-xs"
                    />
                  </div>
                  <div className="flex flex-col gap-y-2 w-full">
                    <label className="text-xs font-bold">
                      Town / City <span className="text-[#E02424]">*</span>
                    </label>
                    <Field
                      name="town"
                      className="border text-sm rounded border-[#DDDDDD] py-2 px-3 focus:border focus:border-dotted focus:border-black outline-none"
                    ></Field>
                    <ErrorMessage
                      name="town"
                      component="div"
                      className="text-red-600 text-xs"
                    />
                  </div>
                  {states?.length ? (
                    <div className="flex flex-col gap-y-2 w-full">
                      <label className="text-xs font-bold">
                        State / County <span className="text-[#E02424]">*</span>
                      </label>
                      <Formik
                        initialValues={{ state: "" }}
                        onSubmit={(values) => {}}
                      >
                        {() => (
                          <Form>
                            <StateSelect states={states} />
                          </Form>
                        )}
                      </Formik>
                      {error?.state ? (
                        <div className="text-red-600 text-xs">Required*</div>
                      ) : null}
                    </div>
                  ) : null}
                  <div className="flex flex-col gap-y-2 w-full">
                    <label className="text-xs font-bold">
                      Postal code / ZIP{" "}
                      <span className="text-[#E02424]">*</span>
                    </label>
                    <Field
                      name="postalCode"
                      className="border text-sm rounded border-[#DDDDDD] py-2 px-3 focus:border focus:border-dotted focus:border-black outline-none"
                    ></Field>
                    <ErrorMessage
                      name="postalCode"
                      component="div"
                      className="text-red-600 text-xs"
                    />
                  </div>
                  <div className="flex flex-col gap-y-2 w-full">
                    <label className="text-xs font-bold">
                      Phone <span className="text-[#E02424]">*</span>
                    </label>
                    <Field
                      name="phone"
                      className="border text-sm rounded border-[#DDDDDD] py-2 px-3 focus:border focus:border-dotted focus:border-black outline-none"
                    ></Field>
                    <ErrorMessage
                      name="phone"
                      component="div"
                      className="text-red-600 text-xs"
                    />
                  </div>
                  <div className="flex flex-col gap-y-2 w-full">
                    <label className="text-xs font-bold">
                      Email address <span className="text-[#E02424]">*</span>
                    </label>
                    <Field
                      name="email"
                      className="border text-sm rounded border-[#DDDDDD] py-2 px-3 focus:border focus:border-dotted focus:border-black outline-none"
                    ></Field>
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="text-red-600 text-xs"
                    />
                  </div>
                </Form>
                <div className="flex gap-x-2">
                  <input
                    onChange={() => setChecked(!checked)}
                    type="checkbox"
                  />
                  <h1 className="font-semibold text-base">
                    Ship to a different address?
                  </h1>
                </div>
                {checked ? (
                  <div className="flex w-full flex-col gap-y-3 my-8">
                    <div className="flex gap-x-7 w-full">
                      <div className="flex flex-col gap-y-2 w-1/2">
                        <label className="text-xs font-bold">
                          First name <span className="text-[#E02424]">*</span>
                        </label>
                        <Field
                          name="differentShipFirstName"
                          className="border text-sm rounded border-[#DDDDDD] py-2 px-3 focus:border focus:border-dotted focus:border-black outline-none"
                        ></Field>
                        <ErrorMessage
                          name="differentShipFirstName"
                          component="div"
                          className="text-red-600 text-xs"
                        />
                      </div>
                      <div className="flex flex-col gap-y-2 w-1/2">
                        <label className="text-xs font-bold">
                          Last name <span className="text-[#E02424]">*</span>
                        </label>
                        <Field
                          name="differentShipLastName"
                          className="border text-sm rounded border-[#DDDDDD] py-2 px-3 focus:border focus:border-dotted focus:border-black outline-none"
                        ></Field>
                        <ErrorMessage
                          name="differentShipLastName"
                          component="div"
                          className="text-red-600 text-xs"
                        />
                      </div>
                    </div>
                    <div className="flex flex-col gap-y-2 w-full">
                      <label className="text-xs font-bold">
                        Company name (optional){" "}
                      </label>
                      <input
                        // name="differentShipLastName"
                        className="border text-sm rounded border-[#DDDDDD] py-2 px-3 focus:border focus:border-dotted focus:border-black outline-none"
                      ></input>

                      {/* <Field
                      as="select"
                      name="differentShipCountryName"
                      className="border text-sm rounded border-[#DDDDDD] py-2 px-3 focus:border focus:border-dotted focus:border-black outline-none"
                    ></Field>
                    <ErrorMessage
                      name="differentShipCountryName"
                      component="div"
                      className="text-red-600 text-xs"
                    /> */}
                    </div>
                    <div className="flex flex-col gap-y-2 w-full">
                      <label className="text-xs font-bold">
                        Country / Region{" "}
                        <span className="text-[#E02424]">*</span>
                      </label>
                      <Formik
                        initialValues={{ state: "" }}
                        onSubmit={(values) => {}}
                      >
                        {() => (
                          <Form>
                            <DiffCountrySelect diffCountries={diffCountries} />
                          </Form>
                        )}
                      </Formik>
                      {error.diffCountry ? (
                        <div className="text-red-600 text-xs">"Required*"</div>
                      ) : null}
                      {/* <Field
                      as="select"
                      name="differentShipCountryName"
                      className="border text-sm rounded border-[#DDDDDD] py-2 px-3 focus:border focus:border-dotted focus:border-black outline-none"
                    ></Field> */}
                    </div>
                    <div className="flex flex-col gap-y-2 w-full">
                      <label className="text-xs font-bold">
                        Street address <span className="text-[#E02424]">*</span>
                      </label>
                      <Field
                        name="differentShipStreet"
                        placeholder="House number and street name"
                        className="border text-sm rounded border-[#DDDDDD] py-2 px-3 focus:border focus:border-dotted focus:border-black outline-none"
                      ></Field>
                      <ErrorMessage
                        name="differentShipStreet"
                        component="div"
                        className="text-red-600 text-xs"
                      />
                      <Field
                        name="differentAppartment"
                        placeholder="Appartment,suite,unit etc"
                        className="border text-sm rounded border-[#DDDDDD] py-2 px-3 focus:border focus:border-dotted focus:border-black outline-none"
                      ></Field>
                      <ErrorMessage
                        name="differentAppartment"
                        component="div"
                        className="text-red-600 text-xs"
                      />
                    </div>
                    <div className="flex flex-col gap-y-2 w-full">
                      <label className="text-xs font-bold">
                        Town / City / Post Office{" "}
                        <span className="text-[#E02424]">*</span>
                      </label>
                      {/* <input className="border text-sm rounded border-[#DDDDDD] py-2 px-3 focus:border focus:border-dotted focus:border-black outline-none"></input> */}
                      <Field
                        name="diffTown"
                        placeholder="Appartment,suite,unit etc"
                        className="border text-sm rounded border-[#DDDDDD] py-2 px-3 focus:border focus:border-dotted focus:border-black outline-none"
                      ></Field>
                      <ErrorMessage
                        name="diffTown"
                        component="div"
                        className="text-red-600 text-xs"
                      />
                    </div>
                    {diffStates?.length ? (
                      <div className="flex flex-col gap-y-2 w-full">
                        <label className="text-xs font-bold">
                          State / County{" "}
                          <span className="text-[#E02424]">*</span>
                        </label>
                        <Formik
                          initialValues={{ diffStates: "" }}
                          onSubmit={(values) => {}}
                        >
                          {() => (
                            <Form>
                              <DiffStateSelect states={diffStates} />
                            </Form>
                          )}
                        </Formik>
                        {error?.diffState ? (
                          <div className="text-red-600 text-xs">Required*</div>
                        ) : null}
                      </div>
                    ) : null}
                    {/* <div className="flex flex-col gap-y-2 w-full">
                      <label className="text-xs font-bold">
                        Parish <span className="text-[#E02424]">*</span>
                      </label>
                      <select className="border bg-transparent rounded text-sm border-[#DDDDDD] py-3 px-3 focus:border focus:border-dotted focus:border-black outline-none">
                        <option>Pakistan</option>
                      </select>
                    </div> */}
                    <div className="flex flex-col gap-y-2 w-full">
                      <label className="text-xs font-bold">
                        Postal code (optional){" "}
                      </label>
                      <Field
                        name="diffPostal"
                        placeholder="Appartment,suite,unit etc"
                        className="border text-sm rounded border-[#DDDDDD] py-2 px-3 focus:border focus:border-dotted focus:border-black outline-none"
                      ></Field>
                      <ErrorMessage
                        name="diffPostal"
                        component="div"
                        className="text-red-600 text-xs"
                      />{" "}
                    </div>
                  </div>
                ) : null}
                <p className="border-[0.10px] my-8 border-t-[#DDDDDD]"></p>
                <p className="text-xs font-medium mb-3">
                  Order notes (optional)
                </p>
                <textarea
                  className="w-full focus:border focus:border-dotted focus:border-black outline-none px-3 py-2 border text-sm rounded border-[#DDDDDD]"
                  placeholder="Note about your order,e.g . special notes for delivery."
                  rows={3}
                ></textarea>
              </div>
            </div>
            <div className="flex-[1_1_45%] tablet:mt-6 ">
              <div className="border-[1.9px] w-full py-7 px-6  max-w-[80%] border-[#DDDDDD] smallTablet:!max-w-full">
                <p className="text-lg font-semibold">Your order</p>
                <div className="flex my-6 justify-between w-full">
                  <p className="text-sm text-[#4B4F58] font-medium">Product</p>
                  <p className="text-sm text-[#4B4F58] font-bold">Subtotal</p>
                </div>
                <p className="border-[0.10px] my-2 border-t-[#DDDDDD]"></p>
                <div
                  className={clsx(
                    cartLoading && "min-h-[20vh] flex justify-center my-auto"
                  )}
                >
                  {cartLoading && <div className="Loader" />}

                  {cartData?.data?.products?.map(
                    (data) =>
                      data?.quantity > 0 && (
                        <div className="flex my-6 justify-between w-full">
                          <p className="text-sm w-1/2 text-[#4B4F58] font-medium">
                            {data?.name}
                          </p>
                          <p className="text- text-[#4B4F58] text-end w-1/2 font-medium">
                            ${data?.subtotal}
                          </p>
                          <p className="border-[0.10px] my-2 border-t-[#DDDDDD]"></p>
                        </div>
                      )
                  )}
                </div>
                <div className="my-4 grid grid-cols-1 gap-y-5 w-full justify-between">
                  <div className="flex items-center gap-x-3">
                    <input
                      type="checkbox"
                      name="option1"
                      className="w-[20px] h-[25px]"
                      checked={shippingValue.option === "option1"}
                      onClick={() => handleCheckboxChange("option1")}
                    />
                    <label>Store Pickup: Free</label>
                  </div>
                  <div className="flex items-center gap-x-3">
                    {/* <div className="w-[10%]"> */}
                    <input
                      type="checkbox"
                      name="option2"
                      className="w-[20px] h-[25px]"
                      checked={shippingValue.option === "option2"}
                      onClick={() => handleCheckboxChange("option2")}
                    />
                    {/* </div> */}
                    <label>
                      Bearer Delivery (Fixed Rate, Close Proximity): $50.00
                    </label>
                  </div>
                  <div className="flex items-center gap-x-3">
                    <div className="w-[10%] smallTablet:w-[3%]">
                      <input
                        type="checkbox"
                        name="option3"
                        className="w-[20px] h-[25px]"
                        checked={shippingValue.option === "option3"}
                        onClick={() => handleCheckboxChange("option3")}
                      />
                    </div>
                    <label>
                      {" "}
                      Local Courier Pickup (COD, Rate Determined by Courier):$0
                    </label>
                  </div>
                  <div className="flex items-center gap-x-3">
                    <div className="w-[10%] smallTablet:w-[3%]">
                      <input
                        type="checkbox"
                        name="option4"
                        className="w-[20px] h-[25px]"
                        checked={shippingValue.option === "option4"}
                        onClick={() => handleCheckboxChange("option4")}
                      />
                    </div>
                    <label>
                      {" "}
                      International Delivery (Fixed Rate, Estimated, Subject to
                      Change): $150.00
                    </label>
                  </div>
                </div>
                <div className="flex my-6 justify-between w-full">
                  <p className="text-sm w-1/2 text-[#4B4F58] font-medium">
                    Total
                  </p>
                  <p className="text- text-[#4B4F58] text-end w-1/2 font-medium">
                    {
                      (total =
                        "$" +
                        cartData?.data?.products?.reduce((acc, data) => {
                          if (data?.quantity > 0) {
                            switch (shippingValue?.option) {
                              case "option2":
                                shippingCost = 50;
                                break;
                              case "option4":
                                shippingCost = 150;
                                break;
                              default:
                                shippingCost = 0;
                                break;
                            }

                            acc += data?.subtotal + shippingCost;
                          }
                          return acc;
                        }, 0))
                    }
                  </p>
                </div>
                <div className="flex gap-x-2">
                  <p className="text-[#545058]">Make payment using</p>
                  <img
                    className="object-cover"
                    src="https://www.technogeniousja.com/wp-content/plugins/woo-mpgs/assets/images/mastercard.png"
                  />
                </div>
                <div className="my-3 w-11/12 bg-[#EFEFEF] py-3 px-3  text-xs text-[#545058]">
                  Use Your MasterCard or Visa Card to make the payment
                </div>
                <p className="my-4 text-sm text-[#545058]">
                  Your personal data will be used to process your order, support
                  your experience throughout this website, and for other
                  purposes described in our{" "}
                  <Link to="/privacy-policy">
                    <span className="text-sm text-[#E02424] cursor-pointer">
                      privacy policy
                    </span>
                  </Link>
                </p>
                <div className="flex gap-x-3 items-center">
                  <input
                    onChange={() => setIsTermsChecked(!isTermsChecked)}
                    className="w-[20px] h-[25px]"
                    type="checkbox"
                  />
                  <p className="text-[13px] font-bold ">
                    {" "}
                    I have read and agree to the website
                    <span
                      onClick={() => setOpenPrivacyModal(true)}
                      className="text-[#e02424] cursor-pointer"
                    >
                      {" "}
                      terms and conditions *{" "}
                    </span>
                  </p>
                </div>
                <button
                  onClick={() => setButton(true)}
                  type="submit"
                  disabled={!isTermsChecked || !shippingValue?.option}
                  className="my-3 w-1/2 rounded-[30px] px-5 py-[10px] text-sm font-bold text-white bg-[#0170B9] disabled:cursor-not-allowed disabled:bg-slate-400"
                >
                  {apiLoading ? (
                    <div className="buttonLoader"></div>
                  ) : (
                    "Place order"
                  )}
                </button>

                <PrivacyModal
                  showDialog={openPrivacyModal}
                  onCloseDialog={() => setOpenPrivacyModal(!openPrivacyModal)}
                ></PrivacyModal>
              </div>
            </div>
          </Form>
          <Elements stripe={stripePromise}>
            {!apiLoading ? (
              <PaymentModal
                data={data}
                showDialog={openModal}
                onCloseDialog={() => setOpenModal(false)}
              />
            ) : null}
          </Elements>
        </>
      </Formik>
    </div>
  );
};

export default Checkout;
