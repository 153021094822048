import axios from "axios";
import React, { useState } from "react";
import { QueryClient, useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import { UserInfo, cartItems } from "./atoms/atoms";
import Toast from "./Toast/toast";
import * as Fa from "react-icons/fa";
import { FaCheck } from "react-icons/fa6";

interface ProductProps {
  title: string;
  price: any;
  link: string;
  image: string;
  ID: string;
  option: any;
}

const ShopProduct: React.FC<ProductProps> = ({
  title,
  price,
  link,
  image,
  ID,
  option,
}) => {
  const navigate = useNavigate();
  const userInfo = useRecoilValue(UserInfo);
  const queryClient = useQueryClient();
  const [cart, setCart] = useRecoilState(cartItems);
  const [toast, setToast] = useState({ type: "", message: "" });
  const [quantity, setQuantity] = useState(1);
  const addToCart = async () => {
    // if (userInfo) {
    //   const { data: response } = await axios.patch(
    //     `http://localhost:4000/cart/add-product/${ID}`,
    //     {},
    //     {
    //       headers: {
    //         Authorization: `Bearer ${userInfo?.access_token}`, // Include the Authorization header with the token
    //       },
    //     }
    //   );
    //   return response.data;
    // } else {
    const existingItem = cart?.find((item) => item?.id === ID);

    if (existingItem) {
      // If exists, update quantity and price
      const updatedCart = cart.map((item) => {
        if (item.id === ID) {
          return {
            ...item,
            quantity: item.quantity + quantity,
            price: item.price,
            subtotal: (item.quantity + quantity) * price,
            Image: image,
            name: title,

            // Adjust price calculation as needed
          };
        }
        return item;
      });

      setCart(updatedCart);
    } else {
      setCart([
        ...cart,
        {
          id: ID,
          _id: ID,
          quantity: quantity,
          price: price,
          subtotal: quantity * price,
          Image: image,
          name: title,
        },
      ]);
    }
  };

  const { mutate, isLoading, reset } = useMutation(addToCart, {
    onSuccess: (data) => {
      setToast({ type: "Sucess", message: "Add to cart sucessfully" });
      queryClient.invalidateQueries("fetch-cart");
    },
    onError: (errors: any) => {
      const error = errors as any;
      setToast({
        type: "Error",
        message: error?.response?.data?.message || "Something went wrong",
      });
    },
  });

  const handleClick = () => {
    // Navigate to the detailed page with state
    navigate(`/product/${encodeURIComponent(ID)}`);
  };

  // const AddToCart = () => {
  //   mutate();
  //   reset();
  // };
  return (
    <div className="w-[191px] h-fit flex justify-center items-center flex-col mobile:w-full">
      {/* <Link to={`/product/${encodeURIComponent(title)}`}> */}
      {toast.type && (
        <Toast
          type={toast.type as "Sucess" | "Error"}
          message={toast.message}
        />
      )}
      <figure
        className="w-44 h-52 bg-white mb-2 mobile:w-full cursor-pointer mobile:h-[20vh]"
        onClick={handleClick}
      >
        <img className="w-full h-full object-contain" src={image} alt={title} />
      </figure>
      {/* </Link> */}

      {/* <Link to={link}> */}
      <p
        className="text-[#3A3A3A] text-[15px] cursor-pointer text-center"
        onClick={handleClick}
      >
        {title}
      </p>
      {/* </Link> */}
      <p className="font-bold text-[#4B4F58] text-center text-sm my-2">
        ${price}
      </p>

      {option.length > 0 ? (
        <button
          onClick={handleClick}
          className="capitalize rounded-full px-5  text-base font-bold text-center py-2 bg-[#0170b9] text-white duration-300 transition-all hover:bg-gray-700"
        >
          Select Option
        </button>
      ) : (
        <button
          onClick={addToCart}
          className="capitalize rounded-full px-5 text-base font-bold text-center py-2 bg-[#0170b9] text-white duration-300 transition-all hover:bg-gray-700"
        >
          {option.length <= 0 && cart?.find((data) => data.id === ID) ? (
            <span className="flex items-center gap-x-3">
              Add to cart <FaCheck className="text-lg font-bold" />
            </span>
          ) : (
            <span>Add to cart</span>
          )}
        </button>
      )}
    </div>
  );
};

export default ShopProduct;
