import React from "react";
import { ShopProduct } from "./index";

const ShopProducts = ({ data }: any) => {
  return (
    <div className="flex flex-wrap w-full gap-[29px] items-center  justify-start smallTablet:justify-center">
      {data?.length > 0 &&
        data?.map((product: any, index: number) => (
          <ShopProduct
            key={product._id}
            title={product.name}
            price={product.price}
            link={product.link}
            image={product.image}
            ID={product._id}
            option={product.option}
          />
        ))}
    </div>
  );
};

export default ShopProducts;
