import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { FaEnvelope } from "react-icons/fa6";
import axios from "axios";
import { useMutation } from "react-query";

interface SimpleDialogProps {
  showDialog: boolean;
  onCloseDialog: () => void;
  title?: string;
  width?: string;
  height?: string;
  isLoading?: boolean;
  className?: string;
  showCloseIcon?: boolean;
}

const ForgotModal = ({
  showDialog,
  onCloseDialog,
  showCloseIcon,
  title,
  width,
  height,
  className,
}: SimpleDialogProps) => {
  const [step, setStep] = useState<"email" | "token">("email"); // State to manage the current step
  const [email, setEmail] = useState<string>("");
  const [error, setError] = useState("");
  const [message, setMessage] = useState(false);

  const overlayStyle: React.CSSProperties = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(28, 40, 64, 0.55)",
    zIndex: 49,
    display: showDialog ? "block" : "none",
  };

  // Validation schema for the email form
  const EmailSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Required*"),
  });

  // Validation schema for the token and password form
  const TokenSchema = Yup.object().shape({
    token: Yup.string().required("Required*"),
    password: Yup.string()
      .min(6, "Password must be at least 6 characters")
      .required("Required*"),
  });

  // Function to handle the forgot password request
  const forgotPassword = async (data: any) => {
    const response = await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/forgot-password`,
      JSON.stringify(data),
      {
        headers: {
          "Content-Type": "application/json",
          accept: "*/*",
        },
      }
    );
    return response.data;
  };

  // Function to handle the reset password request
  const resetPassword = async (data: any) => {
    const response = await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/reset-password/${data.token}`,
      JSON.stringify({ password: data.password }),
      {
        headers: {
          "Content-Type": "application/json",
          accept: "*/*",
        },
      }
    );
    return response.data;
  };

  // useMutation hook for the forgot password request
  const { mutate: sendEmail, isLoading: emailLoading } = useMutation(
    forgotPassword,
    {
      onSuccess: (data) => {
        // Proceed to the next step if email is sent successfully
        setStep("token");
        setMessage(true);
      },
      onError: (error: any) => {
        const errors = error as any;
        console.error("Error sending email", error);
        setError(errors?.response?.data?.message);
      },
    }
  );

  // useMutation hook for the reset password request
  const { mutate: changePassword, isLoading: passwordLoading } = useMutation(
    resetPassword,
    {
      onSuccess: (data) => {
        // Handle successful password reset
        onCloseDialog(); // Close the dialog on success
      },
      onError: (error: any) => {
        const errors = error as any;

        console.error("Error resetting password", error);
        setError(errors?.response?.data?.message);
      },
    }
  );
  console.log("err", error);
  return (
    <div>
      <div style={overlayStyle} onClick={onCloseDialog} />
      {showDialog && (
        <div
          className={`fixed p-4 z-50 min-w-[750px] h-auto max-h-[95vh] overflow-y-auto rounded top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 bg-white ${className} overflow-hidden smallTablet:!w-full smallTablet:!min-w-full`}
          style={{
            boxShadow: "5px 5px 10px 12px rgba(0,2,5,.2)",
          }}
        >
          <p
            onClick={onCloseDialog}
            className="absolute right-0 cursor-pointer mr-4 text-2xl font-bold"
          >
            x
          </p>
          {step === "email" ? (
            <Formik
              initialValues={{
                email: "",
              }}
              validationSchema={EmailSchema}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                setEmail(values.email); // Save the email to state
                sendEmail(values); // Call the mutation to send email
                resetForm({});
                setSubmitting(false);
              }}
            >
              {({ isSubmitting }) => (
                <Form className="mt-8 flex flex-col justify-start">
                  <div className="flex mx-14 gap-x-8 my-3 items-center">
                    <div
                      style={{
                        fontFamily: "DM Sans, sans-serif",
                      }}
                      className="flex w-full flex-col gap-y-4 space-y-1 font-roboto text-sm"
                    >
                      <h1 className="text-4xl font-bold my-4">
                        Find Your Account
                      </h1>

                      <div className="flex flex-col gap-y-1">
                        <div className="flex gap-x-6 items-center w-full border-b-[1.5px] border-black">
                          <FaEnvelope className="text-xl text-black" />
                          <Field
                            type="text"
                            name="email"
                            placeholder="Your email address"
                            className="w-full font-medium font-roboto text-base p-2 rounded-md outline-none focus:border-[#3d24249a]"
                          />
                        </div>
                        <ErrorMessage
                          name="email"
                          component="div"
                          className="text-red-600"
                        />
                        {error ? <p className="text-red-600">{error}</p> : null}
                      </div>

                      <div>
                        <button
                          type="submit"
                          className="bg-[#AD3511] w-1/2 rounded-md p-3 text-white font-roboto my-3"
                          disabled={isSubmitting || emailLoading}
                        >
                          {emailLoading ? "Sending..." : "Search"}
                        </button>
                      </div>
                    </div>

                    <div className="w-3/4 medium:hidden ">
                      <img
                        id="icon-zoom"
                        className="h-[26vh] w-full"
                        src="https://technostorage.nyc3.digitaloceanspaces.com/uploads/2021/06/Poker-Slots-03-03.png"
                        alt="poker slots"
                      />
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          ) : (
            <Formik
              initialValues={{
                token: "",
                password: "",
              }}
              validationSchema={TokenSchema}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                changePassword(values); // Call the mutation to reset password
                resetForm({});
                setSubmitting(false);
              }}
            >
              {({ isSubmitting }) => (
                <Form className="mt-8 flex flex-col justify-start">
                  <div className="flex mx-14 gap-x-8 my-3 items-center">
                    <div
                      style={{
                        fontFamily: "DM Sans, sans-serif",
                      }}
                      className="flex w-full flex-col gap-y-4 space-y-1 font-roboto text-sm"
                    >
                      <h1 className="text-4xl font-bold my-4">
                        Reset Your Password
                      </h1>
                      <p className="text-red-600 font-bold">
                        We have sent a pin to your email address to help you
                        reset your password.Please check your inbox and follow
                        the instructions provided
                      </p>
                      <div className="flex flex-col gap-y-1">
                        <div className="flex gap-x-6 items-center w-full border-b-[1.5px] border-black">
                          <Field
                            type="text"
                            name="token"
                            placeholder="Enter your token"
                            className="w-full font-medium font-roboto text-base p-2 rounded-md outline-none focus:border-[#3d24249a]"
                          />
                        </div>
                        <ErrorMessage
                          name="token"
                          component="div"
                          className="text-red-600"
                        />
                      </div>

                      <div className="flex flex-col gap-y-1 mt-4">
                        <div className="flex gap-x-6 items-center w-full border-b-[1.5px] border-black">
                          <Field
                            type="password"
                            name="password"
                            placeholder="Enter your new password"
                            className="w-full font-medium font-roboto text-base p-2 rounded-md outline-none focus:border-[#3d24249a]"
                          />
                        </div>
                        <ErrorMessage
                          name="password"
                          component="div"
                          className="text-red-600"
                        />
                      </div>

                      <div>
                        <button
                          type="submit"
                          className="bg-[#AD3511] w-1/2 rounded-md p-3 text-white font-roboto my-3"
                          disabled={isSubmitting || passwordLoading}
                        >
                          {passwordLoading ? "Changing..." : "Change Password"}
                        </button>
                      </div>
                    </div>

                    <div className="w-3/4 medium:hidden ">
                      <img
                        id="icon-zoom"
                        className="h-[26vh] w-full"
                        src="https://technostorage.nyc3.digitaloceanspaces.com/uploads/2021/06/Poker-Slots-03-03.png"
                        alt="poker slots"
                      />
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          )}
        </div>
      )}
    </div>
  );
};

export default ForgotModal;
