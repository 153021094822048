import gameBox from "../src/assets/gameBox.jpeg";
import { FaTwitter, FaFacebookF, FaYoutube } from "react-icons/fa6";

export const gamingData = [
  {
    img: "https://www.technogeniousja.com/wp-content/uploads/2023/04/26D129FF-BAD0-4CC8-B834-54102D333403-600x600.png",
    title: "Pot O Gold In Metal Cabinet- (Contact us for pricing).",
  },
  {
    img: "https://www.technogeniousja.com/wp-content/uploads/2023/04/93C93220-0509-4A9E-8AC1-25FE125E23F9-600x600.png",
    title: "Pot O Gold In Metal Cabinet- (Contact us for pricing).",
  },
  {
    img: "https://www.technogeniousja.com/wp-content/uploads/2022/01/IMG-3011-1.png",
    title: "Pot O Gold In Metal Cabinet- (Contact us for pricing).",
  },
  {
    img: "https://www.technogeniousja.com/wp-content/uploads/2023/04/American-Roulette-Machine-1-1-600x600.png",
    title: "Pot O Gold In Metal Cabinet- (Contact us for pricing).",
  },
  {
    img: "https://www.technogeniousja.com/wp-content/uploads/2021/07/96590-1-600x600.jpg",
    title: "Pot O Gold In Metal Cabinet- (Contact us for pricing).",
  },
];

//  footer constants start

export const links = [
  {
    icon: FaTwitter,
    url: "https://www.twitter.com",
  },
  {
    icon: FaFacebookF,
    url: "https://www.facebook.com",
  },
  {
    icon: FaYoutube,
    url: "https://www.youtube.com",
  },
];

export const company = [
  {
    title: "About Us",
    link: "/about",
  },
  {
    title: "Privacy Policy",
    link: "/privacy-policy",
  },
  {
    title: "Terms & Conditions",
    link: "/termscondition",
  },
  {
    title: "Disclaimer",
    link: "/disclaimer",
  },
  {
    title: "Warranties",
    link: "/warranties",
  },
];

export const QuickLinks = [
  {
    title: "Home",
    link: "/",
  },
  {
    title: "Products",
    link: "/products",
  },
  {
    title: "Services",
    link: "/services",
  },
  {
    title: "FAQs",
    link: "/FAQs",
  },
];

//  footer constants end......

//  footer constants end......

// game Board start...........

export const gamingBoard = [
  {
    title: "88 Fortune",
    image:
      "https://www.technogeniousja.com/wp-content/uploads…023/04/88-Fortunes-Screens3-300x300-1-450x450.png",
    price: "867.00",
  },
  {
    title: "American Roulette Linking VER",
    image:
      "https://www.technogeniousja.com/wp-content/uploads/2023/04/brand-1-150x150-1-300x300.jpg",
    price: "732.00",
  },
  {
    title: "Pearl of the Caribbean Linking VER",
    Image:
      "	https://www.technogeniousja.com/wp-content/uploads…sApp-Image-2024-03-28-at-11.05.03-AM-300x300.jpeg",
    price: "790.00",
  },
  {
    title: "American Roulette Single VER",
    Image:
      "https://www.technogeniousja.com/wp-content/uploads/2021/10/ARS-Single.jpg",
    price: "746.00",
  },
  {
    title: "HI/LO Poker",
    image:
      "https://www.technogeniousja.com/wp-content/uploads/2023/04/Hi-Lo-Poker-Board-450x450.png",
    price: "166.67",
  },
];

//  related  product

export const relatedProducts = [
  {
    img: "https://www.technogeniousja.com/wp-content/uploads…sApp-Image-2024-03-28-at-11.05.03-AM-300x300.jpg",
    title: "Pearl of the Caribbean Linking VER",
    link: "/",
    price: "USD$790.00",
  },
  {
    img: "https://www.technogeniousja.com/wp-content/uploads/2023/04/POT-O-GOLD-PIC-3-300x300-1-450x450.jpg",
    title: "Pot O’ Gold",
    link: "/",
    price: "USD$954.00",
  },
  {
    img: "https://www.technogeniousja.com/wp-content/uploads/2021/10/ARS-Single.jpg",
    title: "American Roulette Single VER",
    link: "/",
    price: "USD$746.67",
  },
  {
    img: "https://www.technogeniousja.com/wp-content/uploads…023/04/88-Fortunes-Screens3-300x300-1-450x450.png",
    title: "88 Fortune",
    link: "/",
    price: "USD$867.00",
  },
];
