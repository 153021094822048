import React, { useEffect } from "react";
import { Link } from "react-router-dom";

const TermsAndCondition = () => {
  useEffect(() => {
    window.scrollTo({
      top: 20,
      behavior: "auto",
    });
  }, []);

  return (
    <div className="max-w-[1200px] w-full mx-auto py-[50px]">
      <div className=" px-[40px] mobile:px-6 break-all">
        <h2
          className="text-center mt-[15px] mb-[50px] text-[35px]"
          style={{
            fontFamily: "Montserrat, Sans-serif",
            fontWeight: "600",
            lineHeight: "1.4em",
          }}
        >
          Technogeniousja.com Terms & Conditions
        </h2>
        <p
          className="mb-[24px] text-[14px]"
          style={{
            fontFamily: "Montserrat, Sans-serif",
          }}
        >
          Last modified: 07-29-2024
        </p>
        <ol
          className="mb-[1.5em]"
          style={{
            listStyle: "decimal",
          }}
        >
          <li
            className="text-[14px] mb-[1.5em]"
            style={{
              fontFamily: "Montserrat, Sans-serif",
            }}
          >
            <strong>TERMS OF USE</strong>
          </li>

          <li
            className=" text-[14px] list-none"
            style={{
              fontFamily: "Montserrat, Sans-serif",
            }}
          >
            <strong>Acceptance of the Terms of Use</strong>
          </li>
        </ol>

        <p
          className="mb-[24px] text-[14px]"
          style={{
            fontFamily: "Montserrat, Sans-serif",
          }}
        >
          These terms of use are entered into by and between you and
          Technogenious Solutions Ltd (“Company”, “we” or “us”). The following
          terms and conditions, together with any documents they expressly
          incorporated by reference (collectively, these “Terms of Use”), govern
          your access to and use of
          <a
            //  href='/'
            style={{ color: "blue", textDecoration: "none", cursor: "pointer" }}
            target="_blank"
            rel="noopener noreferrer"
          >
            {" "}
            www.technogeniousja.com
          </a>
          , including any content, functionality and services offered on or
          through {" "}
          <a
            // href='/'
            style={{ color: "blue", textDecoration: "none", cursor: "pointer" }}
            target="_blank"
            rel="noopener noreferrer"
          >
            technogeniousja.com
          </a>
          (the “Website”), whether as a guest or a registered user.
        </p>
        <p
          className="mb-[24px] text-[14px]"
          style={{
            fontFamily: "Montserrat, Sans-serif",
          }}
        >
          Please read the Terms of Use carefully before you start to use the
          Website. {" "}
          <strong>
            By using the Website or by clicking to accept or agree to the Terms
            of Use when this option is made available to you, you accept and
            agree to be bound and abide by these Terms of Use and our Privacy
            Policy, found at technogeniousja.com/privacy-policy/. 
          </strong>{" "}
          and our If you do not want to agree to these Terms of Use or the
          Privacy Policy, you must not access or use the Website.
        </p>
        <p
          className="mb-[24px] text-[14px]"
          style={{
            fontFamily: "Montserrat, Sans-serif",
          }}
        >
          This Website is offered and available to users who are 18 years of age
          or older. If you are under the age of 18, you shall not use this
          website without the supervision and consent of a parent or guardian.
          By using this Website, you represent and warrant that you meet all of
          the foregoing eligibility requirements. If you do not meet all of
          these requirements, you shall not access or use the Website.
        </p>
        <p
          className="mb-[24px] text-[14px]"
          style={{
            fontFamily: "Montserrat, Sans-serif",
          }}
        >
          <strong>
            Under 18 years of age that a parent a guardian has consented to use
          </strong>
        </p>
        <ul
          className="ml-[3em] mb-[1.5em]"
          style={{
            listStyle: "outside",
          }}
        >
          <li
            className="text-[14px]"
            style={{
              fontFamily: "Montserrat, Sans-serif",
            }}
          >
            <strong>Changes to the Terms of Use</strong> We may revise and
            update these Terms of Use from time to time in our sole discretion.
            All changes are effective immediately when we post them, and apply
            to all access to and use of the Website there after. Your continued
            use of the Website following the posting of revised Terms of Use
            means that you accept and agree to the changes. You are expected to
            check this page from time to time so you are aware of any changes,
            as they are binding on you
          </li>
          <li
            className="text-[14px]"
            style={{
              fontFamily: "Montserrat, Sans-serif",
            }}
          >
            <strong>Accessing the Website and Account Security </strong>We
            reserve the right to withdraw or amend this Website, and any service
            or material we provide on the Website, in our sole discretion
            without notice. We will not be liable if for any reason all or any
            part of the Website is unavailable at any time or for any period.
            From time to time, we may restrict access to some parts of the
            Website, or the entire Website, to users, including registered
            users. To access the Website or some of the resources it offers, you
            may be asked to provide certain registration details or other
            information. It is a condition of your use of the Website that all
            the information you provide on the Website is correct, current and
            complete. You agree that all information you provide to register
            with this Website or otherwise, including but not limited to through
            the use of any interactive features on the Website, is governed by
            our Privacy Policy technogeniousja.com, and you consent to all
            actions we take with respect to your information consistent with our
            Privacy Policy. If you choose, or are provided with, a user name,
            password or any other piece of information as part of our security
            procedures, you must treat such information as confidential, and you
            must not disclose it to any other person or entity. You also
            acknowledge that your account is personal to you and agree not to
            provide any other person with access to this Website or portions of
            it using your username, password or other security information. You
            agree to notify us immediately of any unauthorized access to or use
            of your username or password or any other breach of security. You
            also agree to ensure that you exit from your account at the end of
            each session. You should use particular caution when accessing your
            account from a public or shared computer so that others are not able
            to view or record your password or other personal information.
          </li>
        </ul>
        <p
          className="mb-[24px] text-[14px]"
          style={{
            fontFamily: "Montserrat, Sans-serif",
          }}
        >
          We have the right to disable any username, password or other
          identifier, whether chosen by you or provided by us, at any time in
          our sole discretion for any or no reason, including if, in our
          opinion, you have violated any provision of these Terms of Use.
        </p>
        <ul
          className="ml-[3em] mb-[1.5em]"
          style={{
            listStyle: "outside",
          }}
        >
          <li
            className="text-[14px] mb-[1.5em]"
            style={{
              fontFamily: "Montserrat, Sans-serif",
            }}
          >
            <strong> Intellectual Property Rights </strong> The Website and its
            entire contents, features and functionality (including but not
            limited to all information, software, text, displays, images, video
            and audio, and the design, selection and arrangement thereof), are
            owned by the Company, its licensors or other providers of such
            material and are protected by Jamaica and international copyright,
            trademark, patent, trade secret and other intellectual property or
            proprietary rights laws. These Terms of Use permit you to use the
            Website for your personal, non-commercial use only. You must not
            reproduce, distribute, modify, create derivative works of, publicly
            display, publicly perform, republish, download, store or transmit
            any of the material on our Website, except as follows:
          </li>
          <ul
            className="ml-[3em] mb-[1.5em]"
            style={{
              listStyle: "outside",
            }}
          >
            <li
              className="text-[14px] mb-[1.5em]"
              style={{
                fontFamily: "Montserrat, Sans-serif",
              }}
            >
              Your computer may temporarily store copies of such materials in
              RAM incidental to your accessing and viewing those materials.
            </li>
            <li
              className="text-[14px] mb-[1.5em]"
              style={{
                fontFamily: "Montserrat, Sans-serif",
              }}
            >
              You may store files that are automatically cached by your Web
              browser for display enhancement purposes.
            </li>
            <li
              className="text-[14px] mb-[1.5em]"
              style={{
                fontFamily: "Montserrat, Sans-serif",
              }}
            >
              You may print or download one copy of a reasonable number of pages
              of the Website for your own personal, non-commercial use and not
              for further reproduction, publication or distribution.
            </li>
            <li
              className="text-[14px] mb-[1.5em]"
              style={{
                fontFamily: "Montserrat, Sans-serif",
              }}
            >
              If we provide desktop, mobile or other applications for download,
              you may download a single copy to your computer or mobile device
              solely for your own personal, non-commercial use, provided you
              agree to be bound by our end user license agreement for such
              applications.
            </li>
          </ul>
          <p
            className="mb-[24px] text-[14px]"
            style={{
              fontFamily: "Montserrat, Sans-serif",
            }}
          >
            You shall not:
          </p>

          <li
            className="text-[14px]"
            style={{
              fontFamily: "Montserrat, Sans-serif",
            }}
          >
            Modify copies of any materials from this site.
          </li>
          <li
            className="text-[14px]"
            style={{
              fontFamily: "Montserrat, Sans-serif",
            }}
          >
            Delete or alter any copyright, trademark or other proprietary rights
            notices from copies of materials from this site.
          </li>
        </ul>
        <p
          className="mb-[24px] text-[14px]"
          style={{
            fontFamily: "Montserrat, Sans-serif",
          }}
        >
          You shall not access or use for any commercial purposes any part of
          the Website or any services or materials available through the
          Website.
        </p>
        <p
          className="mb-[24px] text-[14px]"
          style={{
            fontFamily: "Montserrat, Sans-serif",
          }}
        >
          If you print, copy, modify, download or otherwise use or provide any
          other person with access to any part of the Website in breach of the
          Terms of Use, your right to use the Website will cease immediately and
          you must, at our option, return or destroy any copies of the materials
          you have made. No right, title or interest in or to the Website or any
          content on the Website is transferred to you, and all rights not
          expressly granted are reserved by the Company. Any use of the Website
          not expressly permitted by these Terms of Use is a breach of these
          Terms of Use and may violate copyright, trademark and other laws.
        </p>

        <ul
          className="ml-[3em] mb-[1.5em]"
          style={{
            listStyle: "outside",
          }}
        >
          <li
            className="text-[14px] mb-[1.5em]"
            style={{
              fontFamily: "Montserrat, Sans-serif",
            }}
          >
            <strong> Trademarks </strong>
            <span className="block">
              The Company name, the terms 8Line Supply, and all related names,
              logos, product and service names, designs and slogans are
              trademarks of the Company or its affiliates or licensors. You must
              not use such marks without the prior written permission of the
              Company. All other names, logos, product and service names,
              designs and slogans on this Website are the trademarks of their
              respective owners.
            </span>
          </li>
          <li
            className="text-[14px] mb-[1.5em]"
            style={{
              fontFamily: "Montserrat, Sans-serif",
            }}
          >
            <strong> Prohibited Uses </strong>
            <span className="block">
              You may use the Website only for lawful purposes and in accordance
              with these Terms of Use. You agree not to use the Website:
            </span>
          </li>
          <ul
            className="ml-[3em] mb-[1.5em]"
            style={{
              listStyle: "outside",
            }}
          >
            <li
              className="text-[14px] mb-[1.5em]"
              style={{
                fontFamily: "Montserrat, Sans-serif",
              }}
            >
              In any way that violates any applicable federal, state, local or
              international law or regulation (including, without limitation,
              any laws regarding the export of data or software to and from the
              US or other countries).
            </li>
            <li
              className="text-[14px] mb-[1.5em]"
              style={{
                fontFamily: "Montserrat, Sans-serif",
              }}
            >
              For the purpose of exploiting, harming or attempting to exploit or
              harm minors in any way by exposing them to inappropriate content,
              asking for personally identifiable information or otherwise.
            </li>
            <li
              className="text-[14px] mb-[1.5em]"
              style={{
                fontFamily: "Montserrat, Sans-serif",
              }}
            >
              To transmit, or procure the sending of, any advertising or
              promotional material, including any “junk mail”, “chain letter” or
              “spam” or any other similar solicitation.
            </li>
            <li
              className="text-[14px] mb-[1.5em]"
              style={{
                fontFamily: "Montserrat, Sans-serif",
              }}
            >
              To impersonate or attempt to impersonate the Company, a Company
              employee, another user or any other person or entity (including,
              without limitation, by using e-mail addresses associated with any
              of the foregoing).
            </li>
            <li
              className="text-[14px] mb-[1.5em]"
              style={{
                fontFamily: "Montserrat, Sans-serif",
              }}
            >
              To engage in any other conduct that restricts or inhibits anyone’s
              use or enjoyment of the Website, or which, as determined by us,
              may harm the Company or users of the Website or expose them to
              liability.
            </li>
          </ul>
          <p
            className="mb-[24px] text-[14px]"
            style={{
              fontFamily: "Montserrat, Sans-serif",
            }}
          >
            Additionally, you agree not to:
          </p>

          <li
            className="text-[14px]  mb-[1.5em]"
            style={{
              fontFamily: "Montserrat, Sans-serif",
            }}
          >
            Use the Website in any manner that could disable, overburden,
            damage, or impair the site or interfere with any other party’s use
            of the Website, including their ability to engage in real time
            activities through the Website.
          </li>
          <li
            className="text-[14px]  mb-[1.5em]"
            style={{
              fontFamily: "Montserrat, Sans-serif",
            }}
          >
            Use any robot, spider or other automatic device, process or means to
            access the Website for any purpose, including monitoring or copying
            any of the material on the Website.
          </li>
          <li
            className="text-[14px]  mb-[1.5em]"
            style={{
              fontFamily: "Montserrat, Sans-serif",
            }}
          >
            Use any manual process to monitor or copy any of the material on the
            Website or for any other unauthorized purpose without our prior
            written consent.
          </li>
          <li
            className="text-[14px]  mb-[1.5em]"
            style={{
              fontFamily: "Montserrat, Sans-serif",
            }}
          >
            Use any device, software or routine that interferes with the proper
            working of the Website.
          </li>
          <li
            className="text-[14px] mb-[1.5em]"
            style={{
              fontFamily: "Montserrat, Sans-serif",
            }}
          >
            Introduce any viruses, trojan horses, worms, logic bombs or other
            material which is malicious or technologically harmful.
          </li>
          <li
            className="text-[14px] mb-[1.5em]"
            style={{
              fontFamily: "Montserrat, Sans-serif",
            }}
          >
            Attempt to gain unauthorized access to, interfere with, damage or
            disrupt any parts of the Website, the server on which the Website is
            stored, or any server, computer or database connected to the
            Website.
          </li>
          <li
            className="text-[14px]  mb-[1.5em]"
            style={{
              fontFamily: "Montserrat, Sans-serif",
            }}
          >
            Attack the Website via a denial-of-service attack or a distributed
            denial-of-service attack.
          </li>
          <li
            className="text-[14px]  mb-[1.5em]"
            style={{
              fontFamily: "Montserrat, Sans-serif",
            }}
          >
            Otherwise attempt to interfere with the proper working of the
            Website.
          </li>
        </ul>

        <ul
          className="ml-[3em] mb-[1.5em]"
          style={{
            listStyle: "outside",
          }}
        >
          <li
            className="text-[14px] mb-[1.5em]"
            style={{
              fontFamily: "Montserrat, Sans-serif",
            }}
          >
            <strong> User Contributions </strong>
            <span className="block">
              {" "}
              The Website may contain message boards, chat rooms, personal web
              pages or profiles, forums, bulletin boards and other interactive
              features (collectively, “Interactive Services”) that allow users
              to post, submit, publish, display or transmit to other users or
              other persons (hereinafter, “post”) content or materials
              (collectively, “User Contributions”) on or through the Website.
              Any User Contribution you post to the Website will be considered
              non-confidential and non-proprietary. By providing any User
              Contribution on the Website, you grant us and our affiliates and
              service providers, and each of their and our licensees, successors
              and assigns the right to use, reproduce, modify, perform, display,
              distribute and otherwise disclose to third parties any such
              material for any purpose. You represent and warrant that:
            </span>
          </li>
          <ul
            className="ml-[3em] mb-[1.5em]"
            style={{
              listStyle: "outside",
            }}
          >
            <li
              className="text-[14px] mb-[1.5em]"
              style={{
                fontFamily: "Montserrat, Sans-serif",
              }}
            >
              You own or control all rights in and to the User Contributions and
              have the right to grant the license granted above to us and our
              affiliates and service providers, and each of their and our
              licensees, successors and assigns.
            </li>
            <li
              className="text-[14px] mb-[1.5em]"
              style={{
                fontFamily: "Montserrat, Sans-serif",
              }}
            >
              All of your User Contributions do and will comply with these Terms
              of Use.
            </li>
          </ul>
        </ul>
        <p
          className="mb-[24px] text-[14px]"
          style={{
            fontFamily: "Montserrat, Sans-serif",
          }}
        >
          You understand and acknowledge that you are responsible for any User
          Contributions you submit or contribute, and you, not the Company, have
          fully responsibility for such content, including its legality,
          reliability, accuracy and appropriateness.
        </p>

        <p
          className="mb-[24px] text-[14px]"
          style={{
            fontFamily: "Montserrat, Sans-serif",
          }}
        >
          We are not responsible, or liable to any third party, for the content
          or accuracy of any User Contributions posted by you or any other user
          of the Website.
        </p>

        <ul
          className="ml-[3em] mb-[1.5em]"
          style={{
            listStyle: "outside",
          }}
        >
          <li
            className="text-[14px] mb-[1.5em]"
            style={{
              fontFamily: "Montserrat, Sans-serif",
            }}
          >
            <strong> Monitoring and Enforcement; Termination </strong>
            <span className="block">We have the right to:</span>
          </li>
          <ul
            className="ml-[3em] mb-[1.5em]"
            style={{
              listStyle: "outside",
            }}
          >
            <li
              className="text-[14px] mb-[1.5em]"
              style={{
                fontFamily: "Montserrat, Sans-serif",
              }}
            >
              Remove or refuse to post any User Contributions for any or no
              reason in our sole discretion.
            </li>
            <li
              className="text-[14px] mb-[1.5em]"
              style={{
                fontFamily: "Montserrat, Sans-serif",
              }}
            >
              Take any action with respect to any User Contribution that we deem
              necessary or appropriate in our sole discretion, including if we
              believe that such User Contribution violates the Terms of Use,
              infringes any intellectual property right or other right of any
              person or entity, threatens the personal safety of users of the
              Website or the public or could create liability for the Company.
            </li>
            <li
              className="text-[14px] mb-[1.5em]"
              style={{
                fontFamily: "Montserrat, Sans-serif",
              }}
            >
              Disclose your identity or other information about you to any third
              party who claims that material posted by you violates their
              rights, including their intellectual property rights or their
              right to privacy.
            </li>
            <li
              className="text-[14px] mb-[1.5em]"
              style={{
                fontFamily: "Montserrat, Sans-serif",
              }}
            >
              Take appropriate legal action, including without limitation,
              referral to law enforcement, for any illegal or unauthorized use
              of the Website.
            </li>
            <li
              className="text-[14px] mb-[1.5em]"
              style={{
                fontFamily: "Montserrat, Sans-serif",
              }}
            >
              Terminate or suspend your access to all or part of the Website for
              any or no reason, including without limitation, any violation of
              these Terms of Use.
            </li>
          </ul>
        </ul>

        <p
          className="mb-[24px] text-[14px]"
          style={{
            fontFamily: "Montserrat, Sans-serif",
          }}
        >
          Without limiting the foregoing, we have the right to fully cooperate
          with any law enforcement authorities or court order requesting or
          directing us to disclose the identity or other information of anyone
          posting any materials on or through the Website. YOU WAIVE AND HOLD
          HARMLESS THE COMPANY AND ITS AFFILIATES, LICENSEES AND SERVICE
          PROVIDERS FROM ANY CLAIMS RESULTING FROM ANY ACTION TAKEN BY THE
          COMPANY OR ANY OF THE FOREGOING PARTIES DURING OR AS A RESULT OF ITS
          INVESTIGATIONS AND FROM ANY ACTIONS TAKEN AS A CONSEQUENCE OF
          INVESTIGATIONS BY EITHER THE COMPANY OR LAW ENFORCEMENT AUTHORITIES.
        </p>
        <p
          className="mb-[24px] text-[14px]"
          style={{
            fontFamily: "Montserrat, Sans-serif",
          }}
        >
          However, we do not undertake to review material before it is posted on
          the Website, and cannot ensure prompt removal of objectionable
          material after it has been posted. Accordingly, we assume no liability
          for any action or inaction regarding transmissions, communications or
          content provided by any user or third party. We have no liability or
          responsibility to anyone for performance or nonperformance of the
          activities described in this section.
        </p>

        <ul
          className="ml-[3em] mb-[1.5em]"
          style={{
            listStyle: "outside",
          }}
        >
          <li
            className="text-[14px] mb-[1.5em]"
            style={{
              fontFamily: "Montserrat, Sans-serif",
            }}
          >
            <strong>Content Standards</strong>
            <span className="block">
              These content standards apply to any and all User Contributions
              and use of Interactive Services. User Contributions must in their
              entirety comply with all applicable federal, state, local and
              international laws and regulations. Without limiting the
              foregoing, User Contributions must not:
            </span>
          </li>
          <ul
            className="ml-[3em] mb-[1.5em]"
            style={{
              listStyle: "outside",
            }}
          >
            <li
              className="text-[14px] mb-[1.5em]"
              style={{
                fontFamily: "Montserrat, Sans-serif",
              }}
            >
              Contain any material which is defamatory, obscene, indecent,
              abusive, offensive, harassing, violent, hateful, inflammatory or
              otherwise objectionable.
            </li>
            <li
              className="text-[14px] mb-[1.5em]"
              style={{
                fontFamily: "Montserrat, Sans-serif",
              }}
            >
              Promote sexually explicit or pornographic material, violence, or
              discrimination based on race, sex, religion, nationality,
              disability, sexual orientation or age.
            </li>
            <li
              className="text-[14px] mb-[1.5em]"
              style={{
                fontFamily: "Montserrat, Sans-serif",
              }}
            >
              Infringe any patent, trademark, trade secret, copyright or other
              intellectual property or other rights of any other person.
            </li>
            <li
              className="text-[14px] mb-[1.5em]"
              style={{
                fontFamily: "Montserrat, Sans-serif",
              }}
            >
              Violate the legal rights (including the rights of publicity and
              privacy) of others or contain any material that could give rise to
              any civil or criminal liability under applicable laws or
              regulations or that otherwise may be in conflict with these Terms
              of Use and our
              <Link
                to="/privacy-policy"
                style={{
                  color: "#ff0000",
                  textDecoration: "none",
                }}
              >
                {" "}
                Privacy Policy
              </Link>
              .
            </li>
            <li
              className="text-[14px] mb-[1.5em]"
              style={{
                fontFamily: "Montserrat, Sans-serif",
              }}
            >
              Be likely to deceive any person.
            </li>
            <li
              className="text-[14px] mb-[1.5em]"
              style={{
                fontFamily: "Montserrat, Sans-serif",
              }}
            >
              Promote any illegal activity, or advocate, promote or assist any
              unlawful act.
            </li>
            <li
              className="text-[14px] mb-[1.5em]"
              style={{
                fontFamily: "Montserrat, Sans-serif",
              }}
            >
              Cause annoyance, inconvenience or needless anxiety or be likely to
              upset, embarrass, alarm or annoy any other person.
            </li>
            <li
              className="text-[14px] mb-[1.5em]"
              style={{
                fontFamily: "Montserrat, Sans-serif",
              }}
            >
              Impersonate any person, or misrepresent your identity or
              affiliation with any person or organization.
            </li>
            <li
              className="text-[14px] mb-[1.5em]"
              style={{
                fontFamily: "Montserrat, Sans-serif",
              }}
            >
              Involve commercial activities or sales, such as contests,
              sweepstakes and other sales promotions, barter or advertising.
            </li>
            <li
              className="text-[14px] mb-[1.5em]"
              style={{
                fontFamily: "Montserrat, Sans-serif",
              }}
            >
              Give the impression that they emanate from or are endorsed by us
              or any other person or entity, if this is not the case.
            </li>
          </ul>
        </ul>

        <ul
          className="ml-[3em] mb-[1.5em]"
          style={{
            listStyle: "outside",
          }}
        >
          <li
            className="text-[14px] mb-[1.5em]"
            style={{
              fontFamily: "Montserrat, Sans-serif",
            }}
          >
            <strong>Reliance on Information Posted</strong>
            <span className="block">
              The information presented on or through the Website is made
              available solely for general information purposes. We do not
              warrant the accuracy, completeness or usefulness of this
              information. Any reliance you place on such information is
              strictly at your own risk. We disclaim all liability and
              responsibility arising from any reliance placed on such materials
              by you or any other visitor to the Website, or by anyone who may
              be informed of any of its contents. This Website may include
              content provided by third parties, including materials provided by
              other users, bloggers and third-party licensors, syndicators,
              aggregators and/or reporting services. All statements and/or
              opinions expressed in these materials, and all articles and
              responses to questions and other content, other than the content
              provided by the Company, are solely the opinions and the
              responsibility of the person or entity providing those materials.
              These materials do not necessarily reflect the opinion of the
              Company. We are not responsible, or liable to you or any third
              party, for the content or accuracy of any materials provided by
              any third parties.
            </span>
          </li>
          <li
            className="text-[14px] mb-[1.5em]"
            style={{
              fontFamily: "Montserrat, Sans-serif",
            }}
          >
            <strong>Changes to the Website</strong>
            <span className="block">
              We may update the content on this Website from time to time, but
              its content is not necessarily complete or up-to-date. Any of the
              material on the Website may be out of date at any given time, and
              we are under no obligation to update such material. If you have
              any questions regarding the content or products displayed on the
              Website, please contact the Company.{" "}
            </span>
          </li>
          <li
            className="text-[14px] mb-[1.5em]"
            style={{
              fontFamily: "Montserrat, Sans-serif",
            }}
          >
            <strong>
              Information About You and Your Visits to the Website{" "}
            </strong>
            <span className="block">
              All information we collect on this Website is subject to our{" "}
              <Link
                to="/privacy-policy"
                style={{
                  color: "#ff0000",
                  textDecoration: "none",
                }}
              >
                {" "}
                Privacy Policy
              </Link>
              . By using the Website, you consent to all actions taken by us
              with respect to your information in compliance with the Privacy
              Policy.{" "}
            </span>
          </li>
          <li
            className="text-[14px] mb-[1.5em]"
            style={{
              fontFamily: "Montserrat, Sans-serif",
            }}
          >
            <strong>
              Online Purchases and Other Terms and Conditions of Sale{" "}
            </strong>
            <span className="block">
              All purchases through our site or other transactions for the sale
              of goods or services formed through the Website or as a result of
              visits made by you are governed by our Terms of Sale
              technogeniousja.com/terms-and-conditions/, which are hereby
              incorporated into these Terms of Use. Additional terms and
              conditions may also apply to specific portions, services or
              features of the Website. All such additional terms and conditions
              are hereby incorporated by this reference into these Terms of Use.{" "}
            </span>
          </li>
          <li
            className="text-[14px] mb-[1.5em]"
            style={{
              fontFamily: "Montserrat, Sans-serif",
            }}
          >
            <strong>Links from the Website</strong>
            <span className="block">
              If the Website contains links to other websites and resources
              provided by third parties, these links are provided for your
              convenience only. This includes links contained in advertisements,
              including banner advertisements and sponsored links. We have no
              control over the contents of those sites or resources, and accept
              no responsibility for them or for any loss or damage that may
              arise from your use of them. If you decide to access any of the
              third party websites linked to this Website, you do so entirely at
              your own risk and subject to the terms and conditions of use for
              such websites.{" "}
            </span>
          </li>
          <li
            className="text-[14px] mb-[1.5em]"
            style={{
              fontFamily: "Montserrat, Sans-serif",
            }}
          >
            <strong>Geographic Restrictions</strong>
            <span className="block">
              The owner of the Website is based in the Kingston, Jamaica. We
              provide this Website specifically for use by persons located in
              Jamaica. We make no claims that the Website or any of its content
              is accessible or appropriate outside of Jamaica. Access to the
              Website may not be legal by certain persons or in certain
              countries. If you access the Website from outside Jamaica, you do
              so on your own initiative and are responsible for compliance with
              local laws and agree that you will not use the content of the
              Website in any manner prohibited by any applicable laws,
              restrictions or regulations of the country from which you are
              accessing the Website.{" "}
            </span>
          </li>
          <li
            className="text-[14px] mb-[1.5em]"
            style={{
              fontFamily: "Montserrat, Sans-serif",
            }}
          >
            <strong>Disclaimer of Warranties</strong>
          </li>
        </ul>

        <p
          className="mb-[24px] text-[14px]"
          style={{
            fontFamily: "Montserrat, Sans-serif",
          }}
        >
          You understand that we cannot and do not guarantee or warrant that
          files available for downloading from the internet or the Website will
          be free of viruses or other destructive code. You are responsible for
          implementing sufficient procedures and checkpoints to satisfy your
          particular requirements for anti-virus protection and accuracy of data
          input and output, and for maintaining a means external to our site for
          any reconstruction of any lost data. WE WILL NOT BE LIABLE FOR ANY
          LOSS OR DAMAGE CAUSED BY A DISTRIBUTED DENIAL-OF-SERVICE ATTACK,
          VIRUSES OR OTHER TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT YOUR
          COMPUTER EQUIPMENT, COMPUTER PROGRAMS, DATA OR OTHER PROPRIETARY
          MATERIAL DUE TO YOUR USE OF THE WEBSITE OR ANY SERVICES OR ITEMS
          OBTAINED THROUGH THE WEBSITE OR TO YOUR DOWNLOADING OF ANY MATERIAL
          POSTED ON IT, OR ON ANY WEBSITE LINKED TO IT.YOUR USE OF THE WEBSITE,
          ITS CONTENT AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE IS
          AT YOUR OWN RISK. THE WEBSITE, ITS CONTENT AND ANY SERVICES OR ITEMS
          OBTAINED THROUGH THE WEBSITE ARE PROVIDED ON AN “AS IS” AND “AS
          AVAILABLE” BASIS, WITHOUT ANY WARRANTIES OF ANY KIND, EITHER EXPRESS
          OR IMPLIED. NEITHER THE COMPANY NOR ANY PERSON ASSOCIATED WITH THE
          COMPANY MAKES ANY WARRANTY OR REPRESENTATION WITH RESPECT TO THE
          COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY OR AVAILABILITY
          OF THE WEBSITE. WITHOUT LIMITING THE FOREGOING, NEITHER THE COMPANY
          NOR ANYONE ASSOCIATED WITH THE COMPANY REPRESENTS OR WARRANTS THAT THE
          WEBSITE, ITS CONTENT OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE
          WEBSITE WILL BE ACCURATE, RELIABLE, ERROR-FREE OR UNINTERRUPTED, THAT
          DEFECTS WILL BE CORRECTED, THAT OUR SITE OR THE SERVER THAT MAKES IT
          AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS OR THAT THE
          WEBSITE OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE WILL
          OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.
        </p>
        <p
          className="mb-[24px] text-[14px]"
          style={{
            fontFamily: "Montserrat, Sans-serif",
          }}
        >
          THE COMPANY HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER
          EXPRESS OR IMPLIED, STATUTORY OR OTHERWISE, INCLUDING BUT NOT LIMITED
          TO ANY WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT AND FITNESS FOR
          PARTICULAR PURPOSE.
        </p>
        <p
          className="mb-[24px] text-[14px]"
          style={{
            fontFamily: "Montserrat, Sans-serif",
          }}
        >
          GAMING MACHINES ARE NOT PERMITTED IN CERTAIN JURISDICTIONS. PURCHASER
          AGREES THAT ANY MERCHANDISE PURCHASED WILL BE USED IN COMPLIANCE WITH
          LOCAL, STATE, AND FEDERAL LAW. IF PURCHASER INTENDS TO USE THE
          MERCHANDISE WITHIN A JURISDICTION THAT PROHIBITS GAMING MACHINES,
          PURCHASER AGREES TO USE SAID MERCHANDISE FOR ENTERTAINMENT PURPOSES
          ONLY.
        </p>
        <p
          className="mb-[24px] text-[14px]"
          style={{
            fontFamily: "Montserrat, Sans-serif",
          }}
        >
          THE FOREGOING DOES NOT AFFECT ANY WARRANTIES WHICH CANNOT BE EXCLUDED
          OR LIMITED UNDER APPLICABLE LAW.
        </p>

        <ul
          className="ml-[3em] mb-[1.5em]"
          style={{
            listStyle: "outside",
          }}
        >
          <li
            className="text-[14px] mb-[1.5em]"
            style={{
              fontFamily: "Montserrat, Sans-serif",
            }}
          >
            <strong>Limitation on Liability</strong>
            <span className="block">
              IN NO EVENT WILL THE COMPANY, ITS AFFILIATES OR THEIR LICENSORS,
              SERVICE PROVIDERS, EMPLOYEES, AGENTS, OFFICERS OR DIRECTORS BE
              LIABLE FOR DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY, ARISING
              OUT OF OR IN CONNECTION WITH YOUR USE, OR INABILITY TO USE, THE
              WEBSITE, ANY WEBSITES LINKED TO IT, ANY CONTENT ON THE WEBSITE OR
              SUCH OTHER WEBSITES OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE
              WEBSITE OR SUCH OTHER WEBSITES, INCLUDING ANY DIRECT, INDIRECT,
              SPECIAL, INCIDENTAL, CONSEQUENTIAL OR PUNITIVE DAMAGES, INCLUDING
              BUT NOT LIMITED TO, PERSONAL INJURY, PAIN AND SUFFERING, EMOTIONAL
              DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR
              ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA,
              AND WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH OF
              CONTRACT OR OTHERWISE, EVEN IF FORESEEABLE.THE FOREGOING DOES NOT
              AFFECT ANY LIABILITY WHICH CANNOT BE EXCLUDED OR LIMITED UNDER
              APPLICABLE LAW.
            </span>
          </li>
          <li
            className="text-[14px] mb-[1.5em]"
            style={{
              fontFamily: "Montserrat, Sans-serif",
            }}
          >
            <strong>Indemnification</strong>
            <span className="block">
              You agree to defend, indemnify and hold harmless the Company, its
              affiliates, licensors and service providers, and its and their
              respective officers, directors, employees, contractors, agents,
              licensors, suppliers, successors and assigns from and against any
              claims, liabilities, damages, judgments, awards, losses, costs,
              expenses or fees (including reasonable attorneys’ fees) arising
              out of or relating to your violation of these Terms of Use or your
              use of the Website, including, but not limited to, your User
              Contributions, any use of the Website’s content, services and
              products other than as expressly authorized in these Terms of Use
              or your use of any information obtained from the Website.{" "}
            </span>
          </li>
          <li
            className="text-[14px] mb-[1.5em]"
            style={{
              fontFamily: "Montserrat, Sans-serif",
            }}
          >
            <strong>Governing Law and Jurisdiction </strong>
            <span className="block">
              All matters relating to the Website and these Terms of Use and any
              dispute or claim arising therefrom or related thereto (in each
              case, including non-contractual disputes or claims), shall be
              governed by and construed in accordance with the internal laws of
              Jamaica without giving effect to any choice or conflict of law
              provision or rule (whether of Jamaica or any other
              jurisdiction).Any legal suit, action or proceeding arising out of,
              or related to, these Terms of Use or the Website shall be
              instituted exclusively in the federal courts of Jamaica, although
              we retain the right to bring any suit, action or proceeding
              against you for breach of these Terms of Use in your country of
              residence or any other relevant country. You waive any and all
              objections to the exercise of jurisdiction over you by such courts
              and to venue in such courts.
            </span>
          </li>
          <li
            className="text-[14px] mb-[1.5em]"
            style={{
              fontFamily: "Montserrat, Sans-serif",
            }}
          >
            <strong>⦁ Waiver and Severability </strong>
            <span className="block">
              No waiver by the Company of any term or condition set forth in
              these Terms of Use shall be deemed a further or continuing waiver
              of such term or condition or a waiver of any other term or
              condition, and any failure of the Company to assert a right or
              provision under these Terms of Use shall not constitute a waiver
              of such right or provision. If any provision of these Terms of Use
              is held by a court or other tribunal of competent jurisdiction to
              be invalid, illegal or unenforceable for any reason, such
              provision shall be eliminated or limited to the minimum extent
              such that the remaining provisions of the Terms of Use will
              continue in full force and effect.{" "}
            </span>
          </li>
          <li
            className="text-[14px] mb-[1.5em]"
            style={{
              fontFamily: "Montserrat, Sans-serif",
            }}
          >
            <strong>Entire Agreement</strong>
            <span className="block">
              The Terms of Use, Privacy Policy and Terms of Sale constitute the
              sole and entire agreement between you and Technogenious Solutions
              Ltd. with respect to the Website and supersede all prior and
              contemporaneous understandings, agreements, representations and
              warranties, both written and oral, with respect to the Website.
            </span>
          </li>
          <li
            className="text-[14px] mb-[1.5em]"
            style={{
              fontFamily: "Montserrat, Sans-serif",
            }}
          >
            <strong>Your Comments and Concerns</strong>
            <span className="block">
              All notices, feedback, comments, requests for technical support,
              and other communications relating to the Website should be
              directed to Company using the
              <Link
                to="/contact"
                style={{
                  color: "#ff0000",
                  textDecoration: "none",
                }}
              >
                {" "}
                Contact US
              </Link>{" "}
              link on the Website.
            </span>
          </li>
        </ul>

        <p
          className="mb-[24px] text-[14px]"
          style={{
            fontFamily: "Montserrat, Sans-serif",
          }}
        >
          <strong>RETURN/REFUND POLICY</strong>
        </p>
        <p
          className="mb-[24px] text-[14px]"
          style={{
            fontFamily: "Montserrat, Sans-serif",
          }}
        >
          Items that are defective or damaged from shipping can be returned
          within 15 days for a full refund.  All parts and supplies can be
          returned within 15 days and may be subject to a restocking fee of up
          to 20%.  All game machines are custom built to specific requests and
          are not able to be returned.  All refunds will be provided as a credit
          to the credit card used at the time of purchase within 5 business days
          upon receipt of the returned merchandise.
        </p>

        <p
          className="mb-[24px] text-[14px]"
          style={{
            fontFamily: "Montserrat, Sans-serif",
          }}
        >
          <strong>SECURITY POLICY</strong>
        </p>
        <p
          className="mb-[24px] text-[14px]"
          style={{
            fontFamily: "Montserrat, Sans-serif",
          }}
        >
          Your payment and personal information are always safe. Our Secure
          Sockets Layer (SSL) software is the industry standard and among the
          best software available today for secure commerce transactions. It
          encrypts all of your personal information, including credit card
          number, name, and address, so that it cannot be read over the
          internet.
        </p>
      </div>
    </div>
  );
};

export default TermsAndCondition;
