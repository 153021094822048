import React, { ReactNode, useEffect } from "react";
// import avatar from "../../src/assests/avatar.svg";
import greyMenu from "../../src/assests/greyMenu.svg";
import * as Fa from "react-icons/fa";
import { useNavigate } from "react-router-dom";
interface SimpleDialogProps {
  showDialog: boolean;
  onCloseDialog: () => void;
  title?: string;
  width?: string;
  height?: string;
  isLoading?: boolean;
  className?: string;
  showCloseIcon?: boolean;
}

const SimpleDialog = ({
  showDialog,
  onCloseDialog,
  showCloseIcon,
  title,
  width,
  height,

  className,
}: SimpleDialogProps) => {
  const getStyles = () => {
    const styles: React.CSSProperties = {};

    if (width) {
      styles.width = width;
    }

    if (height) {
      styles.height = height;
    }

    return styles;
  };
  const navigate = useNavigate();
  const overlayStyle: React.CSSProperties = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(28, 40, 64, 0.55)",
    boxShadow: "5px 5px 10px 12px rgba(0,2,5,.2)",
    zIndex: 49,
    display: showDialog ? "block" : "none",
  };
  const handleClick = () => {
    const phoneNumber = "+1(876)-4482032"; // replace with the actual phone number, including country code
    const message = "Hello, I would like to chat with you."; // optional message

    const whatsappUrl = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(
      message
    )}`;
    window.location.href = whatsappUrl;
  };
  useEffect(() => {
    // Function to handle body scroll lock/unlock
    const handleBodyScroll = () => {
      if (showDialog) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "visible";
      }
    };

    // Add event listener when component mounts
    handleBodyScroll();

    // Clean up function to remove event listener when component unmounts
    return () => {
      document.body.style.overflow = "visible";
    };
  }, [showDialog]);
  return (
    <div>
      <div style={overlayStyle} onClick={onCloseDialog} />
      {showDialog && (
        <div
          className={`fixed p-4 z-50 min-w-[550px]  h-auto max-h-[95vh] overflow-y-auto rounded top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 border-[3px] border-[#ED4D19] bg-white ${className} overflow-hidden smallTablet:!w-full smallTablet:!min-w-full`}
          style={getStyles()}
        >
          <div className="flex justify-center w-full items-center">
            <p className="text-[#ED4D19]  mx-auto text-center font-bold text-[22px]">
              Why Lease Our Gaming Machines?
            </p>
            <p className="cursor-pointer" onClick={onCloseDialog}>
              <svg
                className="w-5 h-5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill=""
                viewBox="0 0 20 20"
              >
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 11.793a1 1 0 1 1-1.414 1.414L10 11.414l-2.293 2.293a1 1 0 0 1-1.414-1.414L8.586 10 6.293 7.707a1 1 0 0 1 1.414-1.414L10 8.586l2.293-2.293a1 1 0 0 1 1.414 1.414L11.414 10l2.293 2.293Z" />
              </svg>
            </p>
          </div>
          <p className="my-5 font-[580] text-base">
            Upgrade your establishment with our top-of-the-line gaming machines.
            They’ll enhance your patrons’ entertainment experience, boost foot
            traffic and revenue, and create a vibrant atmosphere.
          </p>
          <div className="w-full grid grid-cols-2 gap-x-10 mobile:grid-cols-1">
            <div className="grid-cols-1">
              <div className="flex flex-col gap-y-8">
                <div>
                  <span className="font-bold">Increased Foot Traffic:</span>{" "}
                  Attract new customers and keep existing ones coming back for
                  more with our captivating gaming machines.
                </div>
                <div>
                  <span className="font-bold">
                    {" "}
                    Enhanced Entertainment Value:
                  </span>{" "}
                  Elevate your patrons’ experience with a diverse range of games
                  that cater to various interests, ensuring there’s something
                  for everyone to enjoy.
                </div>
                <div>
                  <span className="font-bold"> Boosted Revenue: </span>
                  Our flexible profit-sharing model ensures you benefit directly
                  from the operation of our gaming machines, with a generous
                  60/40 split in your favor.
                </div>
              </div>
            </div>
            <div className="grid-cols-1">
              <div className="flex flex-col gap-y-8">
                <div>
                  <span className="font-bold">Customer Engagement:</span> Foster
                  a sense of community and keep your patrons engaged with our
                  social and interactive gaming experience.
                </div>
                <div>
                  <span className="font-bold">Minimal Risk: </span>Say goodbye
                  to upfront investment costs. With our leasing arrangement, you
                  can enjoy the benefits of having gaming machines on your
                  premises without the financial risk.
                </div>
                <div>
                  <span className="font-bold">Our Commitment:</span> At
                  Technogenious Solutions Ltd, we are dedicated to providing
                  exceptional service and support. From installation to
                  maintenance, our team is here to ensure a seamless experience
                  every step of the way.
                </div>
              </div>
            </div>
          </div>
          <div className="flex gap-x-4 pt-4 pb-2 items-center">
            <div className="border-t w-1/2 border-black"></div>
            <Fa.FaStar size="25px" color="black" />
            <div className="border-t w-1/2 border-black"></div>
          </div>
          <p className="font-semibold text-center">Get Started Today:</p>
          <p className="text-center">
            Ready to take your establishment to the next level? Contact us to
            schedule a personalized consultation and learn more about how our
            gaming machines can benefit your business.
          </p>
          <div className="flex w-1/2 mx-auto gap-x-5 py-4 tablet:w-[60%] mobile:!w-full">
            <div
              onClick={handleClick}
              className="w-fit flex items-center gap-x-2 zoom rounded-full py-2 px-4 bg-[#478603] text-white font-roboto font-medium text-sm"
            >
              <Fa.FaWhatsapp color="white" />
              <button className="">WhatsApp Us</button>
            </div>
            <button
              onClick={() => navigate("/contact")}
              className="w-fit zoom rounded-full py-2 px-4 bg-[#CC4114] text-white font-roboto font-medium text-sm"
            >
              Contact Us
            </button>
          </div>
          <p className="text-[15px] text-[#555961]">
            Don't miss out on this exciting opportunity to transform your bar or
            shop into a gaming destination! Reach out to us today and let's make
            it happen.
          </p>
        </div>
      )}
    </div>
  );
};

export default SimpleDialog;
