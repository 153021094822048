import React from "react";
import { createRoutesFromElements, Route } from "react-router-dom";
import { Home, NotFound, Shop, About, Contact } from "../Pages/index";
import { RootLayout } from "../Layout/index";
import { GamingSection } from "./GamingSection";
import SignUp from "./SignUp";
import Service from "./Service/Service";
import Cart from "./Cart";
import ProductChildLinks from "./ProductChildLinks";
import { FAQsSection } from ".";
import PrivacyPolicy from "../Pages/PrivacyPolicy";
import WarrantySection from "./WarrantySection";
import SignIn from "./SignIn";
import EditProfile from "./EditProfile";
import { GameProductDetail } from "./GameProductDetail";

import Checkout from "../Pages/Checkout";
import PrivateRoute from "../helper/helper";
import AllProducts from "./AllProducts";
import SearchGlobal from "./SearchGlobal";
import TermsAndCondition from "./TermsAndCondition";
import Disclaimer from "./Disclaimer";
// import Payment from "./Payment";
// import AllProducts from "./AllProducts";

const routes = createRoutesFromElements(
  <Route path="/" element={<RootLayout />}>
    <Route index element={<Home />} />
    <Route path="/about" element={<About />} />
    <Route path="/contact" element={<Contact />} />
    <Route path="/products" element={<AllProducts />} />
    <Route path="/shop/:categoryId" element={<Shop />} />
    <Route path="/product/:productId" element={<ProductChildLinks />} />
    <Route path="/gaming-machines" element={<GamingSection />} />
    <Route
      path="/game-product-detail/:gamingId"
      element={<GameProductDetail />}
    />
    <Route path="/register" element={<SignUp />} />
    <Route path="/login" element={<SignIn />} />
    <Route path="/services" element={<Service />} />
    <Route path="/cart" element={<Cart />} />
    <Route path="/FAQs" element={<FAQsSection />} />
    <Route path="/privacy-policy" element={<PrivacyPolicy />} />
    <Route path="/warranties" element={<WarrantySection />} />
    <Route path="*" element={<NotFound />} />
    <Route path="/checkout" element={<Checkout />} />
    <Route path="/search/:searchTerm" element={<SearchGlobal />} />
    <Route path="/termscondition" element={<TermsAndCondition />} />
    <Route path="/disclaimer" element={<Disclaimer />} />
    {/* <Route path="/payment" element={<Payment />} /> */}
    {/* <PrivateRoute path="/checkout" element={<Checkout />} /> */}
  </Route>
);

export default routes;
