import React from "react";
import {
  APIProvider,
  Map,
  MapCameraChangedEvent,
  Marker,
} from "@vis.gl/react-google-maps";

const GoogleMap = () => {
  return (
    <APIProvider
      apiKey={"AIzaSyD_ZKxqvdAdFsbYAXOYBhMTtssRTwbG-QA"}
      onLoad={() => console.log("Maps API has loaded.")}
    >
      <div style={{ width: "100%", height: "400px" }}>
        <Map
          defaultZoom={13}
          defaultCenter={{ lat: 18.0132639, lng: -76.8032989 }}
          onCameraChanged={(ev: MapCameraChangedEvent) =>
            console.log(
              "camera changed:",
              ev.detail.center,
              "zoom:",
              ev.detail.zoom
            )
          }
        >
          <Marker position={{ lat: 18.0132639, lng: -76.8032989 }} />
        </Map>
      </div>
    </APIProvider>
  );
};

export default GoogleMap;
