import React from "react";
import { useLocation } from "react-router-dom";
import { Navbar } from "../Components/index";

const Header = () => {
  const location = useLocation();

  // Correct use of the conditional rendering based on the pathname
  // Also corrected pathname property
  return location.pathname !== "/register" &&
    location.pathname !== "/login" &&
    location.pathname !== "/payment" ? (
    <Navbar />
  ) : null;
};

export default Header;
