import React, { useEffect, useState } from "react";

const Pagination = ({ currentPage, totalPages, onPageChange }: any) => {
  const [loopLimit, setLoopLimit] = useState(5);
  useEffect(() => {
    // Function to update the loop limit based on window size
    const updateLoopLimit = () => {
      const isSmall = window.innerWidth < 510;
      setLoopLimit(isSmall ? 3 : 5);
    };

    // Initial update
    updateLoopLimit();

    // Add event listener for window resize
    window.addEventListener("resize", updateLoopLimit);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", updateLoopLimit);
    };
  }, []); // Empty dependency array to run effect only on mount and unmount

  const getPageNumbers = () => {
    const pages = [];
    if (totalPages <= 5) {
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      if (currentPage <= 3) {
        for (let i = 1; i <= loopLimit; i++) {
          pages.push(i);
        }
        pages.push("...", totalPages);
      } else if (currentPage >= totalPages - 2) {
        pages.push(1, "...");
        for (let i = totalPages - 4; i <= totalPages; i++) {
          pages.push(i);
        }
      } else {
        pages.push(1, "...");
        for (let i = currentPage - 1; i <= currentPage + 1; i++) {
          pages.push(i);
        }
        pages.push("...", totalPages);
      }
    }
    return pages;
  };

  const pages = getPageNumbers();

  return (
    <div className="flex items-center w-full gap-4 overflow-hidden mpb">
      <button
        className={`relative h-10 w-10 select-none bg-transparent text-red-500 border text-center align-middle font-sans text-xs font-medium uppercase shadow-md transition-all border-blue-700 hover:shadow-gray-900/20 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none ${
          currentPage === 1 ? "cursor-not-allowed opacity-50" : ""
        }`}
        type="button"
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 1}
      >
        <span className="absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            aria-hidden="true"
            className="h-[10px] rotate-180"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
            />
          </svg>
        </span>
      </button>

      <div className="flex items-center gap-2">
        {pages.map((page, index) => (
          <button
            key={index}
            className={`relative h-10 w-10 select-none text-center align-middle font-sans text-xs font-medium uppercase shadow-md transition-all border ${
              page === currentPage
                ? "bg-[#0170b9] text-white border-blue-700"
                : "bg-transparent text-red-500 border-blue-700 hover:bg-[#0170b9] hover:text-white"
            } ${page === "..." ? "cursor-default" : ""}`}
            type="button"
            onClick={() => page !== "..." && onPageChange(page)}
            disabled={page === "..."}
          >
            <span className="absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
              {page}
            </span>
          </button>
        ))}
      </div>

      <button
        className={`relative h-10 w-10 select-none bg-transparent text-red-500 border text-center align-middle font-sans text-xs font-medium uppercase shadow-md transition-all border-blue-700 hover:shadow-gray-900/20 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none ${
          currentPage === totalPages ? "cursor-not-allowed opacity-50" : ""
        }`}
        type="button"
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
      >
        <span className="absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            aria-hidden="true"
            className="h-[10px]"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
            />
          </svg>
        </span>
      </button>
    </div>
  );
};

export default Pagination;
