import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { gamingData } from "../constants";
import useFetch from "../Network/useFetch";
import Loader from "./Loader";
import Pagination from "./Pagination";
import Toast from "./Toast/toast";

const GamingBox = () => {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(6);
  const [toast, setToast] = useState({ type: "", message: "" });

  const {
    data: gamingData,
    isLoading,
    isError,
    error,
  } = useFetch(
    `${process.env.REACT_APP_SERVER_URL}/product/gaming-machine?page=${currentPage}&pageSize=${pageSize}`,
    ["fetch-gaming-machines", currentPage, pageSize]
  );
  useEffect(() => {
    if (isError) {
      setToast({ type: "Error", message: error.message });
    }
  }, [isError]);

  const handleReadMore = (ID) => {
    // navigate("/game-product-detail", { state: { product } });
    navigate(`/game-product-detail/${encodeURIComponent(ID)}`);
  };

  const handlePageChange = (page: any) => {
    setCurrentPage(page);
  };

  if (isLoading)
    return (
      <div className="flex h-[80vh] items-center justify-center">
        <Loader forProduct />
      </div>
    );
  if (isError)
    return (
      <div className="h-[50vh]">
        {toast.type && (
          <Toast
            type={toast.type as "Sucess" | "Error"}
            message={toast.message}
          />
        )}
      </div>
    );
  return (
    <>
      <div className="grid grid-cols-3 w-full gap-x-2 gap-y-20 tablet:grid-cols-2 mobile:!grid-cols-1">
        {gamingData.data.results &&
          gamingData.data.results.length > 0 &&
          gamingData.data?.results?.map((data) => (
            <div
              onClick={() => handleReadMore(data._id)}
              className="grid justify-center cursor-pointer grid-cols-1 px-4 py-2 gap-y-6"
            >
              <img
                className="w-[50vw] mx-auto max-w-[250px] h-[25vh] object-contain max-h-[250px] mobile:!max-w-full mobile:w-full mobile:object-contain"
                src={data?.mainImage}
              />
              <p className="w-full text-center">{data?.name}</p>
              <button className="w-fit mx-auto rounded-full py-[10px] px-8 bg-[#0270B9] text-white font-roboto font-bold text-sm hover:bg-[#3A3A3A]">
                Read more
              </button>
            </div>
          ))}
      </div>
      <div className="w-full flex items-center py-8">
        {gamingData.data?.totalRecords > pageSize ? (
          <Pagination
            currentPage={currentPage}
            totalPages={Math.ceil(gamingData.data?.totalRecords / pageSize)}
            onPageChange={handlePageChange}
          />
        ) : null}
      </div>
    </>
  );
};

export default GamingBox;
