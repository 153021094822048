import React, { useState } from "react";
import { isError, useMutation, useQueryClient } from "react-query";
import axios from "axios";
import { useRecoilState, useRecoilValue } from "recoil";
import { DataId, UserInfo, cartItems } from "./atoms/atoms";
import Loader from "./Loader";
import { useNavigate } from "react-router-dom";
import Toast from "./Toast/toast";

const Cart: React.FC = () => {
  const [qty, setQty] = useState(1);
  const userInfo = useRecoilValue(UserInfo);
  const [toast, setToast] = useState({ type: "", message: "" });
  const [cartValue, setCartValue] = useRecoilState(cartItems);
  const [dataId, setDataId] = useRecoilState(DataId);
  const queryClient = useQueryClient();
  const [isSuccess, setIsSucess] = useState(false);
  const handleQuantity = async ({ id, quantity }) => {};
  const removeQuantity = async ({ id }) => {};

  const checkoutCall = async () => {
    const { data } = await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/cart/cart-v2`,
      {
        product: cartValue,
      }
    );
    return data;
  };
  const [loadingId, setLoadingId] = useState(null);

  const {
    mutate: checkoutMutate,
    isLoading: checkoutLoading,
    isError: checkoutError,
  } = useMutation(checkoutCall, {
    onSuccess: (data) => {
      cartValue?.length && setDataId(data?.data?._id);
      setToast({ type: "success", message: "" });
      navigate("/checkout");
    },
    onError: (error) => {
      setToast({
        type: "error",
        message: "Something went wrong",
      });
    },
  });
  const checkoutUpdateCall = async () => {
    const { data } = await axios.patch(
      `${process.env.REACT_APP_SERVER_URL}/cart/cart-v2/${dataId}`,
      { product: cartValue }
    );
    return data;
  };
  const {
    mutate: checkoutUpdateMutate,
    isLoading: checkoutUpdateLoading,
    isError: checkoutUpdateError,
  } = useMutation(checkoutUpdateCall, {
    onSuccess: (data) => {
      setToast({ type: "success", message: "" });
      navigate("/checkout");
      queryClient.invalidateQueries("fetch-cart");
    },
    onError: (error) => {
      const errors = error as any;
      setToast({
        type: "error",
        message: errors.message,
      });
      navigate("/checkout");
    },
  });

  const handleIncrement = async (productId) => {
    const itemExist = cartValue?.find((product) => product?.id === productId);
    if (itemExist) {
      const currentQuantity = itemExist.quantity;
      const updatedQuantity = currentQuantity + 1;
      const updatedSubtotal = updatedQuantity * itemExist.price;

      const updatedItem = {
        ...itemExist,
        quantity: updatedQuantity,
        subtotal: updatedSubtotal,
      };
      const updatedCart = cartValue.map((item) => {
        if (item.id === updatedItem.id) {
          return updatedItem;
        }
        return item;
      });

      setCartValue(updatedCart);
    }
  };

  const handleDecrement = async (productId) => {
    const itemExist = cartValue?.find((product) => product?.id === productId);
    if (itemExist) {
      const currentQuantity = itemExist.quantity;
      const updatedQuantity = currentQuantity - 1;

      if (updatedQuantity <= 0) {
        const updatedCart = cartValue.filter((item) => item.id !== productId);
        setCartValue(updatedCart);
      } else {
        const updatedSubtotal = updatedQuantity * itemExist.price;

        const updatedItem = {
          ...itemExist,
          quantity: updatedQuantity,
          subtotal: updatedSubtotal,
        };

        const updatedCart = cartValue.map((item) => {
          if (item.id === updatedItem.id) {
            return updatedItem;
          }
          return item;
        });

        setCartValue(updatedCart);
      }
    }
  };

  const calculateTotal = (items) => {
    let totalQuantity = 0;
    let totalPrice = 0;
    cartValue?.forEach((item) => {
      if (item.quantity > 0) {
        totalQuantity += item.quantity;
        totalPrice += item.subtotal;
      }
    });

    return { totalQuantity, totalPrice };
  };
  const { totalQuantity, totalPrice } = calculateTotal(cartValue);

  const handleClose = (productId) => {
    const itemExist = cartValue?.find((product) => product?.id === productId);
    if (itemExist) {
      const updatedCart = cartValue.filter((item) => {
        return item.id !== productId;
      });

      setCartValue(updatedCart);
    }
  };

  const navigate = useNavigate();
  const Checkout = (id?: any) => {
    setCartValue(cartValue?.filter((data) => data?.quantity !== 0));
    if (id?.length) {
      checkoutUpdateMutate();
    } else checkoutMutate();
  };
  return (
    <section>
      <figure className="w-full cart-background-image relative">
        <img
          className="w-full h-full object-cover"
          src="https://technostorage.nyc3.digitaloceanspaces.com/uploads/2021/07/slotss.jpg"
          alt=""
        />
        <h1 className="capitalize text-white absolute flex items-center justify-center text-6xl font- inset-0 cart-heading">
          cart
        </h1>
      </figure>
      {/* hero end */}

      <div className="container pb-[50px] mx-auto px-2 mobile:!w-full">
        <div className="w-full border-0 border-t-4 border-blue-600 bg-gray-100 p-4 my-20 flex flex-wrap gap-4 items-center">
          <span className="bg-blue-600 rounded-full w-2 h-2 flex items-center justify-center text-white p-3">
            &#10003;
          </span>
          <p className="text-gray-700 capitalize">cart updated.</p>
        </div>

        {cartValue?.length === 0 ? (
          <div className="w-full border-0 border-t-4 border-blue-600 bg-gray-100 p-4 my-20 flex flex-wrap gap-4 items-center">
            <span className="bg-blue-600 rounded-full w-2 h-2 flex items-center justify-center text-white p-3">
              &#10003;
            </span>
            <p className="text-gray-700 capitalize">
              Your cart is currently empty.
            </p>
          </div>
        ) : null}
        {/* table start */}
        {cartValue?.length > 0 ? (
          <>
            <div className="relative overflow-x-auto tablet:overscroll-x-none">
              <table className="w-full text-sm text-left rtl:text-right text-gray-500 border  ">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50  border   desktop:hidden ">
                  <tr>
                    <th scope="col" className="py-6 w-11"></th>
                    <th scope="col"></th>
                    <th
                      scope="col"
                      className="w-[344px] text-[#4B4F58] font-bold text-sm"
                    >
                      Product
                    </th>
                    <th
                      scope="col"
                      className="w-[173px] text-[#4B4F58] font-bold text-sm"
                    >
                      Price
                    </th>
                    <th
                      scope="col"
                      className="w-[215px] text-[#4B4F58] font-bold text-sm"
                    >
                      Quantity
                    </th>
                    <th
                      scope="col"
                      className="w-[173px] text-[#4B4F58] font-bold text-sm"
                    >
                      Subtotal
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {cartValue?.length > 0 &&
                    cartValue?.map((item, index) => (
                      <>
                        {item?.quantity !== 0 ? (
                          <>
                            <th
                              key={item?.id}
                              className="bg-white border-b hidden largeScreen:table-row"
                            >
                              <th scope="row" className="px-4 py-8">
                                <span
                                  onClick={() => handleClose(item?.id)}
                                  className="rounded-full w-8 h-8 flex items-center justify-center border text-gray-400 duration-300 transition-all hover:border-black hover:text-black cursor-pointer"
                                >
                                  <span className="ahfb-svg-iconset ast-inline-flex ast-mobile-svg ast-close-svg">
                                    <svg
                                      fill="currentColor"
                                      version="1.1"
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                    >
                                      <path d="M5.293 6.707l5.293 5.293-5.293 5.293c-0.391 0.391-0.391 1.024 0 1.414s1.024 0.391 1.414 0l5.293-5.293 5.293 5.293c0.391 0.391 1.024 0.391 1.414 0s0.391-1.024 0-1.414l-5.293-5.293 5.293-5.293c0.391-0.391 0.391-1.024 0-1.414s-1.024-0.391-1.414 0l-5.293 5.293-5.293-5.293c-0.391-0.391-1.024-0.391-1.414 0s-0.391 1.024 0 1.414z"></path>
                                    </svg>
                                  </span>
                                </span>
                              </th>
                              <td className="px-6 py-4 w-32">
                                <img
                                  className="w-full h-full"
                                  src={item?.Image}
                                  alt=""
                                />
                              </td>
                              <td>
                                <h1 className=" text-red-500 table-product-row md:text-sm text-nowrap mx-2 font-semibold duration-300 transition-all hover:text-gray-800 cursor-pointer w-fit  ">
                                  {item?.name}{" "}
                                </h1>
                              </td>
                              <td className="text-base">${item?.price}</td>
                              <td>
                                {/* {loadingId === item.id && 
                                  (cartLoading || isLoading) ? (
                                    <Loader />
                                  ) : ( */}
                                <div className="flex mx-2">
                                  <button
                                    onClick={() => handleDecrement(item?.id)}
                                    className="md:p-2   border md:px-4 text-xs md:text-sm px-2 "
                                  >
                                    -
                                  </button>
                                  <button className="md:p-2   border md:px-4  text-xs md:text-sm px-2">
                                    {item?.quantity}
                                  </button>
                                  <button
                                    onClick={() => handleIncrement(item?.id)}
                                    className="md:p-2   border md:px-4 text-xs md:text-sm px-2 "
                                  >
                                    +
                                  </button>
                                </div>
                                {/* )} */}
                              </td>
                              <td className="text-base">${item?.subtotal}</td>
                            </th>

                            <thead
                              className="text-xs text-gray-700 uppercase bg-gray-50  border  h-[323px] w-full hidden desktop:table-row  "
                              key={index}
                            >
                              <tr className="flex w-full flex-col bg-white">
                                <td
                                  scope="col"
                                  className=" w-full h-[45px] border-b border-gray-300 flex justify-end"
                                >
                                  <span
                                    onClick={() => handleClose(item?.id)}
                                    className="ahfb-svg-iconset ast-inline-flex w-[40px] ast-mobile-svg ast-close-svg flex items-center"
                                  >
                                    <svg
                                      fill="currentColor"
                                      version="1.1"
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      className="text-gray-400 border border-solid border-[grey] rounded-full"
                                    >
                                      <path d="M5.293 6.707l5.293 5.293-5.293 5.293c-0.391 0.391-0.391 1.024 0 1.414s1.024 0.391 1.414 0l5.293-5.293 5.293 5.293c0.391 0.391 1.024 0.391 1.414 0s0.391-1.024 0-1.414l-5.293-5.293 5.293-5.293c0.391-0.391 0.391-1.024 0-1.414s-1.024-0.391-1.414 0l-5.293 5.293-5.293-5.293c-0.391-0.391-1.024-0.391-1.414 0s-0.391 1.024 0 1.414z"></path>
                                    </svg>
                                  </span>{" "}
                                </td>
                                <th
                                  scope="col"
                                  className="h-[90px] h-90 flex justify-center border-b border-gray-300"
                                >
                                  <img
                                    className="w-[85px] h-full"
                                    src={item?.Image}
                                    alt=""
                                  />
                                </th>
                                <th
                                  scope="col"
                                  className="w-full h-[45px] text-base  flex justify-between border-b border-gray-300  items-center pl-[10px] pr-[10px]"
                                >
                                  Product:
                                  <h1 className=" text-red-500 table-product-row md:text-sm text-nowrap mx-2 font-semibold duration-300 transition-all hover:text-gray-800 cursor-pointer w-fit  ">
                                    {item?.name}
                                  </h1>
                                </th>
                                <th
                                  scope="col"
                                  className="w-full h-[45px] text-base border-b border-gray-300 flex items-center pl-[10px] pr-[10px]"
                                >
                                  Price:
                                  <div className="flex absolute right-0 font-normal pr-[10px]">
                                    ${item?.price}
                                  </div>
                                </th>
                                <th
                                  scope="col"
                                  className="w-full h-[55px] text-base flex justify-between border-b border-gray-300 items-center pl-[10px] pr-[10px]"
                                >
                                  Quantity:
                                  <div className="flex mx-2">
                                    <button
                                      onClick={() => handleDecrement(item?.id)}
                                      className="md:p-2   border md:px-4 text-xs md:text-sm px-2 "
                                    >
                                      -
                                    </button>
                                    <button className="md:p-2   border md:px-4  text-xs md:text-sm px-2">
                                      {item?.quantity}
                                    </button>
                                    <button
                                      onClick={() => handleIncrement(item?.id)}
                                      className="md:p-2   border md:px-4 text-xs md:text-sm px-2 "
                                    >
                                      +
                                    </button>
                                  </div>
                                </th>
                                <th
                                  scope="col"
                                  className="w-full h-[45px] text-base border-b border-gray-300 flex items-center pl-[10px] pr-[10px]"
                                >
                                  Subtotal:
                                  <div className="flex absolute right-0 font-normal pr-[10px]">
                                    ${item?.subtotal}
                                  </div>
                                </th>
                              </tr>
                            </thead>
                          </>
                        ) : null}
                      </>
                    ))}
                </tbody>
              </table>
            </div>
            {/* table start */}

            {/* coupon start */}
            <div className="flex flex-wrap items-center justify-between border mb-20 py-8 px-2 gap-3">
              <div className="flex flex-wrap w-full gap-2 mobile:gap-y-4">
                <input
                  type="text"
                  className="border px-2 mobile:w-full mobile:py-2"
                  placeholder="Coupon code"
                />

                <button className="rounded-full bg-[#0270B9] text-white px-6 py-2 capitalize duration-300 transition-all hover:bg-gray-800 mobile:mb-1 mobile:w-full">
                  apply coupon
                </button>
              </div>
              <button className="rounded-full bg-[#0270B9] opacity-50 hover:cursor-not-allowed text-white px-6 py-2 capitalize  medium:w-[100%] mobile:gap-y-2">
                update cart
              </button>
            </div>
            {/* coupon end */}

            {/* Cart totals start */}
            <div className="flex items-center justify-end">
              <div className="w-full md:w-[39rem] border">
                <div className="py-5 px-6 w-full border-b bg-gray-100">
                  <h1 className="text-black/80 text-center font-bold md:text-3xl capitalize">
                    cart totals
                  </h1>
                </div>

                <div className="py-5 px-6">
                  <div className="flex items-center justify-between border-b py-3">
                    <p className="capitalize text-black/80 font-semibold text-xs md:text-xl">
                      subtotal
                    </p>
                    <h1 className="text-black/70 flex w-full justify-end text-xs md:text-xl">
                      USD${totalPrice}
                    </h1>
                    <div></div>
                  </div>

                  {/* <div className="flex items-center justify-between border-b py-3">
                    <p className="capitalize text-black/80 font-semibold text-xs md:text-xl">
                      Shipping
                    </p>
                    <h1 className="text-black/70 flex flex-col justify-end  w-1/2  text-xs md:text-xl">
                      Free shipping
                      <div className="mb-4 mt-1 text-xs md:text-xl">
                        {" "}
                        Shipping to{" "}
                        <span className="font-bold text-black/70">
                          Kingston.
                        </span>
                      </div>
                      <button className="text-red-500 mt-auto duration-300 transition-all hover:text-gray-700">
                        {" "}
                        Change address
                      </button>
                    </h1>
                    <div></div>
                  </div> */}

                  <div className="flex items-center justify-between border-b py-3 mb-6">
                    <p className="capitalize text-black/80 font-semibold text-xs md:text-xl">
                      Total
                    </p>
                    <h1 className="text-black/70 flex w-full justify-end text-xs md:text-xl">
                      USD${totalPrice}
                    </h1>
                    <div></div>
                  </div>

                  <button
                    onClick={() => Checkout(dataId)}
                    disabled={
                      checkoutUpdateLoading ||
                      checkoutLoading ||
                      cartValue?.length === 0
                    }
                    className=" font-bold capitalize bg-[#0270B9] hover:bg-gray-700 duration-300 transition-all w-full text-center py-3 text-white rounded-full text-xs md:text-xl"
                  >
                    {checkoutUpdateLoading || checkoutLoading ? (
                      /* HTML: <div class="loader"></div> */

                      <div className="buttonLoader"></div>
                    ) : (
                      "proceed to checkout"
                    )}
                  </button>
                </div>
              </div>
            </div>
          </>
        ) : null}
        {/* Cart totals end */}
      </div>
    </section>
  );
};

export default Cart;
