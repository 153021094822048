import React from "react";
import logo from "../../Asset/Technogenius-logo.png";
import * as Fa from "react-icons/fa";

const SaleSection = () => {
  const scrollToSection = (id: string) => {
    const section = document.getElementById(id);
    if (section) {
      const offset = -100; // Adjust this value to set the desired offset from the top
      const elementPosition = section.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset + offset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  };

  return (
    <div className="w-full max-w-[1200px] mx-auto flex justify-between  px-3 smallTablet:flex-col smallTablet:gap-y-20 smallTablet:justify-center smallTablet:!items-center">
      <div className="h-[30vh] w-1/3 flex gap-x-4 smallTablet:w-full smallTablet:flex-col smallTablet:items-center smallTablet:h-[25vh]">
        <div className="w-fit">
          <svg
            aria-hidden="true"
            fill="#AB0E13"
            id="icon-zoom"
            className=" text-[50px] h-14 w-14"
            viewBox="0 0 576 512"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M0 432c0 26.5 21.5 48 48 48h480c26.5 0 48-21.5 48-48V256H0v176zm192-68c0-6.6 5.4-12 12-12h136c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12H204c-6.6 0-12-5.4-12-12v-40zm-128 0c0-6.6 5.4-12 12-12h72c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12H76c-6.6 0-12-5.4-12-12v-40zM576 80v48H0V80c0-26.5 21.5-48 48-48h480c26.5 0 48 21.5 48 48z"></path>
          </svg>
        </div>
        <div className="h-[20vh] w-3/4 flex flex-col gap-y-2 smallTablet:items-center">
          <span className="font-roboto text-[24px] font-semibold">
            Sales & Products
          </span>
          <span
            className="text-sm smallTablet:text-center"
            style={{ fontFamily: "Montserrat',Sans-serif" }}
          >
            Our affordable, high quality products are sourced locally and
            internationally from reputable suppliers. We stock a wide variety...
          </span>
          <button
            onClick={() => scrollToSection("Sales & Products")}
            className="w-fit my-2 flex items-center gap-x-2 px-4 text-[13px] border-2 border-black py-2 rounded-md"
          >
            <Fa.FaArrowRight className="h-3 w-3" color="black" />
            Learn more
          </button>
        </div>
      </div>
      <div className="h-[30vh] w-1/3 flex gap-x-2 pl-14 smallTablet:w-full smallTablet:h-[25vh] ">
        <div className="h-[20vh] flex flex-col gap-y-2 w-11/12 smallTablet:items-center">
          <span className="font-roboto text-[24px] font-semibold">Leasing</span>
          <span
            className="text-sm smallTablet:text-center "
            style={{ fontFamily: "Montserrat',Sans-serif" }}
          >
            Gaming machines owned by Technogenious and leased to licensed bar
            owners/operators permits them to...
          </span>
          <button
            onClick={() => scrollToSection("Leasing")}
            className="w-fit my-2 flex items-center gap-x-2 px-4 text-[13px] border-2 border-black py-2 rounded-md"
          >
            <Fa.FaArrowRight className="h-3 w-3" color="black" />
            Learn more
          </button>
        </div>
      </div>
    </div>
  );
};

export default SaleSection;
