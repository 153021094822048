import React from "react";
import clsx from "clsx";
import * as Fa from "react-icons/fa";

type Props = {
  img: string;
  heading: string;
};
const LogoAndText = ({ img, heading }: Props) => {
  const renderContent = (statu: string) => {
    switch (heading) {
      case "Manufacturing":
        return (
          <span
            id="Manufacturing"
            style={{ fontFamily: '"Montserrat",Sans-serif"' }}
            className="text-sm"
          >
            We are extremely proud to inform you that our company has been
            granted a license by the prestigious Betting, Gaming and Lotteries
            Commission (BGLC) to manufacture and assemble gaming machines. This
            notable achievement serves as a testament to our commitment to
            excellence in the gaming industry and our unwavering dedication to
            providing top-quality services to our clients.<br></br> <br></br>As
            a licensed manufacturer and assembler of gaming machines, we take
            great pride in adhering to the highest standards of quality, safety,
            and integrity. Our team of experts is composed of skilled and
            experienced professionals who are passionate about creating
            innovative and engaging gaming machines that capture the hearts and
            minds of players around Jamaica.
            <br></br> <br></br>
            We are truly honored to have received this license from the BGLC,
            and we look forward to leveraging our expertise and resources to
            continue delivering the highest quality gaming machines to our
            esteemed clients. With us, you can rest assured that you are in good
            hands.
          </span>
        );

      case "Installation & Maintenance":
        return (
          <span
            id="Installation & Maintenance"
            style={{ fontFamily: '"Montserrat",Sans-serif"' }}
            className="text-sm"
          >
            At Technogenious Solutions Limited, we strive to provide our
            customers with a hassle-free experience when it comes to
            installation of gaming boxes. As such, we have the exclusive right
            to install boxes leased to customers. However, we understand that
            some customers may prefer to have an independent technician to carry
            out the installation process for machines purchased, and we respect
            their decision.
            <br />
            <br />
            For customers who choose to purchase our gaming boxes, we offer the
            option of paying for installation services from our team of
            qualified technicians. We take pride in providing top-notch
            installation services that are efficient and reliable.
            <br />
            <br />
            Additionally, we are proud to inform our customers that we are a
            licensed authorized service center for Pyramids Bill Acceptors. This
            means that we have been authorized to provide dependable repair and
            maintenance services for Pyramids Bill Acceptors, offering our
            customers peace of mind knowing that their equipment is in good
            hands.
          </span>
        );

      case "Sales":
        return (
          <span
            id="Sales & Products"
            style={{ fontFamily: '"Montserrat",Sans-serif"' }}
            className="text-sm"
          >
            {" "}
            We are proud to offer an exceptional variety of products that cater
            to your diverse needs with affordability and quality as our top
            priority. Our product selection includes an extensive range of
            electronic parts and accessories, which are sourced from renowned
            global and local suppliers. From top-of-the-line touch screen
            monitors, LCDs, and gaming boards to bill acceptors, ticket
            printers, electrical supplies, and components, you name it, we have
            it. Our selection of electronic parts and accessories extends to
            various brands known for their high-quality gaming parts, ensuring
            that your gaming machines maintain top-notch performance. We
            guarantee that our top-notch products will keep your gaming machines
            in excellent working condition, ensuring you have a consistent and
            enjoyable gaming experience. Trust us to meet your requirements,
            whether it’s by providing affordable and exceptional quality
            products or excellent customer service.
          </span>
        );
      case "Leasing":
        return (
          <span
            id="Leasing"
            style={{ fontFamily: '"Montserrat",Sans-serif"' }}
            className="text-sm"
          >
            {" "}
            Technogenious is proud to offer top-of-the-line gaming machines that
            are perfect for-entertainment establishments like licensed bars. Our
            machines are guaranteed to provide endless hours of fun for patrons
            while enabling bar owners and operators to earn a percentage of the
            weekly gaming net intake. We understand that as a business owner,
            every little bit helps, which is why we are dedicated to providing
            you with the best possible gaming experience. Our machines are easy
            to use and come with a wide range of features to keep your customers
            engaged. Plus, we provide comprehensive support to ensure your
            machines are always up and running smoothly. Call our team for
            further details and to learn more about how our gaming machines can
            help boost revenue for your business.
          </span>
        );
    }
  };
  const handleClick = () => {
    const phoneNumber = "+1(876)-4482032"; // replace with the actual phone number, including country code
    const message = "Hello, I would like to chat with you."; // optional message

    const whatsappUrl = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(
      message
    )}`;
    window.location.href = whatsappUrl;
  };

  return (
    <div className="w-full  max-w-[1140px] mx-auto">
      <div className="flex px-12 gap-x-10 smallTablet:flex-col smallTablet:px-1 ">
        <div className="w-[25%] h-[20vh] my-auto smallTablet:!w-full smallTablet:flex smallTablet:justify-center">
          <img
            id="img-zoom"
            className="w-full h-auto smallTablet:h-[20vh] smallTablet:w-fit "
            src={img}
          />
        </div>
        <div className="px-8 w-[85%] smallTablet:px-1 smallTablet:w-full">
          <span
            className={clsx(
              "leading-[1.3em]  font-roboto text-[34px] font-normal mobile:text-[25px]",
              heading === "Installation & Maintenance" && " text-[#C73F13]"
            )}
          >
            {heading}
          </span>
          <div
            style={{
              border: "2px solid",
              borderColor: heading === "Sales" ? "#C73F13" : "black",
            }}
            className={clsx(
              " my-2 border-b-black",
              heading === "Sales" && "border-b-[#C73F13]"
            )}
          />
          {renderContent(heading)}
          <div
            onClick={handleClick}
            className="w-fit flex !my-8 !mx-2 items-center gap-x-2 zoom rounded-full py-2 px-4 bg-[#478603] text-white font-roboto font-medium text-sm"
          >
            <Fa.FaWhatsapp color="white" />
            <button className="">WhatsApp Us</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LogoAndText;
