import React, { useEffect } from "react";
import AboutBanner from "../Components/about-us/AboutBannerSection";
import ContactSection from "../Components/contact-us/ContactSection";

const Contact = () => {
  useEffect(() => {
    window.scrollTo({
      top: -100,
      behavior: "auto",
    });
  }, []);
  return (
    <>
      <AboutBanner
        title="CONTACT US FOR MORE INFORMATION"
        backgroundImage="https://technostorage.nyc3.digitaloceanspaces.com/uploads/2021/07/Contact-Us-Banner-2.jpg"
        height={500}
        fontSize="52px"
        boxHeight="500px"
      />

      <ContactSection />
    </>
  );
};

export default Contact;
