import React from "react";

const StatementSection = () => {
  return (
    <div className="mb-1">
      <div className=" ml-20 flex items-center  tablet:px-2.5 smallTablet:flex-col mobile:mx-auto mobile:!px-0 mobile:!justify-center mobile:!items-start">
        <div className="flex flex-col gap-y-12 w-[90%] mobile:w-full">
          <div className="flex flex-col px-2">
            <h2
              className="text-[60px] font-roboto mobile:text-[30px]"
              style={{
                color: "#3A3A3A",
                fontWeight: "600",
                lineHeight: "1.3em",
              }}
            >
              Mission Statement
            </h2>
            <div
              className="w-[75%]  border rounded-3xl mobile:w-full"
              style={{
                boxShadow: "0 4px 10px -2px rgba(0,0,0,.5)",
                backgroundImage:
                  "url(https://technostorage.nyc3.digitaloceanspaces.com/uploads/2021/07/slate-texture.jpg)",
                backgroundPosition: "center center",
                backgroundSize: "cover",
              }}
            >
              <p
                className="text-center text-[36px]  font-simonetta mobile:!text-[22px]"
                style={{
                  color: "#FFF",
                  fontFamily: "Simonetta, Sans-serif",
                  fontWeight: "400",
                  lineHeight: "0.8",
                }}
              >
                To provide Ingenious Business Solutions – Be Ready!
              </p>
            </div>
          </div>
          <div className="flex flex-col mobile:px-2">
            <h2
              className="text-[60px] font-roboto mobile:text-[30px] "
              style={{
                color: "#3A3A3A",
                fontWeight: "600",
              }}
            >
              Vision Statement
            </h2>
            <div
              className="w-[92%]  border rounded-3xl mobile:px-2 mobile:w-full"
              style={{
                boxShadow: "0 4px 10px -2px rgba(0,0,0,.5)",
                backgroundImage:
                  "url(https://technostorage.nyc3.digitaloceanspaces.com/uploads/2021/07/slate-texture.jpg)",
                backgroundPosition: "center center",
                backgroundSize: "cover",
              }}
            >
              <p
                className="text-center text-[22px] mb-1.5 font-simonetta mobile:!text-[22px]"
                style={{
                  color: "#FFF",
                  fontWeight: "400",
                  lineHeight: "1.12",
                }}
              >
                “To establish and solidify our position as a leader in the
                Gaming Machine industry, we will provide exceptional quality,
                reliable service, and competitive prices. We are committed to
                remaining dependable, affordable, and trendy.”
              </p>
            </div>
          </div>
        </div>
        <div className="w-[20]">
          <img
            src="https://technostorage.nyc3.digitaloceanspaces.com/uploads/2021/10/about-us-page-female.png"
            alt="Technogenious Solutions Contact"
            className=" object-contain relative top-1 "
          />
        </div>
      </div>
    </div>
  );
};

export default StatementSection;
