import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Routes from "./Components/Routes";
import "./index.css";
import SellingProduct from "./Components/SellingProduct";
import TopBrands from "./Components/TopBrands";
import {
  RecoilRoot,
  atom,
  selector,
  useRecoilState,
  useRecoilValue,
} from "recoil";

const router = createBrowserRouter(Routes);

function App() {
  return (
    <RecoilRoot>
      <RouterProvider router={router} />
    </RecoilRoot>
  );
}

export default App;
