import React from "react";

const GambleLife = () => {
  return (
    <div className="mx-auto mt-14 max-w-[1140px] tablet:px-2.5 smallTablet:my-6">
      <div className="flex flex-col justify-center">
        <div className="flex w-[88%] justify-between items-center mobile:flex-col">
          <h4
            className="text-[64px] smallTablet:text-[50px] mobile:!text-[30px]"
            style={{
              fontFamily: "Montserrat, Sans-serif",
              fontWeight: "200",
              lineHeight: "1.2em",
            }}
          >
            "Life is a Gamble"
          </h4>
          <div className="mobile:mt-2">
            <img
              src="https://technostorage.nyc3.digitaloceanspaces.com/uploads/2021/06/Poker-wheel-u.png"
              className="mobile:w-[234px] mobile:h-[229px] img-hover-effect"
              alt="Poker wheel "
            />
          </div>
        </div>
        <hr className="mx-auto mt-10 mb-5 w-full border-solid border-[0.8px] border-black" />
        <div>
          <p
            className="mb-5 text-[15px] smallTablet:text-[13px] "
            style={{
              color: "#000",
              fontFamily: "Montserrat, Sans-serif",
              fontWeight: "700",
            }}
          >
            “Life is a Gamble,” the timeless adage that has fuelled the
            entrepreneurial spirit of our CEO/Founder, Sean Taylor, who took a
            bold leap of faith in 2018. He rolled the dice and set in motion the
            inception of a dynamic and innovative company now known as
            “Technogenious Solutions Limited.” This exciting journey began in
            Portmore, St. Catherine, and subsequently led us to our current
            headquarters at Shop 4B, 17 Molynes Road, Kingston, Jamaica.
          </p>
          <p
            className="mb-5 text-[15px] smallTablet:text-[13px] "
            style={{
              color: "#000",
              fontFamily: "Montserrat, Sans-serif",
              fontWeight: "700",
            }}
          >
            Technogenious Solutions Limited operates under a license granted by
            the Betting Gaming and Lotteries Commission, allowing us to provide
            a wide range of services that encompass the gaming industry. Our
            expertise includes the manufacturing, assembling, selling,
            repairing, and installation of gaming machines and their related
            components. We take pride in our extensive inventory of parts and
            service supplies, sourced from some of the most reputable
            manufacturers in the gaming industry.
          </p>
          <p
            className="mb-5 text-[15px] smallTablet:text-[13px] "
            style={{
              color: "#000",
              fontFamily: "Montserrat, Sans-serif",
              fontWeight: "700",
            }}
          >
            One of the key advantages of engaging with Technogenious Solutions
            Limited is the opportunity for our clients to lease gaming machines.
            This arrangement not only ensures access to state-of-the-art
            machinery but also offers the added benefit of earning a commission
            based on the net weekly profit generated.
          </p>
          <p
            className="mb-5 text-[15px] smallTablet:text-[13px] "
            style={{
              color: "#000",
              fontFamily: "Montserrat, Sans-serif",
              fontWeight: "700",
            }}
          >
            Moreover, we’re proud to be recognized as an Authorized Sales and
            Service Center for bill validators and ticket printers manufactured
            by Pyramid Technologies, Inc., located in Mesa, Arizona, USA. This
            affiliation underscores our commitment to delivering top-notch,
            industry-leading products and services to our valued clients within
            the gaming sector.
          </p>
          <p
            className="mb-5 text-[15px] smallTablet:text-[13px] "
            style={{
              color: "#000",
              fontFamily: "Montserrat, Sans-serif",
              fontWeight: "700",
            }}
          >
            As our company continues to evolve and expand, our unwavering goal
            remains to provide innovative, high-quality products and to deliver
            dependable services that exceed the expectations of our discerning
            clientele in the gaming industry. At Technogenious Solutions
            Limited, we thrive on embracing the uncertainty of life’s gambles
            and turning them into winning opportunities for our clients and
            partners alike.
          </p>
        </div>
        <hr className="mx-auto my-5 w-full border-solid border-[0.8px] border-black" />
      </div>
    </div>
  );
};

export default GambleLife;
