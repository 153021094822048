import React from "react";

export const WhoWeAre = () => {
  return (
    <div className="mx-auto mt-32 mb-16 max-w-[1140px] smallTablet:my-6">
      <div className="flex flex-col justify-center">
        <div className="flex items-center text-center justify-start gap-x-10">
          <div className="text-black pl-7 text-center font-roboto text-6xl font-semibold  smallTablet:mx-auto smallTablet:text-[38px]">
            Who we are
          </div>
          <div>
            <img
              src="https://technostorage.nyc3.digitaloceanspaces.com/uploads/2021/06/dice-02.png"
              className="w-48 h-48 dice smallTablet:hidden"
              alt="Dice"
            />
          </div>
        </div>
        <div
          style={{
            color: "#3A3A3A",
            fontFamily: "Montserrat, Sans-serif",
            fontWeight: "600",
            lineHeight: "1.3em",
          }}
          className="mx-auto px-2 text-center text-[20px]  smallTablet:text-[13px] smallTablet:mt-6 smallTablet:mb-3"
        >
          Technogenious Solutions Ltd is a licensed Technical Service Provider
          authorized by Betting, Gaming and Lotteries Commission.
        </div>
        <hr className="mx-auto my-6 w-1/3 border-[1.5px] border-solid border-black  mb-7 smallTablet:mt-3 smallTablet:mb-5" />
        <div>
          <p
            className="pl-7 text-[16px] smallTablet:text-[13px] smallTablet:pl-4"
            style={{
              color: "#000",
              fontFamily: "Montserrat, Sans-serif",

              fontWeight: "600",
            }}
          >
            Life is a Gamble, this adage was literally the engine that drove our
            CEO/Founder Sean Taylor to do a throw of the dice and launch his own
            Company. This dynamic and innovative Company branded Technogenious
            Solutions Limited began operations in Portmore and moved to its
            present location at 17 Molynes Rd, Shop 4B in 2018. We operate under
            license granted by Betting Gaming and Lotteries Commission and
            provide services such as the manufacturing/assembling, selling,
            repairing, and installation of Gaming machines and related
            components.
          </p>
        </div>
      </div>
    </div>
  );
};
