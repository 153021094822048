import React, { useState } from "react";
import PokerWheel2 from "../assets/PokerWheel2.jpeg";
import SimpleDialog from "./Modal";

const RetailerComission = () => {
  const [openDialog, setOpenDialog] = useState(false);
  return (
    <>
      <div className="flex justify-center items-center w-full h-[515px] pt-11 pb-9 smallTablet:!py-2 smallTablet:h-[400px]">
        <div className="flex justify-center w-[1140px] h-full tablet:h-auto">
          <div className="w-[40%] flex flex-col items-center justify-center p-[10px] smallTablet:w-auto smallTablet:ml-0 smallTablet:mr-0">
            <h2 className="text-[#C70000] font-roboto font-semibold text-[42px] leading-tight text-center mb-5 tablet:text-[23px]">
              Want to become a retailer and earn commission?
            </h2>
            <img
              src="https://technostorage.nyc3.digitaloceanspaces.com/uploads/2021/06/Poker-Slots-03-03.png"
              alt="Logo"
              className="w-[50%] mb-5 left-retailer-effect"
            />
            <button
              onClick={() => setOpenDialog(true)}
              className="border-2 rounded-lg py-[6px] px-6 zoom border-[#0270b9] hover:border-[#C70000] hover:text-[#C70000] "
            >
              Learn More
            </button>
          </div>
          <div className="w-[62%] h-[483px] flex justify-center tablet:h-auto smallTablet:hidden">
            <img
              src={PokerWheel2}
              alt="pokerwheelimage"
              className="w-[70%] object-cover img-hover-effect"
            />
          </div>
        </div>
      </div>
      <SimpleDialog
        showDialog={openDialog}
        onCloseDialog={() => setOpenDialog(!openDialog)}
      />
    </>
  );
};

export default RetailerComission;
