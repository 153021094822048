import React from "react";

interface Props {
  picture: string;
}

const FixedPicture: React.FC<Props> = ({ picture }) => {
  return (
    <div className="my-8">
      <div
        className="Background-fixed  smallTablet:bg-scroll "
        style={{ backgroundImage: `url(${picture})` }}
      >
        <div className="ImageInnerWrap"></div>
      </div>
    </div>
  );
};

export default FixedPicture;
