import React, { useEffect } from "react";
import AboutBanner from "../Components/about-us/AboutBannerSection";
import GambleLife from "../Components/about-us/gambleLife";
import StatementSection from "../Components/about-us/StatementSection";
const About = () => {
  useEffect(() => {
    window.scrollTo({
      top: -100,
      behavior: "auto",
    });
  }, []);
  return (
    <div className="flex flex-col gap-y-10">
      <AboutBanner
        title="ABOUT US"
        backgroundImage="https://technostorage.nyc3.digitaloceanspaces.com/uploads/2021/07/slotss.jpg"
        height={400}
        fontSize="130px"
        boxHeight="160px"
      />
      <GambleLife />
      <StatementSection />
    </div>
  );
};

export default About;
