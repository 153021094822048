import React from "react";
type Props = {
  backgroundImage: string;
  textOnMiddle: string;
  height: number;
};
const ImageSectionOnTop = ({
  backgroundImage,
  textOnMiddle,
  height,
}: Props) => {
  return (
    <div
      style={{
        backgroundImage: `url(${backgroundImage})`,
        minHeight: `${height}px`,
      }}
      className="mx-auto relative"
    >
      <div className="bg-black absolute bg-opacity-50 top-0 left-0 w-full h-full">
        <h1 className="z-10 font-semibold text-[70px] font-roboto text-white w-full flex justify-center h-full items-center">
          {textOnMiddle}
        </h1>
      </div>
    </div>
  );
};

export default ImageSectionOnTop;
