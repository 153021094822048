import React from "react";

const ContentSection = () => {
  return (
    <div className="max-w-[1200px] w-full mx-auto py-[50px]">
      <div className=" px-[40px] mobile:px-6 break-all">
        <h2
          className="text-center mt-[15px] mb-[50px] text-[35px]"
          style={{
            fontFamily: "Montserrat, Sans-serif",
            fontWeight: "600",
            lineHeight: "1.4em",
          }}
        >
          Technogenious Solutions: Website Privacy Policy
        </h2>
        <p
          className="mb-[24px] text-[14px]"
          style={{
            fontFamily: "Montserrat, Sans-serif",
          }}
        >
          Last modified: 7-29-24
        </p>
        <ol
          className="ml-[3em] mb-[1.5em]"
          style={{
            listStyle: "decimal",
          }}
        >
          <li
            className="text-[14px]"
            style={{
              fontFamily: "Montserrat, Sans-serif",
            }}
          >
            Introduction
          </li>
        </ol>
        <p
          className="mb-[24px] text-[14px]"
          style={{
            fontFamily: "Montserrat, Sans-serif",
          }}
        >
          Technogenious Solutions Ltd (<strong>“Company”</strong>or{" "}
          <strong> “We”</strong>) respect your privacy and are committed to
          protecting it through our compliance with this policy.
        </p>
        <p
          className="mb-[24px] text-[14px]"
          style={{
            fontFamily: "Montserrat, Sans-serif",
          }}
        >
          This policy describes the types of information we may collect from you
          or that you may provide when you visit the website
          www.technogeniousja.com (our “ <strong>Website</strong>“) and our
          practices for collecting, using, maintaining, protecting, and
          disclosing that information.
        </p>
        <p
          className="mb-[24px] text-[14px]"
          style={{
            fontFamily: "Montserrat, Sans-serif",
          }}
        >
          This policy applies to information we collect:
        </p>
        <ul
          className="ml-[3em] mb-[1.5em]"
          style={{
            listStyle: "outside",
          }}
        >
          <li
            className="text-[14px]"
            style={{
              fontFamily: "Montserrat, Sans-serif",
            }}
          >
            On this Website.
          </li>
          <li
            className="text-[14px]"
            style={{
              fontFamily: "Montserrat, Sans-serif",
            }}
          >
            In email, text, and other electronic messages between you and this
            Website.
          </li>
        </ul>
        <p
          className="mb-[24px] text-[14px]"
          style={{
            fontFamily: "Montserrat, Sans-serif",
          }}
        >
          It does not apply to information collected by:
        </p>
        <ul
          className="ml-[3em] mb-[1.5em]"
          style={{
            listStyle: "outside",
          }}
        >
          <li
            className="text-[14px]"
            style={{
              fontFamily: "Montserrat, Sans-serif",
            }}
          >
            Us offline or through any other means, including on any other
            website operated by Company or any third party (including our
            affiliates and subsidiaries); or
          </li>
          <li
            className="text-[14px]"
            style={{
              fontFamily: "Montserrat, Sans-serif",
            }}
          >
            any third party (including our affiliates and subsidiaries),
            including through any application or content (including advertising)
            that may link to or be accessible from or on the Website
          </li>
        </ul>
        <p
          className="mb-[24px] text-[14px]"
          style={{
            fontFamily: "Montserrat, Sans-serif",
          }}
        >
          Please read this policy carefully to understand our policies and
          practices regarding your information and how we will treat it. If you
          do not agree with our policies and practices, your choice is not to
          use our Website. By accessing or using this Website, you agree to this
          privacy policy. This policy may change from time to time without
          notice. Your continued use of this Website after we make changes is
          deemed to be acceptance of those changes, so please check the policy
          periodically for updates.
        </p>
        <ol
          className="ml-[3em] mb-[1.5em]"
          style={{
            listStyle: "decimal",
          }}
        >
          <li
            className="text-[14px]"
            style={{
              fontFamily: "Montserrat, Sans-serif",
            }}
          >
            Children Under the Age of 18
          </li>
        </ol>
        <p
          className="mb-[24px] text-[14px]"
          style={{
            fontFamily: "Montserrat, Sans-serif",
          }}
        >
          Our Website is not intended for children under 18 years of age. No one
          under the age of 18 may provide any personal information to or on the
          Website. We do not knowingly collect personal information from
          children under 18. If you are under 18, do not use or provide any
          information on this Website or through any of its features/register on
          the Website, make any purchases through the Website, use any of the
          interactive or public comment features of this Website, or provide any
          information about yourself to us, including your name, address,
          telephone number, email address, or any screen name or username you
          may use without the supervision of a parent or guardian. If we learn
          we have collected or received personal information from a child under
          18 without verification of parental consent, we will delete that
          information. If you believe we might have any information from or
          about a child under 18, please contact us using the “Contact Us” link
          on our Website.
        </p>
        <p
          className="mb-[24px] text-[14px]"
          style={{
            fontFamily: "Montserrat, Sans-serif",
          }}
        >
          III. Information We Collect About You and How We Collect It
        </p>
        <p
          className="mb-[24px] text-[14px]"
          style={{
            fontFamily: "Montserrat, Sans-serif",
          }}
        >
          We collect several types of information from and about users of our
          Website, including information:
        </p>
        <ul
          className="ml-[3em] mb-[1.5em]"
          style={{
            listStyle: "outside",
          }}
        >
          <li
            className="text-[14px]"
            style={{
              fontFamily: "Montserrat, Sans-serif",
            }}
          >
            That personally identifies you, such as your name, postal address,
            email address, telephone number, and any other identifier by which
            you may be contacted online or offline (“Personal Information”);
          </li>
          <li
            className="text-[14px]"
            style={{
              fontFamily: "Montserrat, Sans-serif",
            }}
          >
            That pertains to you but does not individually identify you, such as
            usernames and information about your internet connection, the
            equipment you use to access our Website, and usage details.
          </li>
        </ul>
        <p
          className="mb-[24px] text-[14px]"
          style={{
            fontFamily: "Montserrat, Sans-serif",
          }}
        >
          We gather this information:
        </p>
        <ul
          className="ml-[3em] mb-[1.5em]"
          style={{
            listStyle: "outside",
          }}
        >
          <li
            className="text-[14px]"
            style={{
              fontFamily: "Montserrat, Sans-serif",
            }}
          >
            Directly from you when you provide it to us.
          </li>
          <li
            className="text-[14px]"
            style={{
              fontFamily: "Montserrat, Sans-serif",
            }}
          >
            Automatically as you navigate through the site. Information
            collected automatically may include usage details, IP addresses, and
            data collected through cookies and other tracking technologies.
          </li>
        </ul>
        <ol
          className="ml-[3em] mb-[1.5em]"
          style={{
            listStyle: "decimal",
          }}
        >
          <li
            className="text-[14px]"
            style={{
              fontFamily: "Montserrat, Sans-serif",
            }}
          >
            <strong>
              <u>Information You Provide to Us</u>
            </strong>
          </li>
        </ol>
        <p
          className="mb-[24px] text-[14px]"
          style={{
            fontFamily: "Montserrat, Sans-serif",
          }}
        >
          The information we collect on or through our Website may include:
        </p>
        <ul
          className="ml-[3em] mb-[1.5em]"
          style={{
            listStyle: "outside",
          }}
        >
          <li
            className="text-[14px]"
            style={{
              fontFamily: "Montserrat, Sans-serif",
            }}
          >
            Information you provide by filling out forms on our Website. This
            includes information provided when registering to use our Website,
            subscribing to our services, posting material, or requesting further
            services. We may also ask for information when you enter a contest
            or promotion sponsored by us or when you report a problem with our
            Website.
          </li>
          <li
            className="text-[14px]"
            style={{
              fontFamily: "Montserrat, Sans-serif",
            }}
          >
            Records and copies of your correspondence (including email
            addresses) if you contact us.
          </li>
          <li
            className="text-[14px]"
            style={{
              fontFamily: "Montserrat, Sans-serif",
            }}
          >
            Details of transactions you carry out through our Website and the
            fulfillment of your orders. You may need to provide financial
            information before placing an order through our Website.
          </li>
          <li
            className="text-[14px]"
            style={{
              fontFamily: "Montserrat, Sans-serif",
            }}
          >
            Your search queries on the Website.
          </li>
        </ul>
        <p
          className="mb-[24px] text-[14px]"
          style={{
            fontFamily: "Montserrat, Sans-serif",
          }}
        >
          You may also provide information to be published or displayed/posted
          on public areas of the Website or transmitted to other users of the
          Website or third parties (collectively, “User Contributions”). Your
          User Contributions are posted and transmitted to others at your own
          risk. Please be aware that no security measures are perfect or
          impenetrable. Additionally, we cannot control the actions of other
          users of the Website with whom you may share your{" "}
          <strong>User Contributions</strong>. Therefore, we cannot and do not
          guarantee that your User Contributions will not be viewed by
          unauthorized persons.
        </p>
        <ol
          className="ml-[3em] mb-[1.5em]"
          style={{
            listStyle: "decimal",
          }}
        >
          <li
            className="text-[14px]"
            style={{
              fontFamily: "Montserrat, Sans-serif",
            }}
          >
            {/* <strong>
              <u> */}
            Information We Collect Through Automatic Data Collection
            Technologies As you navigate and interact with our Website, we may
            use automatic data collection technologies to gather certain
            information about your equipment, browsing actions, and patterns,
            including:
            {/* </u>
            </strong> */}
          </li>
          <li
            className="text-[14px]"
            style={{
              fontFamily: "Montserrat, Sans-serif",
            }}
          >
            Details of your visits to our Website, including traffic data,
            location data, logs, and other communication data, and the resources
            you access and use on the Website.
          </li>
          <li
            className="text-[14px]"
            style={{
              fontFamily: "Montserrat, Sans-serif",
            }}
          >
            Information about your computer and internet connection, including
            your IP address, operating system, and browser type.
          </li>
        </ol>
        <p
          className="mb-[24px] text-[14px]"
          style={{
            fontFamily: "Montserrat, Sans-serif",
          }}
        >
          The information we collect automatically is statistical data and may
          include Personal Information. We may maintain it or associate it with
          Personal Information we collect in other ways or receive from third
          parties. This helps us improve our Website and deliver a better, more
          personalized service by enabling us to:
        </p>
        <ul
          className="ml-[3em] mb-[1.5em]"
          style={{
            listStyle: "outside",
          }}
        >
          <li
            className="text-[14px]"
            style={{
              fontFamily: "Montserrat, Sans-serif",
            }}
          >
            Estimate our audience size and usage patterns.
          </li>
          <li
            className="text-[14px]"
            style={{
              fontFamily: "Montserrat, Sans-serif",
            }}
          >
            Store information about your preferences, allowing us to customize
            our Website according to your individual interests.
          </li>
          <li
            className="text-[14px]"
            style={{
              fontFamily: "Montserrat, Sans-serif",
            }}
          >
            Speed up your searches.
          </li>
          <li
            className="text-[14px]"
            style={{
              fontFamily: "Montserrat, Sans-serif",
            }}
          >
            Recognize you when you return to our Website.
          </li>
        </ul>
        <p
          className="mb-[24px] text-[14px]"
          style={{
            fontFamily: "Montserrat, Sans-serif",
          }}
        >
          The technologies we use for automatic data collection may include:
        </p>
        <ul
          className="ml-[3em] mb-[1.5em]"
          style={{
            listStyle: "outside",
          }}
        >
          <li
            className="text-[14px]"
            style={{
              fontFamily: "Montserrat, Sans-serif",
            }}
          >
            Cookies (or browser cookies). A cookie is a small file placed on
            your computer's hard drive. You may refuse to accept browser cookies
            by activating the appropriate setting on your browser. However, if
            you select this setting, you may be unable to access certain parts
            of our Website. Unless you have adjusted your browser setting to
            refuse cookies, our system will issue cookies when you direct your
            browser to our Website.
          </li>
          <li
            className="text-[14px]"
            style={{
              fontFamily: "Montserrat, Sans-serif",
            }}
          >
            Web Beacons. Pages of the Website may contain small electronic files
            known as web beacons (also referred to as clear gifs, pixel tags,
            and single-pixel gifs) that permit the Company to count users who
            have visited those pages and for other related website statistics
            (for example, recording the popularity of certain website content
            and verifying system and server integrity).
          </li>
          <li
            className="text-[14px]"
            style={{
              fontFamily: "Montserrat, Sans-serif",
            }}
          >
            <strong>Speed up your searches.</strong>
            <span className="block">
              Some content or applications, including advertisements, on the
              Website are served by third parties, including advertisers, ad
              networks and servers, content providers, and application
              providers. These third parties may use cookies to collect
              information about you when you use our Website. The information
              they collect may be associated with your Personal Information or
              they may collect information, including Personal Information,
              about your online activities over time and across different
              websites and other online services. They may use this information
              to provide you with interest-based (behavioral) advertising or
              other targeted content.
            </span>
          </li>
          {/* <li
            className="text-[14px]"
            style={{
              fontFamily: "Montserrat, Sans-serif",
            }}
          >
            Recognize you when you return to our Website.
          </li> */}
        </ul>
        <p
          className="mb-[24px] text-[14px]"
          style={{
            fontFamily: "Montserrat, Sans-serif",
          }}
        >
          We do not control these third parties’ tracking technologies or how
          they may be used. If you have any questions about an advertisement or
          other targeted content, you should contact the responsible provider
          directly.
        </p>

        <p
          className="mb-[24px] text-[14px]"
          style={{
            fontFamily: "Montserrat, Sans-serif",
          }}
        >
          VII. How We Use Your Information
        </p>

        <p
          className="mb-[24px] text-[14px]"
          style={{
            fontFamily: "Montserrat, Sans-serif",
          }}
        >
          We use information that we collect about you or that you provide to
          us, including any Personal Information:
        </p>
        <ul
          className="ml-[3em] mb-[1.5em]"
          style={{
            listStyle: "outside",
          }}
        >
          <li
            className="text-[14px]"
            style={{
              fontFamily: "Montserrat, Sans-serif",
            }}
          >
            To present our Website and its contents to you.
          </li>
          <li
            className="text-[14px]"
            style={{
              fontFamily: "Montserrat, Sans-serif",
            }}
          >
            To provide you with information, products, or services that you
            request from us.
          </li>
          <li
            className="text-[14px]"
            style={{
              fontFamily: "Montserrat, Sans-serif",
            }}
          >
            To fulfill any other purpose for which you provide it.
          </li>
          <li
            className="text-[14px]"
            style={{
              fontFamily: "Montserrat, Sans-serif",
            }}
          >
            To provide you with notices about your account.
          </li>
          <li
            className="text-[14px]"
            style={{
              fontFamily: "Montserrat, Sans-serif",
            }}
          >
            To carry out our obligations and enforce our rights arising from any
            contracts entered into between you and us, including for billing and
            collection.
          </li>
          <li
            className="text-[14px]"
            style={{
              fontFamily: "Montserrat, Sans-serif",
            }}
          >
            To notify you about changes to our Website or any products or
            services we offer or provide through it.
          </li>
          <li
            className="text-[14px]"
            style={{
              fontFamily: "Montserrat, Sans-serif",
            }}
          >
            To allow you to participate in interactive features on our Website.
          </li>
        </ul>
        <p
          className="mb-[24px] text-[14px]"
          style={{
            fontFamily: "Montserrat, Sans-serif",
          }}
        >
          We may use the information we have collected from you to enable us to
          display advertisements to our advertisers’ target audiences. Even
          though we do not disclose your Personal Information for these purposes
          without your consent, if you click on or interact with an
          advertisement, the advertiser may assume that you meet its target
          criteria.
        </p>

        <p
          className="mb-[24px] text-[14px]"
          style={{
            fontFamily: "Montserrat, Sans-serif",
          }}
        >
          <strong>VIII. Disclosure of Your Information </strong>
        </p>

        <p
          className="mb-[24px] text-[14px]"
          style={{
            fontFamily: "Montserrat, Sans-serif",
          }}
        >
          We may disclose aggregated information about our users, and
          information that does not identify any individual, without
          restriction.
        </p>
        <p
          className="mb-[24px] text-[14px]"
          style={{
            fontFamily: "Montserrat, Sans-serif",
          }}
        >
          We may disclose Personal Information that we collect or you provide as
          described in this privacy policy:
        </p>
        <ul
          className="ml-[3em] mb-[1.5em]"
          style={{
            listStyle: "outside",
          }}
        >
          <li
            className="text-[14px]"
            style={{
              fontFamily: "Montserrat, Sans-serif",
            }}
          >
            To our subsidiaries and affiliates.
          </li>
          <li
            className="text-[14px]"
            style={{
              fontFamily: "Montserrat, Sans-serif",
            }}
          >
            To contractors, service providers, and other third parties we use to
            support our business.
          </li>
          <li
            className="text-[14px]"
            style={{
              fontFamily: "Montserrat, Sans-serif",
            }}
          >
            To a buyer or other successor in the event of a merger, divestiture,
            restructuring, reorganization, dissolution, or other sale or
            transfer of some or all of Technogenious Solutions Ltd.’s assets,
            whether as a going concern or as part of bankruptcy, liquidation, or
            similar proceeding, in which personal information held by
            Technogenious Solutions Ltd. about our Website users is among the
            assets transferred.
          </li>
          <li
            className="text-[14px]"
            style={{
              fontFamily: "Montserrat, Sans-serif",
            }}
          >
            To fulfill the purpose for which you provide it.
          </li>
          <li
            className="text-[14px]"
            style={{
              fontFamily: "Montserrat, Sans-serif",
            }}
          >
            For any other purpose disclosed by us when you provide the
            information.
          </li>
          <li
            className="text-[14px]"
            style={{
              fontFamily: "Montserrat, Sans-serif",
            }}
          >
            With your consent.
          </li>
        </ul>
        <p
          className="mb-[24px] text-[14px]"
          style={{
            fontFamily: "Montserrat, Sans-serif",
          }}
        >
          We may also disclose your personal information:
        </p>
        <ul
          className="ml-[3em] mb-[1.5em]"
          style={{
            listStyle: "outside",
          }}
        >
          <li
            className="text-[14px]"
            style={{
              fontFamily: "Montserrat, Sans-serif",
            }}
          >
            To comply with any court order, law, or legal process, including to
            respond to any government or regulatory request.
          </li>
          <li
            className="text-[14px]"
            style={{
              fontFamily: "Montserrat, Sans-serif",
            }}
          >
            To enforce or apply our terms of use
            technogeniousja.com/terms-and-conditions/ or Terms of Sale
            technogeniousja.com/terms-and-conditions/ and other agreements,
            including for billing and collection purposes.
          </li>
          <li
            className="text-[14px]"
            style={{
              fontFamily: "Montserrat, Sans-serif",
            }}
          >
            If we believe disclosure is necessary or appropriate to protect the
            rights, property, or safety of Technogenious Solutions Ltd., our
            customers, or others. This includes exchanging information with
            other companies and organizations for fraud protection and credit
            risk reduction.
          </li>
        </ul>

        <p
          className="mb-[24px] text-[14px]"
          style={{
            fontFamily: "Montserrat, Sans-serif",
          }}
        >
          <strong>XIV. Accessing and Correcting Your Information </strong>
        </p>

        <p
          className="mb-[24px] text-[14px]"
          style={{
            fontFamily: "Montserrat, Sans-serif",
          }}
        >
          You can review and change your Personal Information by logging into
          the Website and visiting your account profile page.
        </p>
        <p
          className="mb-[24px] text-[14px]"
          style={{
            fontFamily: "Montserrat, Sans-serif",
          }}
        >
          If you delete your User Contributions from the Website, copies of your
          User Contributions may remain viewable in cached and archived pages or
          might have been copied or stored by other Website users. Proper access
          and use of information provided on the Website, including User
          Contributions, are governed by our terms of use
          technogeniousja.com/terms-and-conditions/.
        </p>

        <p
          className="mb-[24px] text-[14px]"
          style={{
            fontFamily: "Montserrat, Sans-serif",
          }}
        >
          <strong>XV. Data Security </strong>
        </p>

        <p
          className="mb-[24px] text-[14px]"
          style={{
            fontFamily: "Montserrat, Sans-serif",
          }}
        >
          We have implemented measures to secure your Personal Information from
          accidental loss, unauthorized access, use, alteration, and disclosure.
        </p>
        <p
          className="mb-[24px] text-[14px]"
          style={{
            fontFamily: "Montserrat, Sans-serif",
          }}
        >
          Your information's safety and security also depend on you. If we have
          provided you with (or you have chosen) a password for access to
          certain parts of our Website, you are responsible for keeping this
          password confidential. Please do not share your password with anyone.
          Be careful when providing information in public areas of the Website,
          such as message boards, as it may be viewed by any user of the
          Website.
        </p>
        <p
          className="mb-[24px] text-[14px]"
          style={{
            fontFamily: "Montserrat, Sans-serif",
          }}
        >
          Unfortunately, the transmission of information via the internet is not
          completely secure. Although we strive to protect your Personal
          Information, we cannot guarantee its security when transmitted to our
          Website. Any transmission of Personal Information is at your own risk.
          We are not responsible for the circumvention of any privacy settings
          or security measures on the Website.
        </p>
        <p
          className="mb-[24px] text-[14px]"
          style={{
            fontFamily: "Montserrat, Sans-serif",
          }}
        >
          <strong>XVI. Changes to Our Privacy Policy </strong>
        </p>

        <p
          className="mb-[24px] text-[14px]"
          style={{
            fontFamily: "Montserrat, Sans-serif",
          }}
        >
          We will post any changes to our privacy policy on this page. If we
          make significant changes to how we handle users’ Personal Information,
          we will try to notify you through a notice on the Website home page.
          The date this Privacy Policy was last updated is shown at the top of
          the page. You are responsible for periodically visiting our Website
          and this Privacy Policy to check for any updates.
        </p>
        <p
          className="mb-[24px] text-[14px]"
          style={{
            fontFamily: "Montserrat, Sans-serif",
          }}
        >
          <strong>SECURITY POLICY </strong>
        </p>

        <p
          className="mb-[24px] text-[14px]"
          style={{
            fontFamily: "Montserrat, Sans-serif",
          }}
        >
          Your payment and personal information are always protected. Our Secure
          Sockets Layer (SSL) software is the industry standard and one of the
          best available today for secure transactions. It encrypts all your
          personal information, including credit card number, name, and address,
          so that it cannot be read over the internet.
        </p>
        <p
          className="mb-[24px] text-[14px]"
          style={{
            fontFamily: "Montserrat, Sans-serif",
          }}
        >
          <strong>SHIPPING POLICY/DELIVERY POLICY </strong>
        </p>

        <p
          className="mb-[24px] text-[14px]"
          style={{
            fontFamily: "Montserrat, Sans-serif",
          }}
        >
          Rest assured, your items will be shipped within two days of purchase.
          We select the most efficient shipping carrier for your order.
          International carriers may include DHL and FedEx, while local
          deliveries use Knutsford Express and Doorway Express Ltd.
          Unfortunately, we do not ship to P.O. Boxes.
        </p>
        <p
          className="mb-[24px] text-[14px]"
          style={{
            fontFamily: "Montserrat, Sans-serif",
          }}
        >
          To estimate when a package will be delivered, please consider the
          following: Credit card authorization and verification must be received
          before processing. If you need express or 2-day shipping, please call
          us at 876-648-4163 for charges.
        </p>
        <p
          className="mb-[24px] text-[14px]"
          style={{
            fontFamily: "Montserrat, Sans-serif",
          }}
        >
          <strong>Cancel Items and Orders </strong>
        </p>

        <p
          className="mb-[24px] text-[14px]"
          style={{
            fontFamily: "Montserrat, Sans-serif",
          }}
        >
          Physical items can be cancelled, provided the product has not been
          shipped from our warehouse or is not in the process of shipping.
        </p>
        <p
          className="mb-[24px] text-[14px]"
          style={{
            fontFamily: "Montserrat, Sans-serif",
          }}
        >
          <strong>Return/Refund Policy </strong>
        </p>

        <p
          className="mb-[24px] text-[14px]"
          style={{
            fontFamily: "Montserrat, Sans-serif",
          }}
        >
          Items deemed defective or damaged by Technogenious Solutions Ltd
          during shipping can be returned within 7 working days for a full
          refund. All parts and supplies can be returned within 7 working days
          and may be subject to a restocking fee of up to 20%. Custom-built game
          machines cannot be returned. All refunds will be issued as a credit to
          the credit card used at the time of purchase within 5 business days of
          receiving the returned merchandise.
        </p>
      </div>
    </div>
  );
};

export default ContentSection;
