import React, { useEffect, useState } from "react";
import {
  Hero,
  SideFilter,
  ShopProducts,
  Pagination,
} from "../Components/index";
import { rangeRadio } from "../constant";
import { useLocation, useParams } from "react-router-dom";
import useFetch from "../Network/useFetch";
import Loader from "../Components/Loader";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { FilterCheckBox, FilterInfo } from "../Components/atoms/atoms";

const Shop = () => {
  const { categoryId } = useParams();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(8);
  const [sortOption, setSortOption] = useState("");
  const setFilterArray = useSetRecoilState(FilterCheckBox);
  const [filterValue, setFilterValue] = useRecoilState(FilterInfo);
  let fetchUrl = `${process.env.REACT_APP_SERVER_URL}/product/category?categoryId=${categoryId}&page=${currentPage}&pageSize=${pageSize}`;
  const fetchKey: any = `fetch-category`;
  if (filterValue?.filters) {
    const encodedFilter = encodeURIComponent(filterValue?.filters);
    fetchUrl = fetchUrl.concat(`&filter=${encodedFilter}`);
  }
  if (filterValue?.ranges) {
    fetchUrl = fetchUrl.concat(
      `&lowPrice=${filterValue?.ranges?.lowestPrice}&highPrice=${filterValue?.ranges?.highestPrice}`
    );
  }
  if (sortOption?.length) {
    fetchUrl = fetchUrl.concat(`&sort=${sortOption}`);
  }
  const { isLoading, data, isError, error, refetch } = useFetch(
    fetchUrl,
    [fetchKey, currentPage, categoryId, pageSize, filterValue, sortOption],
    {
      enabled: true,
    }
  );
  const location = useLocation();
  const filterUrl: any = `${process.env.REACT_APP_SERVER_URL}/category/filter?categoryId=${categoryId}`;
  const fetchFilterKey: any = `fetch-filter-products`;
  const {
    isLoading: filterLoading,
    data: filterData,
    isError: isFilterError,
    error: filterError,
  } = useFetch(filterUrl, [fetchFilterKey, categoryId], {
    enabled: true,
  });
  useEffect(() => {
    setFilterValue(null);
    setFilterArray(null);
  }, [location?.key]);

  const [hasProducts, setHasProducts] = useState<string[]>([]);

  const handlePageChange = (page: any) => {
    setCurrentPage(page);
  };

  const handleSortChange = (event) => {
    setSortOption(event.target.value);
  };

  if (isLoading)
    return (
      <div className="flex h-[80vh] items-center justify-center">
        <Loader forProduct />
      </div>
    );
  if (isError) return <div className="h-[50vh]">Error: {error.message}</div>;
  return (
    <main>
      <Hero
        image={
          data.backgroundImage
            ? data.backgroundImage
            : "https://technostorage.nyc3.digitaloceanspaces.com/uploads/2021/07/slotss.jpg"
        }
        title={data?.data?.title}
      />
      {hasProducts.length === 0 ? (
        <p className="max-w-[1000px] font-roboto text-[#4B4F58] text-sm mx-auto mt-20 mb-8 text-center">
          Technogenious Solutions Ltd. is a Licensed Technical Services Provider
          by the Betting and Gaming Lotteries Commission – BGLC.
          <br />
          <br /> Authorized Servicing Center for Pyramid Bill Acceptors In
          Jamaica.
        </p>
      ) : null}
      <div
        className={`max-w-[1140px] p-6 mx-auto flex flex-wrap ${
          data.isSlider ? "justify-between gap-6" : "justify-center"
        }`}
      >
        <div
          className={`pt-14 largeTablet:px-6 ${
            !data?.isSlider ? "flex-1" : "hidden"
          }`}
        >
          {!data?.isSlider ? (
            <SideFilter isLoading={filterLoading} data={filterData} />
          ) : null}
        </div>

        <div className="w-[853px] tablet:w-full ">
          <div className="flex items-center justify-between px-4 gap-4 flex-wrap w-full py-8 largeTablet:gap-2  mobile:flex-col mobile:gap-y-3 mobile:!w-full">
            <h1 className="text-[#666666] font-light text-sm">
              {data?.data?.data?.totalRecords !== 0
                ? `Showing ${(currentPage - 1) * pageSize + 1}–${Math.min(
                    currentPage * pageSize,
                    data.data?.data?.totalRecords
                  )} of ${data.data?.data?.totalRecords} results`
                : `Showing 0-0 of 0 results`}
            </h1>
            <select
              className="text-[#666666] font-light text-sm w-40 px-3 border-dotted py-2 border-black focus:border-2 focus:outline-none"
              value={sortOption}
              onChange={handleSortChange}
            >
              <option value="menu_order" selected>
                Default sorting
              </option>
              {/* <option value="popularity">Sort by popularity</option>
              <option value="rating">Sort by average rating</option> */}
              <option value="latest">Sort by latest</option>
              <option value="low">Sort by price: low to high</option>
              <option value="high">Sort by price: high to low</option>
            </select>
          </div>
          <div>
            {data?.data?.data?.totalRecords !== 0 ? (
              <ShopProducts
                data={
                  Array.isArray(data?.data?.data?.results) &&
                  data?.data?.data?.results.length > 0
                    ? data?.data?.data?.results
                    : []
                }
              />
            ) : (
              <p className="font-bold text-medium mx-10">No result found</p>
            )}
          </div>
          <div className="w-full flex items-center py-8">
            {data.data?.data?.totalRecords > pageSize ? (
              <Pagination
                currentPage={currentPage}
                totalPages={Math.ceil(data.data?.data?.totalRecords / pageSize)}
                onPageChange={handlePageChange}
              />
            ) : null}
          </div>
        </div>
      </div>
    </main>
  );
};

export default Shop;
