import React, { useEffect, useState } from "react";
import Accordion from "./Accordion";

const WarrantySection = () => {
  let topImage =
    "https://technostorage.nyc3.digitaloceanspaces.com/uploads/2021/09/questions-bg.jpg";
  let backgroundImage =
    "https://technostorage.nyc3.digitaloceanspaces.com/uploads/2021/09/FAQ-Image-2.jpg";
  let title = "TECHNOGENIOUS";
  const [openIndex, setOpenIndex] = useState(null);

  const handleToggle = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };
  useEffect(() => {
    window.scrollTo({
      top: 20,
      behavior: "auto",
    });
  }, []);
  return (
    <div>
      {/* header */}
      <div className="">
        <div
          style={{
            backgroundImage: `url(${topImage})`,
            backgroundPosition: "center center",
          }}
          className="w-full p-4 h-[66vh]  relative bg-no-repeat bg-cover smallTablet:hidden"
        >
          <div className="absolute w-11/12 flex   bottom-0">
            <div className=" flex flex-col  items-end w-full justify-center pb-9 inset-0 ">
              <h1 className="capitalize text-white  text-[70px] font-bold  tablet:text-6xl smallTablet:text-3xl">
                {title}
              </h1>
              <h2 className="capitalize text-white  text-[52px] font-extralight  tablet:text-[26px] smallTablet:text-3xl">
                fequently asked questions
              </h2>
            </div>
          </div>
        </div>

        <div
          style={{
            backgroundImage: `url("https://technostorage.nyc3.digitaloceanspaces.com/uploads/2021/07/slate-texture.jpg")`,
            backgroundSize: "cover",
          }}
          className="hidden smallTablet:flex smallTablet:h-[30vh]"
        >
          <div className="flex w-full bottom-0">
            <div className=" flex flex-col items-center w-full justify-end pb-9 inset-0 smallTablet:justify-center ">
              <h1 className="capitalize text-white  text-8xl font-bold  smallTablet:text-2xl">
                {title}
              </h1>
              <h2 className="capitalize text-white  text-[52px] font-extralight smallTablet:text-2xl">
                fequently asked questions
              </h2>
            </div>
          </div>
        </div>

        <div className="flex">
          <div className="  flex justify-center items-start w-full  ">
            <div className="flex bg-white w-full gap-x-5 smallTablet:hidden">
              <div
                style={{
                  backgroundImage: `url(${backgroundImage})`,
                  // backgroundSize: "cover",
                  // backgroundPosition: "center",
                }}
                className="w-1/2 h-[85vh] backgroundSetting bg-gray-200 bg-cover"
              ></div>

              <div className=" w-1/2 mx-6  right-0 smallTablet:w-full">
                <div className="elementor-widget-container">
                  <style />{" "}
                  <h2 className="text-[32px] pt-1 font-normal mb-5 text-[#3a3a3a]">
                    TECHNOGENIOUS
                  </h2>
                  <h2 className="text-[32px] font-normal mb-5 text-[#3a3a3a] ">
                    FEQUENTLY ASKED QUESTIONS
                  </h2>
                  <Accordion
                    isOpen={openIndex === 0}
                    onToggle={() => handleToggle(0)}
                    title="What is the warranty policy on a new gaming Machines?"
                  >
                    <p className="text-[15px] w-full px-2 font-normal mb-5 text-[#3a3a3a] ">
                      Technogenious Solutions Ltd warrants your new machine for
                      60 days from the date of purchase. After 60 days,
                      components warranted by the manufacturer for a longer
                      period of time must be sent to the manufacturer for repair
                      or replacement under the Warranty Policy set by the
                      manufacturer.
                    </p>
                  </Accordion>
                  <Accordion
                    isOpen={openIndex === 1}
                    onToggle={() => handleToggle(1)}
                    title="Does Technogenious Solutions Ltd. sell gaming parts and
                    accessories?"
                  >
                    <p className="text-[15px] w-full px-2 font-normal mb-5 text-[#3a3a3a] ">
                      Yes, we offer a full line of parts and services to gaming
                      industries.&nbsp;Email us at sales
                      <a
                        href="mailto:orders@technogeniousja.com"
                        className="text-[#E02424]"
                      >
                        @technogeniousja.com
                      </a>
                      &nbsp;or call us 1-876-648-4163.
                    </p>
                  </Accordion>
                  <Accordion
                    isOpen={openIndex === 2}
                    onToggle={() => handleToggle(2)}
                    title="Does Technogenious Solutions Ltd. lease gaming machine?"
                  >
                    <p className="text-[15px] w-full px-2 font-normal mb-5 text-[#3a3a3a] ">
                      Yes, in fact this is one of our best services offered,
                      machines are leased to Bar Owners/Operators at little to
                      no hassle.&nbsp;Email us at&nbsp;
                      <a
                        href="mailto:orders@technogeniousja.com"
                        className="text-[#E02424]"
                      >
                        sales@technogeniousja.com
                      </a>
                      &nbsp;or call us 1-876-648-4163.
                    </p>
                  </Accordion>
                  <Accordion
                    title=" Is there a fee to lease gaming and have machines installed
                    on premise?
                  "
                    isOpen={openIndex === 3}
                    onToggle={() => handleToggle(3)}
                  >
                    <p className="text-[15px] w-full px-2 font-normal mb-5 text-[#3a3a3a] ">
                      There are no signup fees to lease machine, and have them
                      installed on your premise, we take care of all expenses!
                    </p>
                  </Accordion>
                  <Accordion
                    isOpen={openIndex === 4}
                    onToggle={() => handleToggle(4)}
                    title="   How do I contact customer service?"
                  >
                    <p className="text-[15px] w-full px-2 font-normal mb-5 text-[#3a3a3a] ">
                      Technogenious Solutions Ltd. Customer Service is open and
                      can be reached by telephone at
                      <br style={{ boxSizing: "inherit" }} />
                      1-876-648-4163/1-876-448-2032 Monday – Saturday from 9:00
                      am (EST) to 6:00 pm (PST) or by emailing us at&nbsp;
                      <a
                        href="mailto:service@technogeniousja.com"
                        className="text-[#E02424]"
                      >
                        sales@technogeniousja.com
                      </a>
                      .
                    </p>
                  </Accordion>
                  <Accordion
                    title="What are our opening hours?
                 "
                    isOpen={openIndex === 5}
                    onToggle={() => handleToggle(5)}
                  >
                    <p className="text-[15px] w-full px-2 font-normal mb-5 text-[#3a3a3a] ">
                      Technogenious Solutions Ltd store location opening hours
                      are 9:00 am (EST) to 6:00 pm (PST) from Monday – Saturday.
                      Visit our Location Today located at Shop 4B 17 Molynes Rd,
                      Kingston.
                    </p>{" "}
                  </Accordion>
                </div>
              </div>
            </div>

            <div className="hidden smallTablet:flex smallTablet:flex-col">
              <div className=" smallTablet:w-full">
                <div className="px-5 break-all">
                  <style />{" "}
                  <h2 className="text-[32px] font-normal mb-5 text-[#3a3a3a] ">
                    TECHNOGENIOUS
                  </h2>
                  <h2 className="text-[32px] font-normal mb-5 text-[#3a3a3a] mobile:hidden ">
                    FEQUENTLY ASKED QUESTIONS
                  </h2>
                  <h2 className="text-[26px] hidden font-normal mb-5 text-[#3a3a3a] mobile:block ">
                    FEQUENTLY ASKED <br></br>QUESTIONS
                  </h2>
                  <Accordion
                    isOpen={openIndex === 0}
                    onToggle={() => handleToggle(0)}
                    title="What is the warranty policy on a new gaming Machines?"
                  >
                    <p className="text-[15px] w-full px-2 font-normal mb-5 text-[#3a3a3a] ">
                      Technogenious Solutions Ltd warrants your new machine for
                      60 days from the date of purchase. After 60 days,
                      components warranted by the manufacturer for a longer
                      period of time must be sent to the manufacturer for repair
                      or replacement under the Warranty Policy set by the
                      manufacturer.
                    </p>
                  </Accordion>
                  <Accordion
                    isOpen={openIndex === 1}
                    onToggle={() => handleToggle(1)}
                    title="Does Technogenious Solutions Ltd. sell gaming parts and
                    accessories?"
                  >
                    <p className="text-[15px] w-full px-2 font-normal mb-5 text-[#3a3a3a] ">
                      Yes, we offer a full line of parts and services to gaming
                      industries.&nbsp;Email us at sales
                      <a
                        href="mailto:orders@technogeniousja.com"
                        className="text-[#E02424]"
                      >
                        @technogeniousja.com
                      </a>
                      &nbsp;or call us 1-876-648-4163.
                    </p>
                  </Accordion>
                  <Accordion
                    isOpen={openIndex === 2}
                    onToggle={() => handleToggle(2)}
                    title="Does Technogenious Solutions Ltd. lease gaming machine?"
                  >
                    <p className="text-[15px] w-full px-2 font-normal mb-5 text-[#3a3a3a] ">
                      Yes, in fact this is one of our best services offered,
                      machines are leased to Bar Owners/Operators at little to
                      no hassle.&nbsp;Email us at&nbsp;
                      <a
                        href="mailto:orders@technogeniousja.com"
                        className="text-[#E02424]"
                      >
                        sales@technogeniousja.com
                      </a>
                      &nbsp;or call us 1-876-648-4163.
                    </p>
                  </Accordion>
                  <Accordion
                    title=" Is there a fee to lease gaming and have machines installed
                    on premise?
                  "
                    isOpen={openIndex === 3}
                    onToggle={() => handleToggle(3)}
                  >
                    <p className="text-[15px] w-full px-2 font-normal mb-5 text-[#3a3a3a] ">
                      There are no signup fees to lease machine, and have them
                      installed on your premise, we take care of all expenses!
                    </p>
                  </Accordion>
                  <Accordion
                    isOpen={openIndex === 4}
                    onToggle={() => handleToggle(4)}
                    title="   How do I contact customer service?"
                  >
                    <p className="text-[15px] w-full px-2 font-normal mb-5 text-[#3a3a3a] ">
                      Technogenious Solutions Ltd. Customer Service is open and
                      can be reached by telephone at
                      <br style={{ boxSizing: "inherit" }} />
                      1-876-648-4163/1-876-448-2032 Monday – Saturday from 9:00
                      am (EST) to 6:00 pm (PST) or by emailing us at&nbsp;
                      <a
                        href="mailto:service@technogeniousja.com"
                        className="text-[#E02424]"
                      >
                        sales@technogeniousja.com
                      </a>
                      .
                    </p>
                  </Accordion>
                  <Accordion
                    title="What are our opening hours?
                 "
                    isOpen={openIndex === 5}
                    onToggle={() => handleToggle(5)}
                  >
                    <p className="text-[15px] w-full px-2 font-normal mb-5 text-[#3a3a3a] ">
                      Technogenious Solutions Ltd store location opening hours
                      are 9:00 am (EST) to 6:00 pm (PST) from Monday – Saturday.
                      Visit our Location Today located at Shop 4B 17 Molynes Rd,
                      Kingston.
                    </p>{" "}
                  </Accordion>
                </div>
              </div>

              <img
                src="https://technostorage.nyc3.digitaloceanspaces.com/uploads/2021/11/pointing-female.png"
                alt="imagesec"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WarrantySection;
